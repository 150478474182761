import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

const API_URL = process.env.REACT_APP_BASE_URL;

const CalendarForm = ({ selectedCalendar, onSave }) => {
  const [users, setUsers] = useState([]);
  const [calendar, setCalendar] = useState({
    employee: "",
    title: "",
    color: "",
  });

  useEffect(() => {
    axios.get(`${API_URL}payroll/users/`).then((response) => {
      setUsers(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedCalendar) {
      setCalendar(selectedCalendar);
    }
  }, [selectedCalendar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCalendar({
      ...calendar,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (calendar.id) {
      axios
        .put(`${API_URL}api/calendars/${calendar.id}/`, calendar)
        .then(() => onSave());
    } else {
      axios
        .post(`${API_URL}api/calendars-list/`, calendar)
        .then(() => onSave());
    }
  };

  return (
    <>
    <p className="mb-3"><strong>Note: Please add color by name or HEX code. </strong></p>
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Employee</Form.Label>
        <Form.Control
          as="select"
          name="employee"
          value={calendar.employee}
          onChange={handleChange}
        >
          <option value="">Select Employee</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.username}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          value={calendar.title}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Color</Form.Label>
        <Form.Control
          type="text"
          name="color"
          value={calendar.color}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
    </>
  );
};

export default CalendarForm;
