import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Header from "../DashboardHeader.js";
import RetailSalesPieChart from "./RetailComponents/RetailSalesPiechart";
import RevenueChart from "./SalesComponents/RevenueService";
import VolumeMeter from "./SalesComponents/ServiceVolume";
import Cards from "./FinancialComponents/Cards";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const Retail = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div className="row mb-4">
      <Header />
      <div>
        <RetailSalesPieChart />
      </div>
    </div>
  );
};

export default Retail;
