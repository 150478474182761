import React, { useState, useEffect } from "react";

function ExpenseSubCategoryForm() {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');

  useEffect(() => {
    fetch(`${apiUrl}inventory/expenses/categories-filter/`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
        if (data.length > 0) {
          setSelectedCategory(data[0].id.toString());
        }
      })
      .catch((error) => console.error('Error fetching categories:', error));
  }, [apiUrl]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newSubCategory = {
      name: subCategoryName,
      category: selectedCategory,
    };

    try {
      const response = await fetch(`${apiUrl}inventory/expenses/subcategories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSubCategory),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Success:", result);
      alert("New subcategory created!");
      window.location.href = `/item-categories`;
    } catch (error) {
      console.error("Error:", error);
      alert("Error in creating new subcategory.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
          <div className="row mb-4 mt-4">
          <label>Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="row mb-4">
          <label>Subcategory Name:</label>
          <input
            type="text"
            value={subCategoryName}
            onChange={(e) => setSubCategoryName(e.target.value)}
            required
          />
        </div>
        <div className="row mb-4">
          <button type="submit">Create Subcategory</button>
        </div>
        </div>
        <div className="col-3"></div>
      </div>
    </form>
  );
}

export default ExpenseSubCategoryForm;
