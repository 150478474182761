import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const EmployeePayrollList = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [employeePayrolls, setEmployeePayrolls] = useState([]);

  useEffect(() => {
    loadEmployeePayrolls();
  }, []);

  const loadEmployeePayrolls = async () => {
    const result = await axios.get(`${apiUrl}payroll/employee-payroll/`);
    setEmployeePayrolls(result.data);
  };

  const handleDelete = async (id) => {
    await axios.delete(`${apiUrl}payroll/employee-payroll/${id}/`);
    loadEmployeePayrolls();
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Employee Payroll List
        </h3>

        <Link to={`/employee-payroll/add`}>
          <button className="mb-4 mt-4">Add Payroll</button>
        </Link>

        <table>
          <thead>
            <tr>
              <th>Employee First Name</th>
              <th>Salary</th>
              <th>Commission</th>
              <th>Commission Percentage</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {employeePayrolls.map((payroll) => (
              <tr key={payroll.id}>
                <td>{payroll.employee.first_name}</td>
                <td>{payroll.salary}</td>
                <td>{payroll.commission ? "Yes" : "No"}</td>
                <td>{payroll.commission_percentage}%</td>
                <td>
                  <Link to={`/employee-payroll/edit/${payroll.id}`}>
                    <button className="mr-2">Edit</button>
                  </Link>
                  <button onClick={() => handleDelete(payroll.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeePayrollList;
