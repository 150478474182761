import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Modal } from "react-bootstrap";
import ShopForm from "./ShopForm";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { useSelector, useDispatch } from "react-redux";

const API_URL = process.env.REACT_APP_BASE_URL;

const ShopList = () => {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadShops();
  }, []);

  const loadShops = () => {
    axios.get(`${API_URL}api/shopdetails/`).then((response) => {
      setShops(response.data);
    });
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this shop?")) {
      axios.delete(`${API_URL}api/shopdetails/${id}/`).then(() => loadShops());
    }
  };

  const handleEdit = (shop) => {
    setSelectedShop(shop);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedShop(null);
  };

  const handleSave = () => {
    loadShops();
    handleCloseModal();
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Shops
        </h3>
        <div>
          <Button onClick={() => setShowModal(true)} className="mt-2 mb-4">
            Add Shop
          </Button>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Shop Name</th>
                  <th>Owner</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {shops.map((shop) => (
                  <tr key={shop.id}>
                    <td className="text-center">{shop.ShopName}</td>
                    <td className="text-center">{`${shop.OwnerFirstName} ${shop.OwnerLastName}`}</td>
                    <td className="text-center">
                      <Button variant="info" onClick={() => handleEdit(shop)}>
                        Edit
                      </Button>{" "}
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(shop.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            dialogClassName="custom-modal-width"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedShop ? "Edit Shop" : "Add Shop"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ShopForm shop={selectedShop} onSave={handleSave} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ShopList;
