import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useParams, Link } from "react-router-dom";
import Header from "../Header.js";
import { Container } from "react-bootstrap";
import Sidebar from "../Design/ShopDetailsSidebar.js";
import ShopBookingsDetail from "./shopBookingsDetail.js";
import { Modal, Button } from "react-bootstrap";
import CustomToolbar from "./CustomToolbar.js";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTimes } from "@fortawesome/free-solid-svg-icons";

const localizer = momentLocalizer(moment);

const AppointmentCalendar = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;
  const [bookings, setBookings] = useState([]);
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const apiUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    fetch(`${apiUrl}api/more-appointment-bookings/${id}/`)
      .then((response) => response.json())
      .then((data) => {
        const formattedBookings = data.map((booking) => {
          const start = moment.utc(booking.start).format("YYYY-MM-DDTHH:mm:ss");
          const end = moment.utc(booking.end).format("YYYY-MM-DDTHH:mm:ss");
          const petNamesArray = booking.pet_names.split(", ");
          const petBreedsArray = booking.pet_breeds.split(", ");
          // Ensure both arrays have the same length
          const length = Math.max(petNamesArray.length, petBreedsArray.length);
          const petDetailsArray = [];

          for (let i = 0; i < length; i++) {
            const petName = petNamesArray[i] || "Unknown Pet";
            const petBreed =
              petBreedsArray[i] || petBreedsArray[0] || "Unknown Breed"; // Fallback to the first breed if missing
            petDetailsArray.push(`${petName} (${petBreed})`);
          }

          const petDetails = petDetailsArray.join(", ");

          return {
            id: booking.client_id,
            booking_ids: booking.booking_ids,
            title: petDetails,
            start: new Date(start),
            end: new Date(end),
            owner: booking.client_id,
            pet_names: booking.pet_names,
            pet_breeds: booking.pet_breeds,
            client_names: booking.client_names,
            is_paid: booking.is_paid,
            current_status: booking.current_status,
            calendar_color: booking.calendar_color,
            allDay: false,
          };
        });
        setBookings(formattedBookings);
      })
      .catch((error) => console.error("Error fetching bookings:", error));
  }, [id, apiUrl]);

  const handleSelectEvent = (event) => {
    setSelectedBookingIds(event.booking_ids);
    handleOpenModal();
  };

  const formatTooltipText = (event) => {
    const startTime = moment(event.start).format("D MMM, YYYY - h:mmA");
    const endTime = moment(event.end).format("h:mmA");

    return `${event.pet_names}
Client: ${event.client_names}
Date: ${startTime}-${endTime}
Click for details`;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <Container>
          <h2
            className="booking-heading mb-4 mt-2"
            style={{
              paddingBottom: "10px",
              fontWeight: "700",
              color: "#282c34",
            }}
          >
            Booked Appointments
          </h2>
          <div
            style={{
              backgroundColor: "white",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
            className="px-4"
          >
            <div style={{ height: "600px" }}>
              <Calendar
                localizer={localizer}
                events={bookings.map((booking) => ({
                  ...booking,
                  start: moment(booking.start).toDate(),
                  end: moment(booking.end).toDate(),
                  tooltip: formatTooltipText(booking),
                }))}
                startAccessor="start"
                endAccessor="end"
                defaultView="week"
                tooltipAccessor="tooltip"
                onSelectEvent={handleSelectEvent}
                components={{
                  toolbar: CustomToolbar,
                  event: ({ event }) => (
                    <div
                      className="rbc-event-content"
                      onClick={() => handleSelectEvent(event)}
                      key={event.id}
                      style={{
                        backgroundColor:
                          event.current_status === "Cancel"
                            ? "grey"
                            : event.is_paid
                            ? "grey"
                            : event.calendar_color || "inherit",
                        borderColor:
                          event.current_status === "Cancel"
                            ? "grey"
                            : event.is_paid
                            ? "grey"
                            : event.calendar_color || "inherit",
                        borderWidth: "1px",
                        color: "white",
                        padding: "2px",
                        borderRadius: "3px",
                        boxSizing: "border-box",
                        height: "100%",
                      }}
                    >
                      <div style={{ color: "white", fontSize: "12px" }}>
                        {event.title} <br />
                        <br />
                        {moment(event.start).format("hh:mm A")} -{" "}
                        {moment(event.end).format("hh:mm A")}
                      </div>
                      {event.current_status === "Cancel" ? (
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ marginLeft: "5px", color: "white" }}
                        />
                      ) : event.is_paid ? (
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          style={{ marginLeft: "5px", color: "white" }}
                        />
                      ) : null}
                    </div>
                  ),
                }}
                min={new Date(1970, 1, 1, 8, 30)} // 8:30 AM
                max={new Date(1970, 1, 1, 20, 0)} // 8:00 PM
              />
            </div>
          </div>
        </Container>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header closeButton>
            <Modal.Title>Booking Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedBookingIds.length === 0 ? (
              <div>No booking selected</div>
            ) : (
              <ShopBookingsDetail
                shopName={shopName}
                bookingIds={selectedBookingIds}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AppointmentCalendar;
