import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { useSelector } from 'react-redux';

function PolicySignature() {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId
  const shopName = shop.shopName

  const { id } = useParams();
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [signature, setSignature] = useState("");
  const [error, setError] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchPolicies();
  }, []);

  {
    /*const fetchPolicies = () => {
    fetch("http://127.0.0.1:8000/api/policies/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch policies");
        }
        return response.json();
      })
      .then((data) => setPolicies(data))
      .catch((error) => setError(error.message));
  };*/
  }

  const fetchPolicies = () => {
    fetch(`${apiUrl}api/policies/?client_id=${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch policies");
        }
        return response.json();
      })
      .then((data) => setPolicies(data))
      .catch((error) => setError(error.message));
  };

  const handlePolicyChange = (event) => {
    setSelectedPolicy(event.target.value);
  };

  const handleClearSignature = () => {
    const canvas = document.getElementById("signatureCanvas");
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    setSignature("");
  };

  const handleSaveSignature = () => {
    fetch(`${apiUrl}api/save-signature/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        policy_id: selectedPolicy,
        signature: signature,
        client_id: id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save signature");
        }
        alert("Signature saved successfully");
        window.location.href = `/client-profile/${id}`;
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => setError(error.message));
  };

  const handleDrawing = (event) => {
    const canvas = document.getElementById("signatureCanvas");
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (event.buttons !== 1) return;

    context.lineWidth = 3;
    context.lineJoin = "round";
    context.lineCap = "round";

    if (!isDrawing) {
      context.beginPath();
      context.moveTo(x, y);
      setIsDrawing(true);
    } else {
      context.lineTo(x, y);
      context.stroke();
    }

    const dataUrl = canvas.toDataURL();
    setSignature(dataUrl);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div style={{ background: "white" }}>
      <h2 className="p-4">Select Policy</h2>
      {error && <p>{error}</p>}
      <select value={selectedPolicy} onChange={handlePolicyChange}>
        <option value="">Select a Policy</option>
        {policies.map((policy) => (
          <option key={policy.id} value={policy.id}>
            {policy.title}
          </option>
        ))}
      </select>
      <h2 className="p-4">Sign Here</h2>
      <div className="p-4">
        <canvas
          id="signatureCanvas"
          width={800}
          height={200}
          style={{ border: "1px solid black" }}
          onMouseMove={handleDrawing}
        ></canvas>
      </div>
      <button onClick={handleClearSignature}>Clear Signature</button>
      <button onClick={handleSaveSignature} className="ml-4">
        Save Signature
      </button>
    </div>
  );
}

export default PolicySignature;
