import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const CashReconciliation = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [reconciliations, setReconciliations] = useState([]);
  const [stores, setStores] = useState([]);
  const [form, setForm] = useState({
    store: "",
    date: "",
    opening_balance: "",
    sales_in_cash: "",
    expenses_in_cash: "",
  });

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (shopId) {
      fetchReconciliations();
    }
  }, [shopId]);

  const fetchReconciliations = async () => {
    const response = await axios.get(
      `${apiUrl}inventory/reconciliations/?store=${shopId}`
    );
    setReconciliations(response.data);
  };

  const fetchStores = async () => {
    const response = await axios.get(`${apiUrl}inventory/stores/`);
    setStores(response.data);
  };

  const fetchPreviousDayReconciliation = async (storeId, date) => {
    try {
      const response = await axios.get(`${apiUrl}inventory/reconciliations/?store=${storeId}&date=${date}`);
      const reconciliations = response.data;
      if (reconciliations.length > 0) {
        const previousDayEndingBalance = reconciliations[0].ending_balance;
        setForm((prevForm) => ({
          ...prevForm,
          opening_balance: previousDayEndingBalance,
        }));
      }
    } catch (error) {
      console.error('Error fetching previous day reconciliation:', error);
    }
  };

  const fetchSalesAndExpenses = async (storeId, date) => {
    try {
      const salesResponse = await axios.get(
        `${apiUrl}inventory/get-sales/?store=${storeId}&date=${date}`
      );
      const expensesResponse = await axios.get(
        `${apiUrl}inventory/get-expenses/?store=${storeId}&date=${date}`
      );

      const sales = salesResponse.data.total_sales || 0;
      const expenses = expensesResponse.data.total_expenses || 0;

      setForm((prevForm) => ({
        ...prevForm,
        sales_in_cash: sales,
        expenses_in_cash: expenses,
      }));
    } catch (error) {
      console.error("Error fetching sales and expenses:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    if (name === 'date' && form.store) {
      fetchSalesAndExpenses(form.store, value);
      const previousDay = new Date(value);
      previousDay.setDate(previousDay.getDate() - 1);
      const previousDayStr = previousDay.toISOString().split('T')[0];
      fetchPreviousDayReconciliation(form.store, previousDayStr);
    }
    if (name === 'store' && form.date) {
      fetchSalesAndExpenses(value, form.date);
      const previousDay = new Date(form.date);
      previousDay.setDate(previousDay.getDate() - 1);
      const previousDayStr = previousDay.toISOString().split('T')[0];
      fetchPreviousDayReconciliation(value, previousDayStr);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(`${apiUrl}inventory/reconciliations/`, form);
    alert("Cash entry added.")
    fetchReconciliations();
  };

  const computeEndingBalance = () => {
    const openingBalance = parseFloat(form.opening_balance) || 0;
    const salesInCash = parseFloat(form.sales_in_cash) || 0;
    const expensesInCash = parseFloat(form.expenses_in_cash) || 0;
    return openingBalance + salesInCash - expensesInCash;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Cash Register Reconciliation
        </h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formStore">
            <Form.Label>Store</Form.Label>
            <Form.Control
              as="select"
              name="store"
              value={form.store}
              onChange={handleChange}
              required
            >
              <option value="">Select Store</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.ShopName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={form.date}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formOpeningBalance">
            <Form.Label>Opening Balance</Form.Label>
            <Form.Control
              type="number"
              name="opening_balance"
              value={form.opening_balance}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formSalesInCash">
            <Form.Label>Sales in Cash</Form.Label>
            <Form.Control
              type="number"
              name="sales_in_cash"
              value={form.sales_in_cash}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formExpensesInCash">
            <Form.Label>Expenses in Cash</Form.Label>
            <Form.Control
              type="number"
              name="expenses_in_cash"
              value={form.expenses_in_cash}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEndingBalance">
            <Form.Label>Expected Ending Balance</Form.Label>
            <Form.Control
              type="number"
              value={computeEndingBalance()}
              readOnly
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Add Reconciliation
          </Button>
        </Form>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Opening Balance</th>
              <th>Sales in Cash</th>
              <th>Expenses in Cash</th>
              <th>Ending Balance</th>
            </tr>
          </thead>
          <tbody>
            {reconciliations.map((rec) => (
              <tr key={rec.id}>
                <td className="text-center">{rec.date}</td>
                <td className="text-center">${rec.opening_balance}</td>
                <td className="text-center">${rec.sales_in_cash}</td>
                <td className="text-center">${rec.expenses_in_cash}</td>
                <td className="text-center">${rec.ending_balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CashReconciliation;
