import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BarChart, Bar, PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import { COLORS } from './colors';
import './ExpenseDashboard.css';


const ExpenseDashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fixedPieData, setFixedPieData] = useState([]);
  const [variablePieData, setVariablePieData] = useState([]);
  const [fixedGrandTotal, setFixedGrandTotal] = useState(0);
  const [variableGrandTotal, setVariableGrandTotal] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const apiUrl = process.env.REACT_APP_BASE_URL;
  
  useEffect(() => {
    const fetchData = async (endpoint, setPieData, setGrandTotal) => {
      try {
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        const response = await axios.get(`${apiUrl}inventory/expenses/${endpoint}/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`);

        const categoryTotals = response.data.reduce((acc, expense) => {
          const {subcategory, amount} = expense;
          const {name} = expense.subcategory;
          acc[name] = (acc[name] || 0) + parseFloat(amount);
          return acc;
        }, {});

        const newPieData = Object.keys(categoryTotals).map(name => ({name, value: categoryTotals[name]}));
        setPieData(newPieData);
        setGrandTotal(newPieData.reduce((acc, curr) => acc + curr.value, 0));
        setTotalExpenses(fixedGrandTotal + variableGrandTotal);
      } catch (error) {
        console.error('There was an error fetching the expenses', error);
      }
    };

    fetchData('fixed', setFixedPieData, setFixedGrandTotal);
    fetchData('variable', setVariablePieData, setVariableGrandTotal);
  }, [startDate, endDate]);

  return (
    <div className="expense-dashboard">
      <div className="header-dashboard">
        <h1>Expense Dashboard</h1>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      <div className="chart expense-grand-total">
        <h2>
        Total Expenses: ${totalExpenses.toLocaleString()}
        </h2>
      </div>
      <div className="charts-container">
        <div className="chart operating-expenses-development">
          Operating Expenses Development
        </div>
        <div className="chart operating-ratio">
          Ratio Expense
        </div>
        <div className="chart fixed-expenses">
          <div className="title-sections-dashboard">
            <h2>Fixed Expenses</h2>
          </div>
          <div className="fixed-pie-chart">
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={fixedPieData}
                  dataKey="value"
                  nameKey="name"
                  isAnimationActive={false}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                {
                  fixedPieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))
                }
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="fixed-expense-breakdown">
            <h3>Expense Breakdown</h3>
            {fixedPieData.map((entry, index) => (
              <div key={index} className="expense-item">
                <span className="expense-name">{entry.name}</span>
                <span className="expense-value">${entry.value.toLocaleString()}</span>
              </div>
            ))}
            <div className="expense-item grand-total">
              <span className="expense-name">Total</span>
              <span className="expense-value">${fixedGrandTotal.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <div className="chart fixed-expenses">
        <div className="title-sections-dashboard">
            <h2>Variable Expenses</h2>
          </div>
          <div className="variable-pie-chart">
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={variablePieData}
                  dataKey="value"
                  nameKey="name"
                  isAnimationActive={false}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                {
                  variablePieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))
                }
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="fixed-expense-breakdown">
            <h3>Expense Breakdown</h3>
            {variablePieData.map((entry, index) => (
              <div key={index} className="expense-item">
                <span className="expense-name">{entry.name}</span>
                <span className="expense-value">${entry.value.toLocaleString()}</span>
              </div>
            ))}
            <div className="expense-item grand-total">
              <span className="expense-name">Total</span>
              <span className="expense-value">${variableGrandTotal.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseDashboard;