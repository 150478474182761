import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";

//import Sidebar from "../Design/ClientCenterSidebar.js";
//import Sidebar from "../Design/ShopDetailsSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector } from 'react-redux';

const ClientLeads = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId
  const shopName = shop.shopName
  //const { shopName } = useParams();
  //const { id } = useParams();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(
          `${apiUrl}api/client-leads/${id}/`
        );
        const data = await response.json();
        setBookings(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
  

  return (
    <div className="row">
      
      <div className="col-md-3" style={{paddingRight:'0px'}}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{paddingLeft:'0px'}}>
      <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          New Client Leads
        </h3>
      {/*}  <div
          className="col-3"
          style={{ backgroundColor: "white", marginRight: "60px" }}
        >
          <div className="shop-sidebar">
            <ul>
              <li>
                <Link
                  to={`/shop/${shopName}/${id}`}
                  className="text-decoration-none text-dark px-4"
                >
                  Shop Detail
                </Link>
              </li>
              <li>
                {shopName && (
                  <Link
                    to={`/booking/${shopName}/${id}`}
                    className="text-decoration-none text-dark px-4"
                  >
                    Appointment
                  </Link>
                )}
              </li>
              <li>
                {shopName && (
                  <Link
                    to={`/reservation/${shopName}/${id}`}
                    className="text-decoration-none text-dark px-4"
                  >
                    Reservation
                  </Link>
                )}
              </li>
              <li>
                <Link
                  to={`/appointment-bookings/${id}`}
                  className="text-decoration-none text-dark px-4"
                >
                  Appointment Calender
                </Link>
              </li>
              <li>
                <Link
                  to={`/reservation-bookings/${id}`}
                  className="text-decoration-none text-dark px-4"
                >
                  Reservation Calender
                </Link>
              </li>
            </ul>
          </div>
                </div>*/}
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Pet</th>
                <th>Preferred Contact</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr
                  key={index}
                  style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}
                >
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/client-profile/${booking.id}`}>
                      {booking.FirstName ? `${booking.FirstName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/client-profile/${booking.id}`}>
                      {booking.LastName ? `${booking.LastName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.pets && booking.pets.length > 0
                      ? booking.pets.map((pet, index) => (
                          <Link key={index} to={`/pet-profile/${pet.id}`}>
                            {pet.PetName}
                          </Link>
                        ))
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.contacts && booking.contacts.length > 0
                      ? booking.contacts
                          .map((contact) => contact.contact_type)
                          .join(", ")
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.Address ? booking.Address : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ClientLeads;
