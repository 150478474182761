import React, { useState, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";

const PetBirthday = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);
  const reportRef = useRef();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (startDate && endDate) {
      try {
        const response = await axios.post(`${apiUrl}api/birthday-report/`, {
          start_date: startDate,
          end_date: endDate,
        });
        setResult(response.data.birthday_records);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const headers = [
    { label: "Pet Name", key: "PetName" },
    { label: "Birthday", key: "BirthDate" },
    { label: "Current Age", key: "Age" },
    { label: "Client", key: "Client" },
    { label: "Phone", key: "Phone" },
    { label: "Email", key: "Email" },
  ];

  const csvData = result
    ? result.map((record) => ({
        PetName: record.PetName,
        BirthDate: new Date(record.BirthDate).toISOString().split("T")[0], // Format date to YYYY-MM-DD
        Age: record.Age,
        Client: `${record.Client.FirstName} ${record.Client.LastName}`,
        Phone:
          record.Contacts.find((contact) => contact.contact_type === "Mobile")
            ?.contact_value || "N/A",
        Email:
          record.Contacts.find((contact) => contact.contact_type === "Email")
            ?.contact_value || "N/A",
      }))
    : [];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {result && (
        <>
          <div className="d-flex justify-content-end mb-3">
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"pet-birthday-report.csv"}
              className="btn btn-primary me-2"
            >
              Export to CSV
            </CSVLink>
            <Button className="btn btn-secondary" onClick={handlePrint}>
              Print
            </Button>
          </div>
          <div ref={reportRef}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Pet Name</th>
                  <th>Birthday</th>
                  <th>Current Age</th>
                  <th>Client</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {result.map((record) => (
                  <tr key={record.id}>
                    <td>{record.PetName}</td>
                    <td>{record.BirthDate}</td>
                    <td>{record.Age} year</td>
                    <td>
                      {record.Client.FirstName} {record.Client.LastName}
                    </td>
                    <td>
                      {record.Contacts.find(
                        (contact) => contact.contact_type === "Mobile"
                      )?.contact_value || "N/A"}
                    </td>
                    <td>
                      {record.Contacts.find(
                        (contact) => contact.contact_type === "Email"
                      )?.contact_value || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default PetBirthday;
