import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

import Header from "../Header.js";
import PetClinic from '../../Images/pet_clinic.jpg';


/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";
import { setShopDetail } from '../../reducers/shopSlice';

/* ACTION CREATORS */
import { logout } from "../../actions/userActions";

import Sidebar from "../Design/Sidebar.js";

const ShopDetailList = () => {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  /* HANDLER */
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  const handleSelectShop = (shopName, shopId) => {
    dispatch(setShopDetail({ shopName, shopId }));
  };

  const [shopDetails, setShopDetails] = useState([]);

  const apiUrl = process.env.REACT_APP_BASE_URL;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const response = await fetch(
          `${apiUrl}api/ShowShopView/`,
          config
        );
        const data = await response.json();
        setShopDetails(data);
      } catch (error) {
        console.error("Error fetching shop details:", error);
      }
    };

    if (userInfo && userInfo.token) {
      fetchData();
    }
  }, [userInfo]);

  return (

    <div>
      <Header />
    

    <div style={{background:"white"}}>
      <h2
        className="py-4 mb-4"
        style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
      >
        Shops
      </h2>
      {shopDetails.length > 0 ? (
      <div className="card-container d-flex flex-wrap justify-content-around">
        {shopDetails.map(({ id, ShopName, ShopImage }) => (
          <Card
            key={id}
            style={{ width: "18rem", margin: "10px", height: "300px" }}
          >
            <Link
              to={`/shop`}
              style={{ textDecoration: "none" }}
              onClick={() => handleSelectShop(ShopName, id)}
            >
              <Card.Img
                variant="top"
                src={ShopImage ? `${apiUrl}${ShopImage}` : PetClinic}
                alt={ShopName}
                height={200}
              />
              <Card.Body>
                <Card.Title style={{ fontWeight: "700", fontSize: "24px" }}>
                  {ShopName}
                </Card.Title>
              </Card.Body>
            </Link>
          </Card>
        ))}
      </div>
      ) : (
        <div>No shops added</div>
      )}
    </div>
    </div>
  );
};

export default ShopDetailList;
