import React from "react";
import { Table } from "react-bootstrap";

const SalesDetail = ({ salesDetail, shopTimezone }) => {
  if (!salesDetail || !salesDetail.sales_summary) return <div>No data available</div>;

  const { sales_summary, sales_details } = salesDetail;

  const totals = sales_summary.details.reduce (
     (acc, sale) => {
      acc.total_sales += sale.total_sales;
      acc.cash_amount += sale.cash_amount;
      acc.card_amount += sale.card_amount;
      acc.transfer_amount += sale.transfer_amount;
      return acc;
     },
     {total_sales: 0, cash_amount: 0, card_amount: 0, transfer_amount: 0}
  )

  // Group sales by type and name
  const groupedSalesDetails = sales_details.reduce((acc, detail) => {
    const { item_type, item_category, quantity_sold, total_price } = detail;
    const type = item_category === null ? "Product" : "Service";

    if (!acc[type]) {
      acc[type] = { type, items: [] };
    }

    acc[type].items.push({
      name: item_type,
      total_quantity: quantity_sold,
      total_price: total_price,
    });

    return acc;
  }, {});

  // Function to format currency in Mexican Pesos
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
  };

  return (
    <div>
      <h4>Sales Summary</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Client</th>
            <th>Total Sales</th>
            <th>Cash Amount</th>
            <th>Card Amount</th>
            <th>Transfer Amount</th>
          </tr>
        </thead>
        <tbody>
          {sales_summary.details.map((sale, index) => (
            <tr key={index}>
              <td>
                {new Date(sale.date).toLocaleString('en-US', {
                  timeZone: shopTimezone,
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </td>
              <td>{sale.client__FirstName} {sale.client__LastName}</td>
              <td>{formatCurrency(sale.total_sales)}</td>
              <td>{formatCurrency(sale.cash_amount)}</td>
              <td>{formatCurrency(sale.card_amount)}</td>
              <td>{formatCurrency(sale.transfer_amount)}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="2"><strong>Totals</strong></td>
            <td><strong>{formatCurrency(totals.total_sales)}</strong></td>
            <td><strong>{formatCurrency(totals.cash_amount)}</strong></td>
            <td><strong>{formatCurrency(totals.card_amount)}</strong></td>
            <td><strong>{formatCurrency(totals.transfer_amount)}</strong></td>
          </tr>
        </tbody>
      </Table>

      <h4>Sales Details</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Quantity Sold</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(groupedSalesDetails).map((group, index) => (
            <React.Fragment key={`group-${index}`}>
              {Object.values(group.items).map((item, itemIndex) => (
                <tr key={`${group.type}-${itemIndex}`}>
                  {itemIndex === 0 && (
                    <td rowSpan={group.items.length}>
                      {group.type}
                    </td>
                  )}
                  <td>{item.name}</td>
                  <td>{item.total_quantity}</td>
                  <td>{formatCurrency(item.total_price)}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SalesDetail;