import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Header from "../DashboardHeader.js";
import StoreSalesPieChart from "./SalesComponents/StoreSalesPieChart";
import RevenueChart from "./SalesComponents/RevenueService";
import VolumeMeter from "./SalesComponents/ServiceVolume";
import Cards from "./SalesComponents/Cards";
import DaycareChart from "./SalesComponents/DaycareService";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const Sales = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div className="row">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <Cards />
          </div>
        </div>
        <div className="row mx-4">
          <div className="col-md-6 mb-4">
            <StoreSalesPieChart />
          </div>
          <div className="col-md-6 mb-4">
            <VolumeMeter />
          </div>
          <div className="row">
            <div className="col-md-8 mb-4">
              <RevenueChart />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mb-4">
              <DaycareChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
