import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ClientsForm.css'; // Importing the CSS file
import NewClientsModal from './NewClientsModal.js'; // Import the NewClientsModal component

const ClientForm = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [clients, setClients] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchClients = async () => {
          try {
            const response = await axios.get('/clients/');
            setClients(response.data);
          } catch (error) {
            console.error('Error fetching clients', error);
          }
        };
        fetchClients();
    }, [modalOpen]);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = async () => {
        try {
            
        } catch (error) {
            console.error('Error searching for clients', error.response?.data || error.message);
        }
    };

    const getInitials = (firstName, lastName) => {
        return `${firstName[0]}${lastName[0]}`;
    };

    return (
        <div className="client-container">
            <div className='client-selection'>
                <div className="client-header">
                    <h1>Clients Menu</h1>
                    <div className="client-search">
                        <input
                            className="input-search-client"
                            type="text"
                            placeholder="Search for client by name, last name or pet name"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button onClick={handleSearch}>Search</button>
                        <button onClick={openModal}>New Client</button>
                        {modalOpen && <NewClientsModal onClose={closeModal} />}
                    </div>
                </div>
                <div className="client-cards">
                    {clients.map((client, index) => (
                        <div key={index} className="bg-gray-50 rounded-lg p-4 flex items-center client-card">
                            <div className="bg-green-500 text-white rounded-full h-10 w-10 flex items-center justify-center mr-4">
                                {getInitials(client.first_name, client.last_name)}
                            </div>
                            <div>
                                <div className="font-semibold">{client.first_name} {client.last_name}</div>
                                <div className="text-sm text-gray-600">{client.email}</div>
                                <div className="text-sm text-gray-600">{client.phone}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientForm;