import React, { useState, useEffect } from "react";
import axios from "axios";
//import Sidebar from "../Design/AddPetSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const BreedList = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [breeds, setBreeds] = useState([]);

  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/all-breeds/`);
        setBreeds(response.data);
      } catch (error) {
        console.error("Error fetching breeds:", error);
      }
    };

    fetchBreeds();
  }, []);

  const handleCheckboxChange = (id) => async () => {
    try {
      const updatedBreeds = breeds.map((breed) => ({
        ...breed,
        active: breed.id === id ? !breed.active : breed.active || false,
      }));


      // Update the state
      setBreeds(updatedBreeds);

      await axios.patch(`${apiUrl}api/all-breeds/${id}/`, {
        active: updatedBreeds.find((breed) => breed.id === id).active,
      });

    } catch (error) {
      console.error("Error updating breed:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
      <Header />
      <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Breed List
        </h3>
        <p style={{ paddingBottom: "20px"}}>
            This section will allow you to fine-tune your breed list. 
            Simply uncheck any boxes for breeds you do not want in your breed autocomplete options.
        </p>
        <div className="px-4">
        <table className="mr-2 ml-2">
          <thead>
            <tr style={{textAlign:"left"}}>
              <th>Breed</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {breeds.map((breed) => (
              <tr key={breed.id}>
                <td>{breed.name}</td>
                <td>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`breed-${breed.id}`}
                      checked={breed.active}
                      onChange={handleCheckboxChange(breed.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`breed-${breed.id}`}
                    >
                    </label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  );
};

export default BreedList;
