import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Container, Row, Col } from "react-bootstrap";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const ClockInOut = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [pin, setPin] = useState("");
  const [message, setMessage] = useState("");

  const getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = `${position.coords.latitude},${position.coords.longitude}`;
          callback(location);
        },
        (error) => {
          console.error(error);
          callback("Location not available");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      callback("Geolocation not supported");
    }
  };

  const handleClockIn = async () => {
    getLocation(async (location) => {
      try {
        const response = await axios.post(
          `${apiUrl}api/clock-in/`,
          { location },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setMessage("Clocked in successfully");
      } catch (error) {
        setMessage(error.response.data.error);
      }
    });
  };

  const handleClockOut = async () => {
    getLocation(async (location) => {
      try {
        const response = await axios.post(
          `${apiUrl}api/clock-out/`,
          { location },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setMessage("Clocked out successfully");
      } catch (error) {
        setMessage(error.response.data.error);
      }
    });
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Form>
 
            <Button variant="primary" className="mr-2" onClick={handleClockIn}>
              Clock In
            </Button>
            <Button variant="secondary" onClick={handleClockOut}>
              Clock Out
            </Button>
          </Form>
          {message && <p>{message}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default ClockInOut;
