import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";

//import Sidebar from "../Design/ClientCenterSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import InventoryForm from "./InventoryForm.js";
import { Modal, Button } from "react-bootstrap";

import { useDispatch, useSelector } from 'react-redux';


const InventoryMain = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const { shopName } = useParams();
  const { id } = useParams();
  const [inventory, setInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  //
  const [showFilterTable, setShowFilterTable] = useState(false);
  const [selectedNames, setSelectedNames] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await fetch(`${apiUrl}inventory/inventory-filter/?shop_id=${shopId}`);
        const data = await response.json();
        console.log("inventory :", data);
        setInventory(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching inventory:", error);
      }
    };

    fetchInventory();
  }, [apiUrl]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prev) => {
      if (prev.includes(categoryId)) {
        return prev.filter((id) => id !== categoryId);
      } else {
        return [...prev, categoryId];
      }
    });
  };

  // Function to update searchQuery state
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    console.log("hello :");
  };


  const filteredInventory = useMemo(() => {
    return inventory.filter((item) => {
      const matchesSearchQuery = item.productName
        .toLowerCase()
        .includes(searchQuery);
      // Check if expense_subcategory exists before trying to access its properties
      const isInSelectedCategory =
        selectedCategories.length === 0 ||
        (item.expense_subcategory && selectedCategories.includes(item.expense_subcategory.category_id));
      return matchesSearchQuery && isInSelectedCategory;
    });
  }, [inventory, searchQuery, selectedCategories]);
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${apiUrl}inventory/expenses/categories-filter/`);
        const data = await response.json();
        console.log("Categories :", data);
        setCategories(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Categories:", error);
      }
    };

    fetchCategories();
  }, [apiUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleNameChange = (name) => {
    const newSelection = new Set(selectedNames);
    if (newSelection.has(name)) {
      newSelection.delete(name);
    } else {
      newSelection.add(name);
    }
    setSelectedNames(newSelection);
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Inventory
        </h3>

        <Button onClick={() => handleOpenModal()} className="mt-2 mb-4">
          Add New Item
        </Button>

        <input
          type="text"
          placeholder="Search items..."
          onChange={handleSearchChange}
          className="form-control mb-4 mr-2"
          value={searchQuery}
        />

        <button
          type="button"
          onClick={() => setShowFilterTable(!showFilterTable)}
          className="mb-4"
        >
          Filters
        </button>

        <div className="px-2 mb-4">
          {showFilterTable && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {categories.map((category) => (
                <div key={category.id}>
                  <input
                    type="checkbox"
                    checked={selectedCategories.includes(category.id)}
                    onChange={() => handleCategoryChange(category.id)}
                  />
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </div>

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Media</th>
                <th>Stock #</th>
                <th>Brand Name</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {filteredInventory.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => handleOpenModal(item.id)}
                  style={{
                    height: "50px",
                    borderBottom: "1px solid #dee2e6",
                    cursor: "pointer",
                  }}
                >
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={item.image}
                      alt="?"
                      style={{ height: "50px", width: "50px" }}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.quantityStock ? `${item.quantityStock}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.brandName ? `${item.brandName}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.productName ? `${item.productName}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.price ? `$${item.price}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.expense_category
                      ? item.expense_category.name
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          {!currentItemId ? (
            <Modal.Title>Add Item</Modal.Title>
          ) : (
            <Modal.Title>Edit Item</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <InventoryForm itemId={currentItemId} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InventoryMain;
