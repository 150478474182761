import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";


const PromoGroupsEdit = ({ id }) => {
    const apiUrl = process.env.REACT_APP_BASE_URL;

  const [promoGroup, setPromoGroup] = useState({
    group_name: '',
    description: '',
    items: [],
  });
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}inventory/promotion-group/${id}/`)
      .then(res => res.json())
      .then(data => {
        setPromoGroup({
          ...data,
          items: data.items.map(item => item.id),
        });
      })
      .catch(err => console.error(err));

    fetch(`${apiUrl}inventory/inventory/`)
      .then(res => res.json())
      .then(data => setAllItems(data))
      .catch(err => console.error(err));
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPromoGroup(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleItemsChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
    setPromoGroup(prevState => ({
      ...prevState,
      items: selectedOptions,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit the updated promo group
    fetch(`${apiUrl}inventory/promotion-group/${id}/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(promoGroup),
    })
    .then(response => response.json())
    .then(data => console.log('Success:', data))
    .catch(error => console.error('Error:', error));
  };

  const handleDelete = () => {
    // Delete the promo group
    fetch(`${apiUrl}inventory/promotion-group/${id}/`, {
      method: 'DELETE',
    })
    .then(() => console.log('Delete successful'))
    .catch(error => console.error('Error:', error));
  };

  return (
    <div>
      <p>Set a group name, describe the group's purpose, then search items and select from the list to 
        populate your new promo group. 
        Each item included in the group will have the associated promo (sale) applied to their pricing.</p>
      <form onSubmit={handleSubmit}>
      <div className="row mb-3">
      
        <label>
          Group Name: </label>
          <input
            name="group_name"
            type="text"
            value={promoGroup.group_name || ''}
            onChange={handleChange}
          />
        
        <br />
        <label className='mt-4'>
          Description: </label>
          <textarea
            name="description"
            value={promoGroup.description || ''}
            onChange={handleChange}
          />
        
        <br />
        <label className='mt-4'>
          Items: </label>
          <select multiple value={promoGroup.items} onChange={handleItemsChange}>
            {allItems.map(item => (
              <option key={item.id} value={item.id}>
                {item.productName}
              </option>
            ))}
          </select>
        
        <br />
        </div>
        <button type="submit">Save Changes</button>
        
      </form>
      {/*<button onClick={handleDelete}>Delete Promo Group</button>*/}
    </div>
  );
};

export default PromoGroupsEdit;
