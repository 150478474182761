import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector } from "react-redux";

const EmployeeCenter = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${apiUrl}api/employee/`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, []);

  const handleUserClick = (userId) => {
    navigate(`/employee-detail/${userId}/`);
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Employees
        </h3>
        <div className="d-flex justify-content-center">
        <Link to={`/employees`}>
          <button className="mb-4 mt-4 mr-2">Add Employee</button>
        </Link>
        </div>
        <div
          className="px-4 mt-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Date of Hire</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.employee_info?.user_role}</td>
                  <td>{user.employee_info?.phone}</td>
                  <td>{user.employee_info?.date_of_hire}</td>
                  <td>{user.employee_info?.status}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleUserClick(user.id)}
                    >
                      View Profile
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCenter;
