import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import BanksForm from "./BanksForm";
import { Table, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

const Banks = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [serviceName, setServiceName] = useState("");
  const [serviceCategory, setServiceCategory] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState([]);

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentItemId(null);
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/banks/`)
      .then((response) => setServices(response.data))
      .catch((error) => console.error("Error fetching services", error));
  }, []);

  const handleDelete = (bankId) => {
    if (window.confirm("Are you sure you want to delete this bank?")) {
      axios
        .delete(`${apiUrl}inventory/banks/${bankId}/`)
        .then(() => {
          // Refresh the list after deletion
          setServices(services.filter((service) => service.id !== bankId));
        })
        .catch((error) => console.error("Error deleting bank", error));
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Banks
        </h3>

        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
            Add New Bank
          </Button>
        </div>

        <Table striped bordered hover className="m-2">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service.id}>
                <td>{service.name}</td>
                <td style={{textAlign:'center'}}>
                  <Button
                    onClick={() => handleOpenModal(service.id)}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    onClick={() => handleDelete(service.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>{currentItemId ? "Edit Bank" : "Add Bank"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BanksForm
            currentItemId={currentItemId}
            setCurrentItemId={setCurrentItemId}
            services={services}
            setServices={setServices}
            setShowModal={setShowModal}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Banks;
