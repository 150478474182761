import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentModal = ({ poNumber, closeModal, submitPayment, bankAccounts, purchaseOrderDetails, stores, isCOGSChecked, selectedStore, setSelectedStore, fromCashRegister }) => {
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    }

    //console.log("selectedStore :", stores)

    const apiUrl = process.env.REACT_APP_BASE_URL;

    const [receivedDate, setReceivedDate] = useState(formatDate(new Date()));
    const [receiverNotes, setReceiverNotes] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [amountPaid, setAmountPaid] = useState(0);
    const [paymentDate, setPaymentDate] = useState(formatDate(new Date()));
    const [paidBy, setPaidBy] = useState('');
    const [receivedItems, setReceivedItems] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    

    const paymentOptions = [
        { value: '', label: 'Select Payment Type' },
        { value: 'Cash', label: 'Cash' },
        { value: 'Transfer', label: 'Bank Transfer' },
        { value: 'DebitCard', label: 'Debit Card' },
        { value: 'CreditCard', label: 'Credit Card' }
    ];

    const handleStoreChange = (e) => {
        const storeId = parseInt(e.target.value, 10);
        console.log("storeId :", storeId)
        console.log("stores :", stores)
        const selected = stores.find(s => s.value === storeId);
        console.log("selected :", selected)
        if(setSelectedStore) {
            setSelectedStore(selected);
        } else {
            console.error('setSelectedStore function not provided');
        }
    }

    //const filteredBankAccounts = bankAccounts && bankAccount.length > 0
    //    ? bankAccount.filter(account => account.name !== 'Cash')
    //    : [];

    const fetchInventoryItemDetails = async (inventoryItemId) => {
        try {
            const response = await axios.get(`${apiUrl}inventory/inventory/${inventoryItemId}/`);
            return response.data;
        } catch (error) {
            console.error('Error fetching inventory item details:', error);
            return null;
        }
    };

    useEffect(() => {
        if (!['Transfer', 'DebitCard', 'CreditCard'].includes(paymentType)) {
            setBankAccount(null);
        }
    }, [paymentType]);

    useEffect(() => {
        console.log("PO Number:", poNumber);
        console.log("Purchase Order Details:", purchaseOrderDetails);
    }, [poNumber, purchaseOrderDetails]);

    useEffect(() => {
        if (fromCashRegister && selectedStore) {
            setPaymentType('Cash');
            const storeToUpdate = stores.find(store => store.value === selectedStore.value);
            setSelectedStore(storeToUpdate); // assuming selectedStore is the store passed from CashRegisterForm
        }
    }, [fromCashRegister, selectedStore, stores]);

    useEffect(() => {
        const fetchAndSetReceivedItems = async () => {
            if (purchaseOrderDetails && purchaseOrderDetails.items) {
                const fetchedItems = await Promise.all(purchaseOrderDetails.items.map(async (item) => {
                    if (!item.is_paid) {
                    try {
                        const inventoryDetails = await fetchInventoryItemDetails(item.inventory_item);
                        console.log("Inventory Details:", inventoryDetails);
                        return {
                            ...item,
                            inventory_item: {
                                id: inventoryDetails.id,
                                productName: inventoryDetails.productName,
                                expense_subcategory_id: inventoryDetails.expense_subcategory,
                            },
                            quantityReceived: 0,
                            totalToBePaid: 0,
                        };
                    } catch (error) {
                        console.error('Error fetching inventory item details:', error);
                        return null; // Or some error handling logic
                    }   
                }
                }));
                setReceivedItems(fetchedItems.filter(item => item != null));
            }
        };
    
        fetchAndSetReceivedItems();
    }, [purchaseOrderDetails]);

    const calculateGrandTotal = () => {
        return receivedItems.reduce((acc, item) => acc + item.totalToBePaid, 0);
    }

    const grandTotal = calculateGrandTotal();

    const handleQuantityReceivedChange = (index, quantityReceived) => {
        const updateReceivedItems = receivedItems.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    quantityReceived,
                    totalToBePaid: item.unit_cost * quantityReceived,
                };
            }
            return item;
        });
        setReceivedItems(updateReceivedItems);
    };
    //console.log("selected Store : ", selectedStore.value)

    const handleSubmit = () => {
        if (!selectedStore) {
            console.error('No store selected');
            alert("Select Shop")
            // Optionally, show an error message to the user indicating that a store selection is required.
            return; // Exit the function to prevent further execution
        }

        // Construct payment and receiving information payload
        const paymentAndReceivingInfo = {
            paymentType, 
            bankAccount: bankAccount || null, 
            amountPaid: grandTotal.toFixed(2), 
            paymentDate,
            paidBy,
            receivedDate, 
            receiverNotes,
            store_id: selectedStore ? selectedStore.value : '',
            is_cogs: isCOGSChecked,
            items: receivedItems.map(item => ({
                inventory_item: item.inventory_item.id,
                quantityReceived: item.quantityReceived,
                expense_subcategory: item.inventory_item.expense_subcategory_id,
                isPaid: item.quantityReceived > 0,
                unit_cost: item.unit_cost
            }))
            // Include other necessary data such as quantities received for each item
        };
        submitPayment(poNumber, paymentAndReceivingInfo).then(() => {
            setSuccessMessage('Payment saved');
            setTimeout(() => {
                setSuccessMessage('');
                closeModal();
            }, 3000); // Close modal after 3 seconds

            window.location.href = `/purchase-order`;
        })
        .catch((error) => {
            if (error && error.response) {
                console.error('Error during payment submission: ', error.response.data);
            } else {
                console.error('Error during payment submission: ', error);
            }
        });
    };

    return (
        <div className="payment-modal">
            <span className="payment-modal-close" onClick={closeModal}>&times;</span>
            <h3>Payment and Receiving Details</h3>
            <div className='mt-4'>
                <label>Received Date:</label>
                <input type="date" value={receivedDate} onChange={(e) => setReceivedDate(e.target.value)} />
            </div>
            {successMessage && <div className="success-message">{successMessage}</div>}
            {purchaseOrderDetails && purchaseOrderDetails.items && (
                <table>
                    <thead>
                        <tr>
                            <th>Name of Product</th>
                            <th>Quantity</th>
                            <th>Unit Cost</th>
                            <th>Total Cost</th>
                            <th>Quantity Received</th>
                            <th>Total to be Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivedItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.inventory_item.productName}</td>
                                <td>{item.quantity_ordered}</td>
                                <td>{item.unit_cost}</td>
                                <td>{item.total_cost}</td>
                                <td>
                                    <input 
                                        type="number" 
                                        min="0" 
                                        value={item.quantityReceived}
                                        onChange={(e) => handleQuantityReceivedChange(index, Number(e.target.value))}
                                    />
                                </td>
                                <td>{item.totalToBePaid.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5">Grand Total to be Paid</td>
                            <td>{grandTotal.toFixed(2)}</td>
                        </tr>                       
                    </tfoot>
                </table>
            )}
            <div className='mt-2'>
                <label>Receiver's Notes:</label>
                <textarea value={receiverNotes} onChange={(e) => setReceiverNotes(e.target.value)} />
            </div>
            <div className='mb-2 mt-2'>
                <label>Payment Type:</label>
                <select value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                    {paymentOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            {paymentType === 'Cash' && (
                <div>
                    <label>Store:</label>
                    <select value={selectedStore ? selectedStore.value : ''} onChange={handleStoreChange}>
                        <option value="" style={{color:"black"}}>Select a Store</option>
                        {stores.map(store => (
                            <option key={store.value} value={store.value} style={{color:"black"}}>
                                {store.label}</option>
                        ))}
                    </select>
                </div>
            )}
            {['Transfer', 'DebitCard', 'CreditCard'].includes(paymentType) && (
                <div>
                    <label>Bank Account:</label>
                    <select value={bankAccount} onChange={(e) => setBankAccount(e.target.value)}>
                        <option value="">Select a Bank Account</option>
                        {bankAccounts.map(account => (
                            <option key={account.id} value={account.id}>{account.bank_name} - {account.account_number}</option>
                        ))}
                    </select>

                    <label>Store:</label>
                 <select value={selectedStore ? selectedStore.value : ''} onChange={handleStoreChange}>
                     <option value="" style={{color:"black"}}>Select a Store</option>
                     {stores.map(store => (
                         <option key={store.value} value={store.value} style={{color:"black"}}>
                            {store.label}</option>
                     ))}
                 </select>
                </div>
            )}
            <div className='mt-2'>
                <label>Amount Paid:</label>
                <input type="number" value={grandTotal.toFixed(2)} onChange={(e) => setAmountPaid(e.target.value)} />
            </div>
            <div>
                <label>Payment Date:</label>
                <input type="date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
            </div>
            <div className='mt-2 mb-2'>
                <label>Paid By:</label>
                <input type="text" value={paidBy} onChange={(e) => setPaidBy(e.target.value)} />
            </div>
            <button onClick={handleSubmit} className='mr-2'>Submit Payment</button>
            <button onClick={closeModal}>Close</button>
        </div>
    );
};

export default PaymentModal;
