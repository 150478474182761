import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import "./sb-admin-2.min.css";

import "./bootstrap.bundle.min.js";
import "./jquery.easing.min.js";
import "./jquery.min.js";

import { useSelector } from 'react-redux';

const Sidebar = ({ userId }) => {
  const shop = useSelector((state) => state.shop);
  const clientId = shop.shopId
  const shopName = shop.shopName


  const [appointmentDropdownOpen, setAppointmentDropdownOpen] = useState(false);
  const [reservationDropdownOpen, setReservationDropdownOpen] = useState(false);
  const [clientCenterDropdownOpen, setClientCenterDropdownOpen] = useState(false);
  const [inventoryDropdownOpen, setInventoryDropdownOpen] = useState(false);
  const [managerDropdownOpen, setManagerDropdownOpen] = useState(false);

  const toggleAppointmentDropdown = () => {
    setAppointmentDropdownOpen(!appointmentDropdownOpen);
  };

  const toggleReservationDropdown = () => {
    setReservationDropdownOpen(!reservationDropdownOpen);
  };

  const toggleClientCenterDropdown = () => {
    setClientCenterDropdownOpen(!clientCenterDropdownOpen);
  };

  const toggleInventoryDropdown = () => {
    setInventoryDropdownOpen(!inventoryDropdownOpen);
  };

  const toggleManagerDropdown = () => {
    setManagerDropdownOpen(!managerDropdownOpen);
  };


  return (
    <div id="">
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
         
        >
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div>
          <div className="sidebar-brand-text mx-3">
          <Link to={`/`} className="text-decoration-none text-white">
            Logo
            </Link>
          </div>
        </a>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            <Link to={`/shop`} className="text-decoration-none text-white">
            Shop Detail</Link>
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            <Link to={`/shop-bookings`} className="text-decoration-none text-white">
              Today's Booking
            </Link>
          </a>
        </li>
        <li className="nav-item dropdown active">
          <a className="nav-link" onClick={toggleAppointmentDropdown} style={{cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
              Appointment {appointmentDropdownOpen ? <FontAwesomeIcon icon={faAngleUp} className="ml-2" /> : <FontAwesomeIcon icon={faAngleDown} className="ml-2" />}
          </a>
          <div className={`dropdown-menu ${appointmentDropdownOpen ? "show" : ""}`} aria-labelledby="appointmentDropdown">
            <Link to={`/booking`} className="dropdown-item">Appointment</Link>
            <Link to={`/appointment-bookings`} className="dropdown-item">Appointment Calendar</Link>
          </div>
        </li>
        <li className="nav-item dropdown active">
          <a className="nav-link " href="#" role="button" id="reservationDropdown" onClick={toggleReservationDropdown}>
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            Reservation
            {reservationDropdownOpen ? <FontAwesomeIcon icon={faAngleUp} className="ml-2" /> : <FontAwesomeIcon icon={faAngleDown} className="ml-2" />}
          </a>
          <div className={`dropdown-menu ${reservationDropdownOpen ? "show" : ""}`} aria-labelledby="reservationDropdown">
            <Link to={`/reservation`} className="dropdown-item">Reservation</Link>
            <Link to={`/reservation-bookings`} className="dropdown-item">Reservation Calendar</Link>
          </div>
        </li>
        <li className="nav-item dropdown active">
          <a className="nav-link " href="#" role="button" id="clientCenterDropdown" onClick={toggleClientCenterDropdown}>
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            Client Center
            {clientCenterDropdownOpen ? <FontAwesomeIcon icon={faAngleUp} className="ml-2" /> : <FontAwesomeIcon icon={faAngleDown} className="ml-2" />}
          </a>
          <div className={`dropdown-menu ${clientCenterDropdownOpen ? "show" : ""}`} aria-labelledby="clientCenterDropdown">
            <Link to={`/shop-clients`} className="dropdown-item">Client Center</Link>
            <Link to={`/shop-add-clients`} className="dropdown-item">Add New Client</Link>
            <Link to={`/client-leads`} className="dropdown-item">Client Leads</Link>
          </div>
        </li>
        <li className="nav-item dropdown active">
          <a className="nav-link " href="#" role="button" id="inventoryDropdown" onClick={toggleInventoryDropdown}>
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            Inventory
            {inventoryDropdownOpen ? <FontAwesomeIcon icon={faAngleUp} className="ml-2" /> : <FontAwesomeIcon icon={faAngleDown} className="ml-2" />}
          </a>
          <div className={`dropdown-menu ${inventoryDropdownOpen ? "show" : ""}`} aria-labelledby="inventoryDropdown">
            <Link to={`/inventory-main`} className="dropdown-item">Inventory Main</Link>
            <Link to={`/item-categories`} className="dropdown-item">Item Categories</Link>
            <Link to={`/reorder-items`} className="dropdown-item">Reorder Items</Link>
            <Link to={`/promotions-main`} className="dropdown-item">Promotions Main</Link>
            <Link to={`/promo-groups`} className="dropdown-item">Promo Groups</Link>
            <Link to={`/vendors`} className="dropdown-item">Vendors</Link>
            <Link to={`/services`} className="dropdown-item">Services</Link>
            <Link to={`/purchase-order`} className="dropdown-item">Purchase Orders</Link>
          </div>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            <Link to={`/point-of-sale`} className="text-decoration-none text-white">
              Point of Sale</Link>
          </a>
        </li>
        <li className="nav-item dropdown active">
          <a className="nav-link " href="#" role="button" id="clientCenterDropdown" onClick={toggleManagerDropdown}>
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            Manager
            {managerDropdownOpen ? <FontAwesomeIcon icon={faAngleUp} className="ml-2" /> : <FontAwesomeIcon icon={faAngleDown} className="ml-2" />}
          </a>
          <div className={`dropdown-menu ${managerDropdownOpen ? "show" : ""}`} aria-labelledby="clientCenterDropdown">
            <Link to={`/banks`} className="dropdown-item">Banks</Link>
          </div>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            <Link to={`/shop-add-pet/${userId}`} className="text-decoration-none text-white">
                    Add Pet</Link>
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4"/>
            <Link to={`/flags-list`} className="text-decoration-none text-white">
                   Flags List</Link>
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link to={`/message-screen`} className="text-decoration-none text-white">
              Messaging
            </Link>
          </a>
        </li>
      </ul>
      
    </div>
  );
};

export default Sidebar;
