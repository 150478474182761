import React, { useState, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";

const ClientBySource = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);
  const reportRef = useRef();


  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (startDate && endDate) {
      try {
        const response = await axios.post(
          `${apiUrl}api/clients-referal-report/`,
          {
            //start_date: startDate.toISOString().split('T')[0],
            //end_date: endDate.toISOString().split('T')[0],
            start_date: startDate,
            end_date: endDate,
          }
        );
        setResult(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const headers = [
    { label: "Referral Source", key: "source" },
    { label: "Count", key: "count" },
  ];

  const csvData = result
    ? [
        { source: "Search Results - Google", count: result.google_count },
        { source: "Referral - Friend or Family", count: result.referral_family_count },
        { source: "Search Results - Bing", count: result.bing_count },
        { source: "Search Results - Yahoo", count: result.yahoo_count },
        { source: "Social - Facebook", count: result.facebook_count },
        { source: "Social - Twitter", count: result.twitter_count },
        { source: "Social - Instagram", count: result.instagram_count },
        { source: "Referral - Groomer", count: result.groomer_count },
        { source: "Referral - Pet Store", count: result.store_count },
        { source: "Referral - Veterinarian", count: result.veterinarian_count },
        { source: "Returning Client", count: result.returning_client_count },
        { source: "Saw Sign - Drive by", count: result.drive_by_count },
        { source: "Advertisement - Online / Digital", count: result.advertisement_count },
        { source: "Advertisement - Print", count: result.print_count },
        { source: "Advertisement - Radio", count: result.radio_count },
        { source: "Walk In", count: result.walk_count },
        { source: "Groupon", count: result.groupon_count },
        { source: "ValPack", count: result.valpack_count },
        { source: "Website", count: result.website_count },
        { source: "Yelp", count: result.yelp_count },
        { source: "Other Source - Not Listed", count: result.other_count }
      ]
    : [];


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {result && (
        <div>
          <div>
          <div className="d-flex justify-content-end mb-3">
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"referral-report.csv"}
              className="btn btn-primary me-2"
            >
              Export to CSV
            </CSVLink>
            <button className="btn btn-secondary" onClick={handlePrint}>
              Print
            </button>
          </div>
          <div ref={reportRef}>
          <Table striped bordered hover>
            <tbody>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>
                  <strong>Referral Source</strong>
                </td>
                <td>
                  <strong>Count</strong>
                </td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Search Results - Google</td>
                <td>{result.google_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Search Results - Bing</td>
                <td>{result.bing_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Search Results - Yahoo</td>
                <td>{result.yahoo_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Referral - Friend or Family</td>
                <td>{result.referral_family_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Social - Facebook</td>
                <td>{result.facebook_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Social - Twitter</td>
                <td>{result.twitter_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Social - Instagram</td>
                <td>{result.instagram_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Referral - Groomer</td>
                <td>{result.groomer_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Referral - Pet Store</td>
                <td>{result.store_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Referral - Veterinarian</td>
                <td>{result.veterinarian_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Returning Client</td>
                <td>{result.returning_client_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Saw Sign - Drive by</td>
                <td>{result.drive_by_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Advertisement - Online / Digital</td>
                <td>{result.advertisement_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Advertisement - Print</td>
                <td>{result.print_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Advertisement - Radio</td>
                <td>{result.radio_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Walk In</td>
                <td>{result.walk_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Groupon</td>
                <td>{result.groupon_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>ValPack</td>
                <td>{result.valpack_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Website</td>
                <td>{result.website_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Yelp</td>
                <td>{result.yelp_count}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td>Other Source - Not Listed</td>
                <td>{result.other_count}</td>
              </tr>
            </tbody>
          </Table>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientBySource;
