import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import "./PurchaseOrderForm.css";
import PaymentModal from "./PurchaseOrderPaymentModal.js"; // Import the PaymentModal component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faMoneyBillWave,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import PofHeader from "../PofHeader.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const PurchaseOrderCreateForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poDate, setPoDate] = useState(new Date());
  const [status, setStatus] = useState("");
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [search, setSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantityOrdered, setQuantityOrdered] = useState(1);
  const [unitCost, setUnitCost] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(true);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedPOForPayment, setSelectedPOForPayment] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedPurchaseOrderDetails, setSelectedPurchaseOrderDetails] =
    useState(null);
  const [isCOGSChecked, setIsCOGSChecked] = useState(false);

  const resetForm = () => {
    // Function to reset the form fields
    setSelectedVendor(null);
    setPoDate(new Date());
    setOrderItems([]);
    setGrandTotal(0);
    setSelectedStore(null);
    setSelectedPaymentTerms("");
    setPaymentMethod("");
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/stores/`)
      .then((response) => {
        const storeOptions = response.data.map((s) => ({
          value: s.id,
          label: s.ShopName + ", " + s.Address,
        }));
        setStores(storeOptions);
      })
      .catch((error) => console.error("Error fetching stores:", error));
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/vendor/search/`)
      .then((response) => {
        const vendorOptions = response.data.map((v) => ({
          value: v.id,
          label: v.business_name,
        }));
        setVendors(vendorOptions);
      })
      .catch((error) => console.error("Error fetching vendors:", error));
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/accounts/bank/`)
      .then((response) => {
        console.log("nonsense", response.data);
        setBankAccounts(response.data); // Adjust according to your actual response structure
      })
      .catch((error) => console.error("Error fetching bank accounts:", error));
  }, []);

  useEffect(() => {
    if (selectedVendor) {
      setSelectedItem(null);
      setQuantityOrdered(1);
      setUnitCost("");
      setOrderItems([]);
      setGrandTotal(0);
      setInventoryItems([]);
      axios
        .get(`${apiUrl}inventory/inventory/?vendor_id=${selectedVendor.value}`)
        .then((response) => {
          console.log("Inventory items fetched:", response.data);
          const itemOptions = response.data.map((i) => ({
            value: i.id,
            label: i.productName,
            unitCost: i.cost_per_piece || i.cost_per_hundred_grams, // Use cost_per_piece if available, else cost_per_hundred_grams
          }));
          setInventoryItems(itemOptions);
        })
        .catch((error) =>
          console.error("Error fetching inventory items:", error)
        );
    } else {
      setInventoryItems([]);
    }
  }, [selectedVendor]);

  const openPaymentModal = async (id) => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/purchase-orders/${id}/`
      );

      console.log("payment purchase : ", response.data);
      setSelectedPurchaseOrderDetails(response.data);
      //setBankAccounts(response.data);
      setSelectedPOForPayment(id);
      setIsPaymentModalOpen(true);
    } catch (error) {
      console.error("Error fetching purchase order details:", error);
    }
  };

  const submitPayment = (poNumber, paymentDetails) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${apiUrl}inventory/purchase-orders/update-payment/${poNumber}/`,
          paymentDetails
        )
        .then((response) => {
          console.log("Payment updated:", response.data);
          alert("Payment updated!");
          resolve(); // Resolve the promise on successful update
          //window.location.href = `/purchase-order`;
        })
        .catch((error) => {
          console.error("Error updating payment:", error);
          reject(); // Reject the promise on error
        });
    });
  };

  const handleAddNewPurchaseOrder = () => {
    setShowForm(true); // Show form when adding new purchase order
    setShowSearchResults(false); // Hide search results
    resetForm(); // Optionally reset form here if needed
  };

  const handleItemSelect = (selectedItem) => {
    setSelectedItem(selectedItem);
    setUnitCost(selectedItem.unitCost);
  };

  const updateSelectedStore = (storeValue) => {
    const selected = stores.find((store) => store.value === storeValue);
    setSelectedStore(selected);
  };

  const handlePaymentTermsChange = (selectedOption) => {
    setSelectedPaymentTerms(selectedOption); // Ensure this sets the entire option object
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePay = (poNumber) => {
    console.log(`Pay clicked for PO Number: ${poNumber}`);
    // Implement the pay functionality here
  };

  const handleDelete = async (poNumber) => {
    if (
      window.confirm("Are you sure you want to delete this purchase order?")
    ) {
      try {
        await axios.delete(
          `${apiUrl}inventory/purchase-orders/delete/${poNumber}`
        );
        // Remove the vendor from searchResults or re-fetch the vendors
        setPurchaseOrders((prevResults) =>
          prevResults.filter((po) => po.id !== poNumber)
        );
        alert("Purchase order deleted successfully");
      } catch (error) {
        console.error(
          "Error deleting po",
          error.response?.data || error.message
        );
        alert("Failed to delete po");
      }
    }
  };

  const handleAddItem = () => {
    const newItem = {
      id: uuidv4(),
      inventoryItem: selectedItem.value,
      itemName: selectedItem.label,
      quantityOrdered: quantityOrdered,
      unitCost: unitCost,
      totalCost: quantityOrdered * unitCost,
    };
    setOrderItems([...orderItems, newItem]);
  };

  const calculateGrandTotal = (items) => {
    const total = items.reduce(
      (acc, item) => acc + item.quantityOrdered * item.unitCost,
      0
    );
    setGrandTotal(total);
  };

  useEffect(() => {
    calculateGrandTotal(orderItems);
  }, [orderItems]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let searchParams = new URLSearchParams();
    if (search) {
      searchParams.append("search", search);
    }

    axios
      .get(`${apiUrl}inventory/purchase-orders/?${searchParams.toString()}`)
      .then((response) => {
        setPurchaseOrders(response.data);
        resetForm(); // Reset and hide the form upon searching
        setShowSearchResults(true);
        setShowForm(false);
        console.log("response.data : ", response.data);
      })
      .catch((error) =>
        console.error("Error searching purchase orders:", error)
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const purchaseOrderData = {
      po_date: poDate.toISOString().split("T")[0],
      vendor: selectedVendor ? selectedVendor.value : null,
      delivery_address: selectedStore ? selectedStore.label : "",
      payment_terms: selectedPaymentTerms.value,
      payment_method: paymentMethod || "",
      po_creator: "Benjamin",
      subtotal: grandTotal.toFixed(2),
      is_cogs: isCOGSChecked,
      items: orderItems.map((item) => ({
        inventory_item: item.inventoryItem,
        quantity_ordered: item.quantityOrdered,
        unit_cost: item.unitCost,
        total_cost: item.totalCost,
      })),
    };
    console.log("Sending Purchase Order Data:", purchaseOrderData);
    try {
      const response = await axios.post(
        `${apiUrl}inventory/purchase-orders/`,
        purchaseOrderData
      );
      setSuccessMessage("Purchase order saved successfully.");
      alert("Purchase order saved successfully.");
      setTimeout(() => setSuccessMessage(""), 3000); // Hide message after 3 seconds
      resetForm(); // Reset form fields after submission
      setShowForm(false); // Hide form after submission
      window.location.href = `/purchase-order`;
    } catch (error) {
      console.error("Error creating purchase order:", error);
    }

    // Handle form submission
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12" style={{ paddingLeft: "0px" }}>
          <form onSubmit={handleSubmit}>
            <div className="inventory-form">
              <div className="row">
                <div className="row">
                  <div className="col-6">
                    <label>PO Date:</label>
                    <input
                      type="date"
                      name="poDate"
                      value={
                        poDate instanceof Date
                          ? poDate.toISOString().split("T")[0]
                          : ""
                      }
                      onChange={(event) =>
                        setPoDate(new Date(event.target.value))
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="col-6">
                    <label>Delivery Address:</label>
                    <Select
                      options={stores}
                      value={selectedStore}
                      onChange={setSelectedStore}
                      placeholder="Select Store for Delivery"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <label>Vendor:</label>
                    <Select
                      options={vendors}
                      value={selectedVendor}
                      onChange={setSelectedVendor}
                    />
                  </div>
                  <div className="col-6">
                    <label>Payment Terms:</label>
                    <Select
                      options={[
                        { value: "Day of Delivery", label: "Day of Delivery" },
                        {
                          value: "Next Day of Delivery",
                          label: "Next Day of Delivery",
                        },
                        {
                          value: "10th of the Month",
                          label: "10th of the Month",
                        },
                        {
                          value: "25th of the Month",
                          label: "25th of the Month",
                        },
                      ]}
                      onChange={handlePaymentTermsChange}
                      value={selectedPaymentTerms}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <label>Item:</label>
                    <Select
                      options={inventoryItems}
                      value={selectedItem}
                      onChange={handleItemSelect}
                    />
                  </div>
                  <div className="col-6" style={{ marginTop: "35px" }}>
                    <label htmlFor="cogs" className="mr-2">
                      Cost of Goods Sold:
                    </label>
                    <input
                      type="checkbox"
                      id="cogs"
                      checked={isCOGSChecked}
                      onChange={(e) => setIsCOGSChecked(e.target.checked)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <label>Quantity Ordered:</label>
                    <input
                      type="number"
                      value={quantityOrdered}
                      onChange={(e) => setQuantityOrdered(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <label>Unit Cost:</label>
                    <input
                      type="number"
                      value={unitCost}
                      onChange={(e) => setUnitCost(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 text-center mb-4">
                  <button type="button" onClick={handleAddItem}>
                    Add Item
                  </button>
                </div>
                <h3 className="mt-4">Order Items</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Unit Cost</th>
                      <th>Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.itemName}</td>
                        <td>
                          <input
                            type="number"
                            value={item.quantityOrdered}
                            onChange={(e) => {
                              const updatedItem = {
                                ...item,
                                quantityOrdered: e.target.value,
                                totalCost: e.target.value * item.unitCost,
                              };
                              setOrderItems(
                                orderItems.map((i) =>
                                  i.id === item.id ? updatedItem : i
                                )
                              );
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={item.unitCost}
                            onChange={(e) => {
                              const updatedItem = {
                                ...item,
                                unitCost: e.target.value,
                                totalCost:
                                  e.target.value * item.quantityOrdered,
                              };
                              setOrderItems(
                                orderItems.map((i) =>
                                  i.id === item.id ? updatedItem : i
                                )
                              );
                            }}
                          />
                        </td>
                        <td>
                          {(item.quantityOrdered * item.unitCost).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3">Grand Total</td>
                      <td>{grandTotal.toFixed(2)}</td>
                    </tr>
                  </tfoot>
                </table>
                <div className="col-12 text-center mt-4">
                  <button type="submit">Submit Order</button>
                </div>
                {successMessage && (
                  <div className="success-message">{successMessage}</div>
                )}{" "}
                {/* Display success message */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderCreateForm;
