import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const RevenueChart = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shop, setShop] = useState("");
  const [data, setData] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiUrl, userInfo.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}inventory/revenue-service/`, {
        start_date: startDate,
        end_date: endDate,
        shop: selectedUser,
      });
      setData(response.data.sale_data);
    } catch (error) {
      console.error("Error fetching revenue data:", error);
    }
  };

  const calculateTimeGrouping = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInDays = (end - start) / (1000 * 3600 * 24);  // Difference in days
    return differenceInDays <= 14 ? "day" : "week";
  }

  const chartData = {
    labels: data ? data.dates : [],
    datasets: [
      {
        label: "Number of Pets Done",
        data: data ? data.pets_done : [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4>Revenue Services (Grooming)</h4>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-row justify-content-evenly mb-4 bg-white border-0"
        >
          <div className="d-flex flex-column me-4">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column me-4">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column me-4">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn btn-primary align-self-end">
            Submit
          </button>
        </form>

        {data && (
          <div>
            <ul>
              <li>Total Revenue: ${data.total_revenue.toFixed(2)}</li>
              <li>Total Pets Done: {data.total_appointments}</li>
              <li>
                Average Revenue per Pet: $
                {data.average_revenue_per_service.toFixed(2)}
              </li>
            </ul>
            <Line data={chartData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RevenueChart;

