import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
//import Sidebar from "../Design/ClientCenterSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Modal, Button } from "react-bootstrap";

const PromotionsMain = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  //const { shopName } = useParams();
  //const { id } = useParams();
  const [inventory, setInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  //
  const [selectedNames, setSelectedNames] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await fetch(`${apiUrl}inventory/promotions/`);
        const data = await response.json();
        console.log("inventory :", data);
        setInventory(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching inventory:", error);
      }
    };

    fetchInventory();
  }, [apiUrl]);


  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };


  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "20px", fontWeight: "700", color: "#282c34" }}
        >
          Promotions
        </h3>

        {/********** */}

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Label</th>
                <th>Promo Type</th>
                <th>Promo Code</th>
                <th>Disc Amount</th>
                <th>Promo Start</th>
                <th>Promo End</th>
              </tr>
            </thead>
            <tbody>
            {inventory.length > 0 ? (
              inventory.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => handleOpenModal(item.id)}
                  style={{
                    height: "50px",
                    borderBottom: "1px solid #dee2e6",
                    cursor: "pointer",
                  }}
                >
                  <td style={{ textAlign: "center" }}>
                    {item.label ? `${item.label}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.promo_type ? `${item.promo_type}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.promo_code ? `${item.promo_code}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.disc_amount ? item.disc_amount : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.promo_start ? item.promo_start : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.promo_end ? item.promo_end : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td colSpan={6} style={{ textAlign: "center" }}>No Results</td>
              </tr>
            )}
          </tbody>

          </Table>
        </div>
      </div>
    </div>
  );
};

export default PromotionsMain;
