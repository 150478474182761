import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PurchaseOrderPaymentModal from '../Vendors/PurchaseOrderPaymentModal.js'; // Import the payment modal

const CashRegisterWithdrawModal = ({ onClose, handleCashMovement, cashMovements, totalClosingCash, discrepancyAmount, setShowModal, stores, submitPayment, selectedStore, setSelectedStore }) => {
    const [step, setStep] = useState(1); // To manage modal steps
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalReason, setWithdrawalReason] = useState('');
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [selectedPO, setSelectedPO] = useState(null); 

    useEffect(() => {
        switch(withdrawalReason) {
            case 'payVendor':
                axios.get('/purchase-orders/?status=pending')
                    .then(response => {
                        console.log("Fetched POs:", response.data);
                        setPurchaseOrders(response.data);
                    })
                    .catch(error => console.error('Error fetching POs:', error));
                break;
            case 'payCommissions':
                break;
            case 'transferMoney':
                break;
            default:
        }
    }, [withdrawalReason]);

    const openPaymentModal = (po) => {
        console.log("Opening Payment Modal with PO: ", po);
        setSelectedPO(po);
    }
    
    const closeModal = () => {
        console.log("Closing modal");
        setSelectedPO(null);
        if(onClose) onClose(); // Call the passed onClose prop if provided
    };

    return (
        <div className="modal">
            <div className="modal-content">
            <span className="close-modal" onClick={closeModal}>&times;</span>
                {step === 1 && (
                <>
                    <h2>{discrepancyAmount > 0 ? "Amount does not match" : "Withdraw or Transfer Money"}</h2>
                    {discrepancyAmount > 0 && <p>The correct closing cash should be: ${discrepancyAmount}</p>}
                    <button onClick={() => setStep(2)}>Withdraw</button>
                    <button onClick={closeModal}>Cancel</button>
                </>
                )}
                {step === 2 && (
                    <>
                        <label htmlFor="reason">Reason for Withdrawal:</label>
                        <select
                            id="reason"
                            value={withdrawalReason}
                            onChange={(e) => setWithdrawalReason(e.target.value)}
                        >
                            <option value="SelectOption">Select an option</option>
                            <option value="payVendor">Pay Vendor</option>
                            <option value="payCommissions">Pay Commissions</option>
                            <option value="moveToAccount">Move to Another Account</option>
                        </select>
                        {withdrawalReason === 'payVendor' && (
                            <div className="scrollable-modal-content">
                                <h2>Pay Vendor</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>PO Number</th>
                                            <th>PO Date</th>
                                            <th>Vendor</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchaseOrders.map (po => (
                                            <tr key={po.id}>
                                                <td>{po.po_number}</td>
                                                <td>{po.po_date}</td>
                                                <td>{po.vendor_details.business_name}</td>
                                                <td>{po.status}</td>
                                                <td>
                                                    <button onClick={() => openPaymentModal(po)}>Pay</button>
                                                </td>
                                            </tr>
                                        ))}
                                        {selectedPO && selectedPO.po_number && (
                                            <>
                                            {console.log("Passing selectedPO to Payment Modal:", selectedPO)}
                                            <PurchaseOrderPaymentModal
                                                poNumber={selectedPO.po_number}
                                                closeModal={() => setSelectedPO(null)}
                                                submitPayment={submitPayment}
                                                //bankAccounts={bankAccounts}
                                                stores={stores} // Ensure this prop is passed
                                                purchaseOrderDetails={selectedPO}
                                                selectedStore={selectedStore} // Pass selectedStore to the Payment Modal
                                                setSelectedStore={setSelectedStore}
                                            />
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {selectedPO && 
                            <PurchaseOrderPaymentModal
                                poNumber={selectedPO.id}
                                closeModal={closeModal}
                                onClose={() => setSelectedPO(null)}
                                submitPayment={submitPayment}
                                stores={stores}
                                purchaseOrderDetails={selectedPO}
                                selectedStore={selectedStore}
                                setSelectedStore={setSelectedStore}
                                fromCashRegister={true}
                            />
                        }
                    <button onClick={() => setStep(1)}>Back</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default CashRegisterWithdrawModal;
