export const addToCart = (item) => ({
    type: 'ADD_TO_CART',
    payload: item,
  });
  
export const removeFromCart = (uniqueId) => ({
  type: 'REMOVE_FROM_CART',
  payload: { uniqueId },
});
  

export const addMultipleToCart = (item) => ({
  type: 'ADD_MULTIPLE_TO_CART',
  payload: item,
});

export const clearCart = () => ({
  type: 'CLEAR_CART',
});
