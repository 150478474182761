import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import moment from "moment";

import { Link } from 'react-router-dom';

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetch(`${apiUrl}api/bookings/`)
      .then((response) => response.json())
      .then((data) => setBookings(data))
      .catch((error) => console.error("Error fetching bookings:", error));
  }, []);

  const today = new Date().toISOString().split("T")[0];

  const filteredBookings = bookings.filter(
    (booking) => booking.start.split("T")[0] === today
  );

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
  };

  const handleCloseModal = () => {
    setSelectedBooking(null);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
        <div className="shop-sidebar">
      <h2>Links</h2>
      <ul>
        <li><Link to={`/BookingList`}>Today's Booking</Link></li>
        <li><Link to={``}>Appointment</Link></li>
        <li><Link to={``}>Reservation</Link></li>
        <li><Link to={``}>Appointment Calender</Link></li>
        <li><Link to={``}>Reservation Calender</Link></li>
      </ul>
    </div>
        </div>
        <div className="col-8">
      <h1 className="text-center">Booking List for Today</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Booking Name</th>
            <th>Client</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookings.map((booking) => (
            <tr key={booking.id} onClick={() => handleBookingClick(booking)}>
              <td>{booking.title}</td>
              <td>{booking.owner_first_name}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal to display booking details */}
      {selectedBooking && (
        <div
          className="modal"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div
            className="modal-content"
            style={{
              position: "relative",
              width: "50%",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <span
              className="close"
              onClick={handleCloseModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              &times;
            </span>
            <h2>{selectedBooking.title}</h2>
            <table>
              <tbody>
                <tr>
                  <td>Start</td>
                  <td>{moment(selectedBooking.start).format("LLL")}</td>
                </tr>
                <tr>
                  <td>End</td>
                  <td>{moment(selectedBooking.end).format("LLL")}</td>
                </tr>
                <tr>
                  <td>Client</td>
                  <td>{selectedBooking.owner_first_name}</td>
                </tr>
                <tr>
                  <td>Pet Name</td>
                  <td>{selectedBooking.pet_name}</td>
                </tr>
                <tr>
                  <td>Booking Type</td>
                  <td>{selectedBooking.booking_type}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      </div>
      </div>
    </div>
  );
};

export default BookingList;
