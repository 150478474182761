import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Table, Button, Modal } from "react-bootstrap";

import ClockInOut from "./../Attendance/ClockInOut.js";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from "../../actions/userActions";

const MyProfile = () => {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  /* HANDLER */
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${apiUrl}api/my-profile/`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user details!", error);
      });
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          About {user.first_name} {user.last_name}
        </h3>
        <div className="d-flex justify-content-center">
          <Link to={`/employee-edit/${user.id}/`}>
            <button className="mb-4 mt-4 mr-2">Edit</button>
          </Link>
          <Link to={`/work-time/${user.id}/`}>
            <button className="mb-4 mt-4 mr-2">Work Time</button>
          </Link>
          <Link to={`/add-work-time/${user.id}/`}>
            <button className="mb-4 mt-4 mr-2">Add Work Time</button>
          </Link>
          <button className="mb-4 mt-4" onClick={handleOpenModal}>
            Clock
          </button>
        </div>
        <Table className="mt-4">
          <tbody>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                First Name
              </td>
              <td>{user.first_name}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Last Name
              </td>
              <td>{user.last_name}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Username
              </td>
              <td>{user.username}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>Email</td>
              <td>{user.email}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>Role</td>
              <td>{user.employee_info?.user_role}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>Phone</td>
              <td>{user.employee_info?.phone}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>Cell</td>
              <td>{user.employee_info?.cell}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Address
              </td>
              <td>{user.employee_info?.address}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>City</td>
              <td>{user.employee_info?.city}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Postal Code
              </td>
              <td>{user.employee_info?.postal_code}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>Status</td>
              <td>{user.employee_info?.status}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Service Level
              </td>
              <td>{user.employee_info?.service_level}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Efficiency
              </td>
              <td>{user.employee_info?.efficiency}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Calendar Assignment
              </td>
              <td>{user.employee_info?.calendar_assignment}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Run Assignment
              </td>
              <td>{user.employee_info?.run_assignment}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Date of Hire
              </td>
              <td>{user.employee_info?.date_of_hire}</td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
              <td style={{ textAlign: "center", fontWeight: "700" }}>
                Last Update
              </td>
              <td>{user.employee_info?.last_update}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Employ Time Clock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClockInOut />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyProfile;
