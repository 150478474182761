import React, { useEffect, useState } from "react";
import { Table, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import InactiveClients from "./InactiveClients.js";
import Timecards from "./Timecards.js";
import ClientBySource from "./ClientBySource.js";
import PetBirthday from "./PetBirthday.js";
import ItemSoldReport from "./ItemSoldReport.js";
import TopSellers from "./TopSellers.js";
import SalesReport from "./SalesReport.js"
import ExpenseReport from "./ExpenseReport.js"
import PayrollReport from "./PayrollReport.js"
import EmployeeCommission from "./EmployeeCommission.js"

import { useSelector } from "react-redux";

function Reports() {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Reports
        </h3>
        <div className="row mb-5">
          <DropdownButton
            id="dropdown-basic-button"
            title="Select Report"
            onSelect={handleSelect}
          >
            <Dropdown.Item eventKey="InactiveClients">
              Inactive Clients
            </Dropdown.Item>
            <Dropdown.Item eventKey="Timecards">Timecards</Dropdown.Item>
            <Dropdown.Item eventKey="ClientBySource">
              Client by referral source
            </Dropdown.Item>
            <Dropdown.Item eventKey="Birthday">Birthday</Dropdown.Item>
            <Dropdown.Item eventKey="ItemSoldReport">Item Sold Report</Dropdown.Item>
            <Dropdown.Item eventKey="TopSellers">Top Sellers</Dropdown.Item>
            <Dropdown.Item eventKey="SalesReport">Sales Report</Dropdown.Item>
            <Dropdown.Item eventKey="ExpenseReport">Expense Report</Dropdown.Item>
          {/*}  <Dropdown.Item eventKey="PayrollReport">Payroll Report</Dropdown.Item>*/}
            <Dropdown.Item eventKey="EmployeeCommission">Employee Commission Report</Dropdown.Item>
          </DropdownButton>
        </div>

        {selectedOption === "InactiveClients" && <InactiveClients />}
        {selectedOption === "Timecards" && <Timecards />}
        {selectedOption === "ClientBySource" && <ClientBySource />}
        {selectedOption === "Birthday" && <PetBirthday />}
        {selectedOption === "ItemSoldReport" && <ItemSoldReport />}
        {selectedOption === "TopSellers" && <TopSellers />}
        {selectedOption === "SalesReport" && <SalesReport />}
        {selectedOption === "ExpenseReport" && <ExpenseReport />}
        {selectedOption === "PayrollReport" && <PayrollReport />}
        {selectedOption === "EmployeeCommission" && <EmployeeCommission />}
      </div>
    </div>
  );
}

export default Reports;
