import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pie, Bar } from "react-chartjs-2";
import "chart.js/auto";
import "../SalesComponents/StoreSalesPieChart.css";

const RetailSalesPieChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Sales",
        data: [],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  });

  const [topProductsData, setTopProductsData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Quantity Sold",
        data: [],
        backgroundColor: "#00a6a6",
      },
    ],
  });

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [salesData, setSalesData] = useState([]);
  const [salesCategoryData, setSalesCategoryData] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/retail-sales/`)
      .then((response) => {
        console.log("API response:", response.data);
        
        if (Array.isArray(response.data.sales_by_store)) {
          const storeNames = response.data.sales_by_store.map(
            (store) => store.ShopName
          );
          const storeSales = response.data.sales_by_store.map(
            (store) => store.total_sales
          );

          setChartData({
            labels: storeNames,
            datasets: [
              {
                label: "Total Sales",
                data: storeSales,
                backgroundColor: [
                  "#00a6a6",
                  "#51c5c5",
                  "#43cbc7",
                  "#4BC0C0",
                  "#9966FF",
                  "#FF9F40",
                ],
              },
            ],
          });

          setSalesData(response.data.sales_by_store);

          if (response.data && response.data.sales_by_category) {
            // Prepare an array to display divisions and subcategories
            const salesCategoryDisplay = {
              Grooming: {
                total_sales: 0,
                subcategories: { Bath: 0, Grooming: 0 },
              },
              "Day Care": {
                total_sales: 0,
                subcategories: { "Day Care": 0, Walking: 0 },
              },
              Products: {
                total_sales: 0,
                subcategories: {},
              },
            };
            
            // Use Object.entries to iterate over the sales_by_category object
            Object.entries(response.data.sales_by_category).forEach(([category, categorySales]) => {
              const { total_sales } = categorySales;

              // Grooming Division
              if (category === "Grooming") {
                Object.entries(categorySales.subcategories).forEach(([subcategory, subcategorySales]) => {
                  salesCategoryDisplay.Grooming.subcategories[subcategory] += subcategorySales;
                  salesCategoryDisplay.Grooming.total_sales += subcategorySales;
                });
              }
            
              // Day Care Division
              if (category === "Day Care") {
                Object.entries(categorySales.subcategories).forEach(([subcategory, subcategorySales]) => {
                  salesCategoryDisplay["Day Care"].subcategories[subcategory] += subcategorySales;
                  salesCategoryDisplay["Day Care"].total_sales += subcategorySales;
                });
              }              
            });

            // Products Division
            const productSalesData = response.data.product_sales_data.Products;
            salesCategoryDisplay.Products.total_sales = productSalesData.total_sales;
            salesCategoryDisplay.Products.subcategories = { ...productSalesData.subcategories };

            const formattedSalesCategoryData = Object.entries(
              salesCategoryDisplay
            ).map(([division, divisionData]) => ({
              category: division,
              total_sales: divisionData.total_sales,
              subcategories: Object.entries(divisionData.subcategories).map(
                ([subcategory, subcategorySales]) => ({
                  subcategory,
                  subcategory_sales: subcategorySales,
                })
              ),
            }));

            setSalesCategoryData(formattedSalesCategoryData);
          }
          
          // Update top products chart data
          const productNames = response.data.most_sold_products.map(
            (product) => product.product_name
          );
          const productQuantities = response.data.most_sold_products.map(
            (product) => product.total_quantity_sold
          );

          setTopProductsData({
            labels: productNames,
            datasets: [
              {
                label: "Total Quantity Sold",
                data: productQuantities,
                backgroundColor: "#00a6a6",
              },
            ],
          });
        } else {
          console.error("Unexpected response data:", response.data);
        }
      })
      .catch((error) => console.error("Error fetching sales data:", error));
  }, [apiUrl]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card">
              <div className="card-body">
                <h4 className="mb-4 mt-4">Sales by Store</h4>
                <div className="chart-container">
                {chartData ? (
                  <Pie data={chartData} className="chart-data" />
                ) : (
                  <p>No available data</p>
                )}
                </div>
                <div className="mt-4">
                  {salesData.map((store, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <span
                        className="legend-color me-2"
                        style={{
                          backgroundColor:
                            chartData.datasets[0].backgroundColor[index],
                        }}
                      ></span>
                      <span className="me-2">{store.ShopName}</span>
                      <span>{store.total_sales != null ? store.total_sales : "0"}%</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card">
              <div className="card-body">
                <h4 className="mb-4 mt-4">Sales by Division</h4>
                <div className="d-flex flex-column">
                  {salesCategoryData.map((category, index) => (
                    <div key={index} className="mb-4">
                      <strong>
                        <div className="d-flex justify-content-between mb-2">
                          <span>{category.category}:</span>
                          <span>${category.total_sales.toLocaleString()}</span>
                        </div>
                      </strong>
                      {category.subcategories.map((subcategory, subIndex) => (
                        <div key={subIndex} className="d-flex justify-content-between ms-4"> 
                          <span>{subcategory.subcategory}:</span>
                          <span>${subcategory.subcategory_sales.toLocaleString()}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <strong>Total (Products + Day Care + Grooming):</strong>
                    <strong>
                      ${salesCategoryData.reduce(
                        (sum, category) => sum + category.total_sales,
                        0
                      ).toLocaleString()}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mb-4 mt-4">Top Sold Items</h4>
                <Bar data={topProductsData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailSalesPieChart;

