import React, { useState } from "react";
import axios from "axios";

function AddFlag() {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [name, setName] = useState("");
  const [flagType, setFlagType] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios.post(`${apiUrl}api/flags/`, {
      name: name,
      flag_type: flagType,
    });
    setName("");
    setFlagType("");
    alert("New flag is added!")
    window.location.href = `/flags-list`;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
          <div className="row mb-4 mt-4">
          <label>Name:</label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="row mb-4">
          <label>Type:</label>
            <select
              value={flagType}
              onChange={(e) => setFlagType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value="health">Health</option>
              <option value="service">Service</option>
              <option value="guest">Guest</option>
            </select>
          </div>
          <div className="row mb-4">
            <button type="submit">Add Flag</button>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </form>
  );
}

export default AddFlag;
