import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Table, Form, Button } from 'react-bootstrap';
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector } from "react-redux";

const timeOptions = [
  '12:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM',
  '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM',
  '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM',
  '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
];

const EditEmployeeWorkTime = () => {
  const { userId } = useParams();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [user, setUser] = useState(null);
  const [workTime, setWorkTime] = useState({});

  useEffect(() => {
    axios.get(`${apiUrl}api/work-time/${userId}/`)
      .then((response) => {
        setUser(response.data);
        setWorkTime(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user details!", error);
      });
  }, [userId]);

  const handleChange = (day, period, value) => {
    setWorkTime({
      ...workTime,
      [`${day}_${period}`]: value,
    });
  };

  const handleSubmit = () => {
    axios.put(`${apiUrl}/api/work-time/${userId}/`, workTime)
      .then((response) => {
        alert('Work time updated successfully!');
        window.location.href = `/work-time/${userId}/`;
      })
      .catch((error) => {
        console.error("There was an error updating the work time!", error);
      });
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Edit Employee Work Time
        </h3>
        <Table className="mt-4 col-md-5 mx-auto">
          <tbody>
            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
              <tr key={day}>
                <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                <td>
                  <Form.Control
                    as="select"
                    value={workTime[`${day}_from`] || ''}
                    onChange={(e) => handleChange(day, 'from', e.target.value)}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </Form.Control>
                </td>
                <td>
                  <Form.Control
                    as="select"
                    value={workTime[`${day}_to`] || ''}
                    onChange={(e) => handleChange(day, 'to', e.target.value)}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </Form.Control>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button onClick={handleSubmit}>Save Changes</Button>
      </div>
    </div>
  );
};

export default EditEmployeeWorkTime;
