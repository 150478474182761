import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

const PrepaidPackageForm = ({ packageData, handleClose, refreshPackages }) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    service_id: "",
    no_of_services: "",
    discount: "",
  });

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/booking-services/`)
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the services!", error);
      });

    // Set form data if packageData is provided
    if (packageData) {
      setFormData({
        service_id: packageData.service.id,
        no_of_services: packageData.no_of_services,
        discount: packageData.discount,
      });
    }
  }, [packageData, apiUrl]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (packageData) {
      // Update existing package
      axios
        .put(`${apiUrl}inventory/prepaid-packages/${packageData.id}/`, formData)
        .then((response) => {
          console.log("Prepaid package updated successfully:", response.data);
          refreshPackages();
          handleClose();
        })
        .catch((error) => {
          console.error(
            "There was an error updating the prepaid package!",
            error
          );
        });
    } else {
      // Create new package
      axios
        .post(`${apiUrl}inventory/add-prepaid-package/`, formData)
        .then((response) => {
          console.log("Prepaid package created successfully:", response.data);
          refreshPackages();
          handleClose();
        })
        .catch((error) => {
          console.error(
            "There was an error creating the prepaid package!",
            error
          );
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formService">
        <Form.Label>Service</Form.Label>
        <Form.Control
          as="select"
          name="service_id"
          value={formData.service_id}
          onChange={handleChange}
          required
        >
          <option value="">Select a service</option>
          {services.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formNoOfServices">
        <Form.Label>Number of Services</Form.Label>
        <Form.Control
          type="number"
          name="no_of_services"
          value={formData.no_of_services}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formDiscount">
        <Form.Label>Discount</Form.Label>
        <Form.Control
          type="number"
          name="discount"
          value={formData.discount}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        {packageData ? "Update" : "Create"} Prepaid Package
      </Button>
    </Form>
  );
};

export default PrepaidPackageForm;
