import React, { useEffect, useState } from "react";
import axios from "axios";
import AddNewFlag from "./AddNewFlag.js";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

function FlagsTable() {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [flags, setFlags] = useState([]);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    fetchFlags();
  }, []);

  const fetchFlags = async () => {
    const response = await axios.get(`${apiUrl}api/flags/`);
    setFlags(response.data);
  };

  //const deleteFlag = async (id) => {
  //  await axios.delete(`${apiUrl}api/flags/${id}/`);
  //  fetchFlags();
  //};

  const deleteFlag = (Id) => {
    if (window.confirm("Are you sure you want to delete this flag?")) {
      axios
        .delete(`${apiUrl}api/flags/delete/${Id}/`)
        .then(() => {
          // Refresh the list after deletion
          setFlags(flags.filter((flag) => flag.id !== Id));
        })
        .catch((error) => console.error("Error deleting flag", error));
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Flags List
        </h3>

        <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
          Add New Flag
        </Button>
        <table className="ml-2">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {flags.map((flag) => (
              <tr key={flag.id}>
                <td>{flag.name}</td>
                <td>{flag.flag_type}</td>
                <td className="text-center">
                  <button onClick={() => deleteFlag(flag.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Flag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddNewFlag />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default FlagsTable;
