import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Button } from "react-bootstrap";

const API_URL = process.env.REACT_APP_BASE_URL;

const BreedForm = () => {
  const [name, setName] = useState("");
  const [breed, setBreed] = useState("");
  const [active, setActive] = useState(true);
  const history = useNavigate();
  const { id } = useParams();
  const isEdit = Boolean(id);

  const breedOptions = ["Dog", "Cat"];

  useEffect(() => {
    if (isEdit) {
      axios
        .get(`${API_URL}api/add-breeds/${id}/`)
        .then((response) => {
          setName(response.data.name);
          setBreed(response.data.breed);
          setActive(response.data.active);
        })
        .catch((error) => console.error(error));
    }
  }, [id, isEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiCall = isEdit
      ? axios.put(`${API_URL}api/add-breeds/${id}/`, { name, breed, active })
      : axios.post(`${API_URL}api/add-breeds/`, { name, breed, active });

    apiCall
      .then((response) => (window.location.href = `/add-breed-list`))
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBreedName">
          <Form.Label>Breed:</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formActive">
          <Form.Check
            type="checkbox"
            label="Active"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default BreedForm;
