import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SalesForm = () => {

    const apiUrl = process.env.REACT_APP_BASE_URL;

    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState('');
    const [saleDate, setSaleDate] = useState('');
    const [cashSales, setCashSales] = useState(0);
    const [cardSales, setCardSales] = useState(0);
    const [transferSales, setTransferSales] = useState(0);
    const [otherSales, setOtherSales] = useState(0);
    const calculateTotalSales = () => {
        return parseFloat(cashSales) + parseFloat(cardSales) + parseFloat(transferSales) + parseFloat(otherSales);
    };
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const checkForExistingSale = async () => {
        const response = await axios.get(`${apiUrl}inventory/sales/${selectedStore}/${saleDate}`);
        return response.data.exists;
    };

    useEffect(() => {
        // Fetch stores on component mount
        axios.get(`${apiUrl}inventory/stores/`).then(response => {
            setStores(response.data);
        }).catch(error => console.error('Error fetching stores:', error));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const totalSales = calculateTotalSales();

        if (await checkForExistingSale()) {
            setMessage('Sales already exist. Please edit existing sale.');
            setIsSubmitting(false);
            setTimeout(() => setMessage(''), 3000);
            return;
        }

        const payload = {
            store: selectedStore,
            date: saleDate,
            cash_sales: cashSales,
            card_sales: cardSales,
            transfer_sales: transferSales,
            other_sales: otherSales,
            total_sales: totalSales
        };

        const resetForm = () => {
            setSelectedStore('');
            setSaleDate('');
            setCashSales(0);
            setCardSales(0);
            setTransferSales(0);
            setOtherSales(0);
        };

        try {
            await axios.post(`${apiUrl}inventory/sales/`, payload);
            alert('Sale successfully recorded!');
            resetForm();
        } catch (error) {
            console.error('Error submitting sale:', error);
            alert('Failed to record sale.');
            setIsSubmitting(false);
        }
        setTimeout(() => setMessage(''), 3000);
    };

    return (
        <div className="sales-form">
        <h2>Record Sale</h2>
        <form onSubmit={handleSubmit}>
            <div>
            <label>Store:</label>
            <select value={selectedStore} onChange={(e) => setSelectedStore(e.target.value)}>
                <option value="">Select a store</option>
                {stores.map(store => (
                <option key={store.id} value={store.id}>{store.name}</option>
                ))}
            </select>
            </div>
            <div>
            <label>Date:</label>
            <input type="date" value={saleDate} onChange={(e) => setSaleDate(e.target.value)} />
            </div>
            <div>
            <label>Cash Sales:</label>
            <input type="number" value={cashSales} onChange={(e) => setCashSales(e.target.value)} />
            </div>
            <div>
            <label>Card Sales:</label>
            <input type="number" value={cardSales} onChange={(e) => setCardSales(e.target.value)} />
            </div>
            <div>
            <label>Transfer Sales:</label>
            <input type="number" value={transferSales} onChange={(e) => setTransferSales(e.target.value)} />
            </div>
            <div>
            <label>Other Sales:</label>
            <input type="number" value={otherSales} onChange={(e) => setOtherSales(e.target.value)} />
            </div>
            <div>
            <label>Total Sales:</label>
            <input type="number" value={calculateTotalSales()} readOnly />
            </div>
            <button type="submit" disabled={isSubmitting}>Record Sale</button>
            {message && <div>{message}</div>}
        </form>
        </div>
    );
};

export default SalesForm;
