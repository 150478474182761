import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

import ClockInOut from "./../Attendance/ClockInOut.js";
import { Table, Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [appointmentDropdownOpen, setAppointmentDropdownOpen] = useState(false);
  const [reservationDropdownOpen, setReservationDropdownOpen] = useState(false);
  const [clientCenterDropdownOpen, setClientCenterDropdownOpen] =
    useState(false);
  const [inventoryDropdownOpen, setInventoryDropdownOpen] = useState(false);
  const [managerDropdownOpen, setManagerDropdownOpen] = useState(false);
  const [employeeDropdownOpen, setEmployeeDropdownOpen] = useState(false);

  const toggleAppointmentDropdown = () => {
    setAppointmentDropdownOpen(!appointmentDropdownOpen);
  };

  const toggleReservationDropdown = () => {
    setReservationDropdownOpen(!reservationDropdownOpen);
  };

  const toggleClientCenterDropdown = () => {
    setClientCenterDropdownOpen(!clientCenterDropdownOpen);
  };

  const toggleInventoryDropdown = () => {
    setInventoryDropdownOpen(!inventoryDropdownOpen);
  };

  const toggleManagerDropdown = () => {
    setManagerDropdownOpen(!managerDropdownOpen);
  };

  const toggleEmployeeDropdown = () => {
    setEmployeeDropdownOpen(!employeeDropdownOpen);
  };

  return (
    <div id="">
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <a className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div>
          <div className="sidebar-brand-text mx-3">
            <Link to={`/`} className="text-decoration-none text-white">
              Logo
            </Link>
          </div>
        </a>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link to={`/shop`} className="text-decoration-none text-white">
              Shop Detail
            </Link>
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link
              to={`/shop-bookings`}
              className="text-decoration-none text-white"
            >
              Today's Booking
            </Link>
          </a>
        </li>
        <li className="nav-item dropdown active">
          <a
            className="nav-link"
            onClick={toggleAppointmentDropdown}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Appointment{" "}
            {appointmentDropdownOpen ? (
              <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            )}
          </a>
          <div
            className={`dropdown-menu ${appointmentDropdownOpen ? "show" : ""}`}
            aria-labelledby="appointmentDropdown"
          >
            <Link to={`/booking`} className="dropdown-item">
              Appointment
            </Link>
            <Link to={`/appointment-bookings`} className="dropdown-item">
              Appointment Calendar
            </Link>
            <Link to={`/calendars-list`} className="dropdown-item">
              Calender
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown active">
          <a
            className="nav-link "
            href="#"
            role="button"
            id="reservationDropdown"
            onClick={toggleReservationDropdown}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Reservation
            {reservationDropdownOpen ? (
              <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            )}
          </a>
          <div
            className={`dropdown-menu ${reservationDropdownOpen ? "show" : ""}`}
            aria-labelledby="reservationDropdown"
          >
            <Link to={`/reservation`} className="dropdown-item">
              Reservation
            </Link>
            <Link to={`/reservation-bookings`} className="dropdown-item">
              Reservation Calendar
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown active">
          <a
            className="nav-link "
            href="#"
            role="button"
            id="clientCenterDropdown"
            onClick={toggleClientCenterDropdown}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Client Center
            {clientCenterDropdownOpen ? (
              <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            )}
          </a>
          <div
            className={`dropdown-menu ${
              clientCenterDropdownOpen ? "show" : ""
            }`}
            aria-labelledby="clientCenterDropdown"
          >
            <Link to={`/shop-clients`} className="dropdown-item">
              Client Center
            </Link>
            <Link to={`/shop-add-clients`} className="dropdown-item">
              Add New Client
            </Link>
            <Link to={`/client-leads`} className="dropdown-item">
              Client Leads
            </Link>
            <Link to={`/inactive-clients`} className="dropdown-item">
              Inactive Clients
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown active">
          <a
            className="nav-link "
            href="#"
            role="button"
            id="inventoryDropdown"
            onClick={toggleInventoryDropdown}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Inventory
            {inventoryDropdownOpen ? (
              <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            )}
          </a>
          <div
            className={`dropdown-menu ${inventoryDropdownOpen ? "show" : ""}`}
            aria-labelledby="inventoryDropdown"
          >
            <Link to={`/inventory-main`} className="dropdown-item">
              Inventory Main
            </Link>
            <Link to={`/item-categories`} className="dropdown-item">
              Item Categories
            </Link>
            <Link to={`/reorder-items`} className="dropdown-item">
              Reorder Items
            </Link>
            <Link to={`/prepaid-packages`} className="dropdown-item">
              Prepaid Packages
            </Link>
            <Link to={`/vendors`} className="dropdown-item">
              Vendors
            </Link>
            <Link to={`/services`} className="dropdown-item">
              Services
            </Link>
            <Link to={`/purchase-order`} className="dropdown-item">
              Purchase Orders
            </Link>
          </div>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link
              to={`/point-of-sale`}
              className="text-decoration-none text-white"
            >
              Point of Sale
            </Link>
          </a>
        </li>
        {userInfo && userInfo.user_role === "Manager" && (
          <li className="nav-item dropdown active">
            <a
              className="nav-link "
              href="#"
              role="button"
              id="clientCenterDropdown"
              onClick={toggleManagerDropdown}
            >
              <FontAwesomeIcon icon={faChartBar} className="mr-4" />
              Manager
              {managerDropdownOpen ? (
                <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
              )}
            </a>
            <div
              className={`dropdown-menu ${managerDropdownOpen ? "show" : ""}`}
              aria-labelledby="clientCenterDropdown"
            >
              <Link to={`/`} className="dropdown-item">
                Switch Stores
              </Link>
              <Link to={`/shops-list`} className="dropdown-item">
                Shops
              </Link>
              <Link to={`/banks`} className="dropdown-item">
                Banks
              </Link>
              <Link to={`/banks-accounts`} className="dropdown-item">
                Bank Accounts
              </Link>
              <Link to={`/cash-reconciliation`} className="dropdown-item">
                Cash Register
              </Link>
              <Link to={`/bank-reconciliation`} className="dropdown-item">
                Bank Reconciliation
              </Link>
              <Link to={`/promotions`} className="dropdown-item">
                Promotions
              </Link>
              <Link to={`/reports`} className="dropdown-item">
                Reports
              </Link>
              <Link to={`/expense-form`} className="dropdown-item">
                Expense Form
              </Link>
              <Link to={`/sales-dashboard`} className="dropdown-item">
                Sales Dashboard
              </Link>
              <Link to={`/financial-dashboard`} className="dropdown-item">
                Financial Dashboard
              </Link>
              <Link to={`/retail-dashboard`} className="dropdown-item">
                Retail Dashboard
              </Link>
            </div>
          </li>
        )}
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link
              to={`/breed-list`}
              className="text-decoration-none text-white"
            >
              Breeds List
            </Link>
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link
              to={`/add-breed-list`}
              className="text-decoration-none text-white"
            >
              Add Breeds
            </Link>
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link
              to={`/flags-list`}
              className="text-decoration-none text-white"
            >
              Flags List
            </Link>
          </a>
        </li>
        <li className="nav-item dropdown active">
          <a
            className="nav-link "
            href="#"
            role="button"
            id="employeeDropdown"
            onClick={toggleEmployeeDropdown}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Employees
            {employeeDropdownOpen ? (
              <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            )}
          </a>
          <div
            className={`dropdown-menu ${employeeDropdownOpen ? "show" : ""}`}
            aria-labelledby="employeeDropdown"
          >
            {userInfo && userInfo.user_role === "Manager" && (
              <Link to={`/employee-payroll`} className="dropdown-item">
                Employee Payroll
              </Link>
            )}
            <Link to={`/employee-center`} className="dropdown-item">
              Employee center
            </Link>
            <Link to={`/employees`} className="dropdown-item">
              Add Employee
            </Link>
            <Link to={`/my-profile`} className="dropdown-item">
              My Profile
            </Link>
            <Link onClick={handleOpenModal} className="dropdown-item">
              Clock In/Out
            </Link>
          </div>
        </li>
        <li className="nav-item active">
          <a className="nav-link">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            <Link to={`/message-screen`} className="text-decoration-none text-white">
              Messaging
            </Link>
          </a>
        </li>
      </ul>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Employ Time Clock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClockInOut />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
