
  const initialState = {
    selectedItems: JSON.parse(localStorage.getItem('cartItems')) || [],
  };
  
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_TO_CART':
        const existingItem = state.selectedItems.find(item => item.uniqueId === action.payload.uniqueId);
        if (existingItem) {
          return {
            ...state,
          selectedItems: state.selectedItems.map(item =>
            item.uniqueId === action.payload.uniqueId
              ? { ...item, quantity: item.quantity + action.payload.quantity }
              : item
          ),
          };
        } else {
          return {
            ...state,
          selectedItems: [...state.selectedItems, { ...action.payload, discount: action.payload.discount || 0 }],
        };
        }
      case 'REMOVE_FROM_CART':
        return {
          ...state,
          selectedItems: state.selectedItems.filter(item => item.uniqueId !== action.payload.uniqueId),
        };
        case 'ADD_MULTIPLE_TO_CART':
          let updatedItems = [...state.selectedItems];
          action.payload.forEach(newItem => {
            const existingItem = updatedItems.find(item => item.uniqueId === newItem.uniqueId);
            if (existingItem) {
              updatedItems = updatedItems.map(item =>
                item.uniqueId === newItem.uniqueId ? { ...item, quantity: item.quantity + newItem.quantity } : item
              );
            } else {
              updatedItems.push({ ...newItem, quantity: newItem.quantity, discount: 0 });
            }
          });
          return {
            ...state,
            selectedItems: updatedItems,
          };
          // Add this case inside the switch statement of your cartReducer
          case 'CLEAR_CART':
            return {
              ...state,
              selectedItems: [],
            };

        
      default:
        return state;
    }
  };
  
  export default cartReducer;
  