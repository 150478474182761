import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";

const FirstTimeClients = () => {
  const { shopName } = useParams();
  const { id } = useParams();
  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="container-fluid">
      
    </div>
  );
};

export default FirstTimeClients;
