import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import { Container, Modal, Button } from "react-bootstrap";
import "./InventoryForm.css";

import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../actions/cartActions';


const InventoryForm = ({ itemId, setEditing }) => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId
  const shopName = shop.shopName
  
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();

  const handleAddToCart = () => {

    if (inventoryItem.quantityStock === 0) {
      alert('This item is out of stock and cannot be added to the cart.');
      return;
    }

    const itemToDispatch = {
      uniqueId: `product-${inventoryItem.id}`,
      id: itemId,
      productName: inventoryItem.productName,
      price: parseFloat(inventoryItem.price),
      quantity: 1, // Starting quantity
      imageUrl: inventoryItem.imageUrl,
      margin: inventoryItem.margin
    };
  
    dispatch(addToCart(itemToDispatch));
    window.location.href = `/point-of-sale`;
  };
  

  const [inventoryItem, setInventoryItem] = useState({
    brandName: "",
    category: "",
    expense_category: "",
    //gender: "",
    price: "",
    productName: "",
    description: "",
    visibility: "hidden", // default value
    publishDate: "",
    parentCategory: "",
    image: null,
    imageUrl: "",
    quantityStock: 0,
    costPerHundredGrams: "",
    costPerPiece: "",
    reorderPoint: 0,
    sku: "",
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedType, setSelectedType] = useState('Product');

  const [vendorSearch, setVendorSearch] = useState("");
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [pricingMethod, setPricingMethod] = useState("piece");
  const [margin, setMargin] = useState("");
  const calculateCost = () => {
    if (pricingMethod === "piece") {
      return inventoryItem.costPerPiece;
    } else if (pricingMethod === "weight") {
      return inventoryItem.costPerHundredGrams;
    }
    return 0;
  };

  useEffect(() => {
    const cost = calculateCost();
    const price = inventoryItem.price;
    if (price && cost) {
      const marginValue = ((price - cost) / price) * 100;
      setMargin(marginValue.toFixed(2)); // Rounds to two decimal places
    } else {
      setMargin("");
    }
  }, [
    inventoryItem.price,
    inventoryItem.costPerPiece,
    inventoryItem.costPerHundredGrams,
    pricingMethod,
  ]);

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    console.log("Selected type:", newType);
    setSelectedType(newType);
  };
  
useEffect(() => {
  const fetchCategories = async () => {
    if (selectedVendor) {
      const response = await axios.get(`${apiUrl}inventory/expenses/categories/?vendor_id=${selectedVendor.id}`);
      setCategories(response.data);
      
      // Optionally, immediately fetch sub-categories for the first category
      if (response.data.length > 0) {
        fetchSubCategories(response.data[0].id);
      }
    }
  };

  fetchCategories();
}, [selectedVendor]);

// Function to fetch sub-categories for a given category ID
const fetchSubCategories = async (categoryId) => {
  if (selectedVendor && categoryId) {
    const response = await axios.get(`${apiUrl}inventory/expenses/subcategories/?vendor_id=${selectedVendor.id}&category_id=${categoryId}`);
    setSubCategories(response.data);
  }
};


  useEffect(() => {
    if (vendorSearch.length >= 3) {
      const fetchVendors = async () => {
        const response = await axios.get(`${apiUrl}inventory/vendor/search/?query=${vendorSearch}`);
        setVendors(response.data);
      };
      fetchVendors();
    } else if (vendorSearch.length === 0) {
      // Clear vendors if search term is cleared
      setVendors([]);
    }
  }, [vendorSearch]);

  const handleSelectVendor = (vendor) => {
    setSelectedVendor(vendor);
    setVendorSearch("");
    setVendors([]);
    setCategories([]);
    setSubCategories([]);
  };

  const handleVendorSearchChange = debounce((searchTerm) => {
    setVendorSearch(searchTerm);
  }, 100);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "category") {
      setSelectedCategoryId(value);
      setInventoryItem({ ...inventoryItem, category: value });
      // No need to manually reset subcategories here as useEffect will take care of it
    } else if (name === "subCategory") {
      setSelectedSubCategoryId(value);
      setInventoryItem({ ...inventoryItem, subCategory: value });
    } else {
      setInventoryItem({ ...inventoryItem, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log("Submitting form");

    // Append inventory item data to the formData object
    Object.keys(inventoryItem).forEach((key) => {
      if (key !== "image") {
        formData.append(key, inventoryItem[key]);
      }
    });

    if (inventoryItem.image) {
      formData.append("image", inventoryItem.image);
    }

    if (selectedVendor) {
      formData.append("vendor_id", selectedVendor.id);
    }

    if (pricingMethod === "piece") {
      formData.append("cost_per_piece", inventoryItem.costPerPiece);
      formData.append("cost_per_hundred_grams", ""); // Clear the other field
    } else if (pricingMethod === "weight") {
      formData.append(
        "cost_per_hundred_grams",
        inventoryItem.costPerHundredGrams
      );
      formData.append("cost_per_piece", ""); // Clear the other field
    }

    if (selectedCategoryId) {
      formData.append("expense_category", selectedCategoryId);
    }

    if (selectedSubCategoryId) {
      formData.append("expense_subcategory", selectedSubCategoryId);
    }

    if (inventoryItem.reorderPoint) {
      formData.append("reorder_point", inventoryItem.reorderPoint);
    }

    formData.append('store', shopId);

    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const url = itemId
        ? `${apiUrl}inventory/inventory/${itemId}/`
        : `${apiUrl}inventory/inventory/`;
      const method = itemId ? "put" : "post";

      console.log("formData : ", formData)
      await axios({ method, url, data: formData, headers: config.headers });
      //setEditing(false); // If you are using a modal or similar to edit
      if (method === "post") {
        alert("New item is created!");
      } else if (method === "put") {
        alert("Item is updated!");
      }
      window.location.href = `/inventory-main`;
    } catch (error) {
      console.error("Error submitting the form", error);
      // Handle error - display error message
      alert("Error in creating new item.");
    }
  };
  
  useEffect(() => {
    const fetchItemDetails = async () => {
      if (itemId) {
        try {
          const response = await axios.get(
            `${apiUrl}inventory/inventory/${itemId}/`
          );
          console.log("itemDetails :", response.data);
          // Determine the pricing method based on the item details
          const method = response.data.cost_per_piece ? "piece" : 
          response.data.cost_per_hundred_grams ? "weight" : 
          "";

          setPricingMethod(method);

          setInventoryItem({
            ...response.data,
            imageUrl: response.data.image || "",
            image: null,
            category: response.data.expense_category,
            subCategory: response.data.expense_subcategory,
            costPerPiece: response.data.cost_per_piece,
            reorderPoint: response.data.reorder_point,
            costPerHundredGrams: response.data.cost_per_hundred_grams,
          });

          setSelectedVendor(response.data.vendor);
          setSelectedCategoryId(response.data.expense_category);
          setSelectedSubCategoryId(response.data.expense_subcategory);

          console.log("itemId : ", itemId)

          // Set other related states as necessary
        } catch (error) {
          console.error("Failed to fetch item details", error);
        }
      } else {
        console.log("Adding new item");
      }
    };

    fetchItemDetails();
  }, []);
  

  // For displaying the image or a preview of the uploaded image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setInventoryItem({ ...inventoryItem, image: file, imageUrl: imageUrl });
    }
  };

  return (
    <div className="">
      <Container>
        <form onSubmit={handleSubmit}>
          <div className="inventory-form">
            {/*}
            <h2>{itemId ? "Edit" : "Add"} Inventory Item</h2>
            */}

            <div className="row mb-3 mt-4">
              <div className="col-md-6">
                <label htmlFor="vendorSearch" className="mr-2">
                  Vendor Name
                </label>
                <input
                  type="text"
                  name="vendorSearch"
                  id="vendorSearch"
                  value={vendorSearch}
                  onChange={(e) => handleVendorSearchChange(e.target.value)}
                  autoComplete="off"
                  placeholder="Search Vendor"
                />
                <div className="vendor-suggestions">
                  {vendors.map((vendor) => (
                    <div
                      key={vendor.id}
                      onClick={() => handleSelectVendor(vendor)}
                      className="suggestion-item"
                      style={{cursor:"pointer"}}
                    >
                      {vendor.business_name}
                    </div>
                  ))}
                </div>
                {selectedVendor && (
                  <div className="selected-vendor">
                    Selected Vendor: {selectedVendor.business_name}
                    <input
                      type="hidden"
                      name="vendor"
                      value={selectedVendor.id}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="brandName" className="mr-2">
                  Brand Name
                </label>
                <input
                  type="text"
                  name="brandName"
                  id="brandName"
                  value={inventoryItem.brandName}
                  onChange={handleInputChange}
                  required
                />
                </div>
              <div className="col-md-6">
                <label htmlFor="productName" className="mr-2">
                  Item Name
                </label>
                <input
                  type="text"
                  name="productName"
                  id="productName"
                  value={inventoryItem.productName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="category" className="mr-2">
                  Category
                </label>
                <select
                  name="category"
                  id="category"
                  value={inventoryItem.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select a Category</option>{" "}
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

               <label htmlFor="subCategory">Sub Category</label>
              <select
                name="subCategory"
                id="subCategory"
                value={inventoryItem.subCategory}
                onChange={handleInputChange}
                //required
                disabled={!selectedCategoryId} // Disable if no category is selected
              >
                <option value="">Select a SubCategory</option>
                {subCategories.map(subCategory => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
              </select>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="pricingMethod">Pricing Method</label>
                <div className="pricing-method-options">
                  <label className="mr-2">Price per Piece</label>
                  <input
                    className="mr-4"
                    type="radio"
                    name="pricingMethod"
                    value="piece"
                    checked={pricingMethod === "piece"}
                    onChange={() => setPricingMethod("piece")}
                  />

                  <label className="mr-2">Price per 100g</label>
                  <input
                    type="radio"
                    name="pricingMethod"
                    value="weight"
                    checked={pricingMethod === "weight"}
                    onChange={() => setPricingMethod("weight")}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                {pricingMethod === "piece" && (
                  <div>
                    <label htmlFor="costPerPiece">Cost per Piece</label>
                    <input
                      type="number"
                      name="costPerPiece"
                      id="costPerPiece"
                      value={inventoryItem.costPerPiece}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {pricingMethod === "weight" && (
                  <div>
                    <label htmlFor="costPerHundredGrams">Cost per 100g</label>
                    <input
                      type="number"
                      name="costPerHundredGrams"
                      id="costPerHundredGrams"
                      value={inventoryItem.costPerHundredGrams}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="price">Price of Sale</label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  value={inventoryItem.price}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <div className="margin-display">
                  <label>Margin:</label>
                  <span>{margin ? `${margin}%` : ""}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="sku" className="mr-2">
                  SKU
                </label>
                <input
                  type="text"
                  name="sku"
                  id="sku"
                  value={inventoryItem.sku}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="row mb-3">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="description"
                value={inventoryItem.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          <div className="inventory-side-panel">
            <label htmlFor="quantityStock">Quantity in Stock</label>
            <input
              type="number"
              name="quantityStock"
              id="quantityStock"
              value={inventoryItem.quantityStock}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="reorderPoint">Reorder Point</label>
            <input
              type="number"
              name="reorderPoint"
              id="reorderPoint"
              value={inventoryItem.reorderPoint}
              onChange={handleInputChange}
              required
            />

            <div className="inventory-item-image">
              {inventoryItem.imageUrl && (
                <img
                  src={inventoryItem.imageUrl}
                  alt="Inventory Item"
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </div>

            <label htmlFor="image" className="mr-2">
              Product Image
            </label>
            <input
              type="file"
              name="image"
              id="image"
              onChange={handleImageChange}
            />

            <button type="submit" className="mr-2">
              {itemId ? "Save Changes" : "Add Item"}
            </button>
            {itemId && (
              <button type="button" className="cart-btn" onClick={handleAddToCart}>
                Add To Cart
              </button>
            )}
          </div>
        </form>
      </Container>
    </div>
  );
};

export default InventoryForm;