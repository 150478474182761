import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";
import ExpenseDetail from "./ExpenseReportComponent/ExpenseDetail";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const ExpenseReport = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);
  const [expenseDetail, setExpenseDetail] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const reportRef = useRef();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
        console.log(">>>>>>>", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {};
    if (startDate) data.start_date = startDate;
    if (endDate) data.end_date = endDate;
    if (selectedUser && selectedUser !== "all") data.shop = selectedUser;

    if (Object.keys(data).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}inventory/expense-report/`, data);
      setResult(response.data.expenses);
      console.log("sales response : ", response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleOpenModal = (expense) => {
    setExpenseDetail({ details: expense.details || expense.expenses });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Total Expenses", key: "total_amount" },
  ];

  const isGroupedByDay = result && result.length > 0 && !!result[0].date;

  // Function to format currency in Mexican Pesos
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(amount);
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
    {result && (
      <div>
      <div className="d-flex justify-content-end mb-3">
        <CSVLink
          data={result}
          headers={headers}
          filename={"expense-report.csv"}
          className="btn btn-primary me-2"
        >
          Export to CSV
        </CSVLink>
        <button className="btn btn-secondary" onClick={handlePrint}>
          Print
        </button>
      </div>
      <div ref={reportRef}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{isGroupedByDay ? "Date" : "Date Range"}</th>
              <th>Total Expenses</th>
            </tr>
          </thead>
          <tbody>
            {result.map((record, index) => (
              <tr key={index} onClick={() => handleOpenModal(record, !!record.week_range)} style={{ cursor: "pointer" }}>
                <td>{record.formatted_date || record.week_range}</td>
                <td>{formatCurrency(record.total_amount)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Expense Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {expenseDetail ? <ExpenseDetail expenseDetail={expenseDetail} /> : <div>No data available</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
    )}
  </div>
  );
};

export default ExpenseReport;

