import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Table } from "react-bootstrap";
import CalendarForm from "./CalendarForm";

import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { useSelector, useDispatch } from "react-redux";

const API_URL = process.env.REACT_APP_BASE_URL;

const CalendarList = () => {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCalendar(null);
  };

  useEffect(() => {
    loadCalendars();
  }, []);

  const loadCalendars = () => {
    axios.get(`${API_URL}api/calendars-list/`).then((response) => {
      setCalendars(response.data);
    });
  };

  const handleEdit = (calendar) => {
    setSelectedCalendar(calendar);
    handleOpenModal();
  };

  const handleDelete = (id) => {
    if (
      window.confirm("Are you sure you want to delete this calendar entry?")
    ) {
      axios
        .delete(`${API_URL}api/calendars/${id}/`)
        .then(() => loadCalendars());
    }
  };

  const handleSave = () => {
    setSelectedCalendar(null);
    loadCalendars();
    handleCloseModal();
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Calendars
        </h3>
        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4">
            Add Calendar Entry
          </Button>
        </div>
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Title</th>
                <th>Color</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {calendars.map((calendar) => (
                <tr key={calendar.id}>
                  <td>{calendar.user_first_name}</td>
                  <td>{calendar.title}</td>
                  <td>{calendar.color}</td>
                  <td>
                    <Button variant="info" onClick={() => handleEdit(calendar)}>
                      Edit
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(calendar.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedCalendar ? "Edit Calendar Entry" : "Add Calendar Entry"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CalendarForm
              selectedCalendar={selectedCalendar}
              onSave={handleSave}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CalendarList;
