import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import {
  Form,
  Button,
  Col,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const EmployeeEdit = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const timezones = moment.tz.names();

  const { userId } = useParams();
  const [user, setUser] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    employee_info: {
      user_role: "",
      status: "",
      service_level: 0,
      efficiency: "",
      calendar_assignment: "",
      run_assignment: "",
      phone: "",
      cell: "",
      address: "",
      city: "",
      postal_code: "",
      shown_on_staff_page: false,
      bio: "",
      notes: "",
      time_zone: "",
      date_of_hire: "",
    },
  });

  useEffect(() => {
    axios
      .get(`${apiUrl}api/employee/${userId}/`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user details!", error);
      });
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePhoneChange = (value, name) => {
    setUser((prevState) => ({
      ...prevState,
      employee_info: {
        ...prevState.employee_info,
        [name]: value,
      },
    }));
  };

  const handleEmployeeInfoChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setUser((prevState) => ({
      ...prevState,
      employee_info: {
        ...prevState.employee_info,
        [name]: newValue,
      },
    }));

    if (name === "postal_code" && value.trim().length > 0) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: value,
              components: "country:MX",
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );

          setUser((prevState) => ({
            ...prevState,
            employee_info: {
              ...prevState.employee_info,
              city: cityComponent ? cityComponent.long_name : "",
              postal_code: newValue,
            },
          }));
        }
      } catch (error) {
        console.error(
          "Error fetching data from Google Maps Geocoding API",
          error
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${apiUrl}api/employee/update/${userId}/`, user)
      .then((response) => {
        alert("Employee updated successfully.");
        window.location.href = `/employee-center`;
      })
      .catch((error) => {
        console.error("There was an error updating the user!", error);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="service-level-tooltip" {...props}>
      <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
        <li>
          <strong>Level 0:</strong> Person in training
        </li>
        <li>
          <strong>Level 1:</strong> Bather
        </li>
        <li>
          <strong>Level 2:</strong> Groomer with experience
        </li>
        <li>
          <strong>Level 3:</strong> Senior groomer (knows Asian grooming and
          stripping)
        </li>
      </ul>
    </Tooltip>
  );

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Edit Employee
        </h3>
        <Form onSubmit={handleSubmit} className="mt-4">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Username
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="username"
                value={user.username}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Password
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
                placeholder="Password"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Email
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              First Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="first_name"
                value={user.first_name}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Last Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="last_name"
                value={user.last_name}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              User Role
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="user_role"
                value={user.employee_info.user_role}
                onChange={handleEmployeeInfoChange}
              >
                <option value="">Select Role</option>
                <option value="Manager">Manager</option>
                <option value="Assistant Manager">Assistant Manager</option>
                <option value="Groomer">Groomer</option>
                <option value="Daycare">Daycare</option>
                <option value="Reception">Reception</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Status
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="status"
                value={user.employee_info.status}
                onChange={handleEmployeeInfoChange}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Suspended">Suspended</option>
                <option value="Terminated">Terminated</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Service Level{" "}
              <OverlayTrigger placement="right" overlay={renderTooltip}>
                <span style={{ cursor: "pointer", color: "blue" }}>?</span>
              </OverlayTrigger>
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="service_level"
                value={user.employee_info.service_level}
                onChange={handleEmployeeInfoChange}
                required
              >
                <option value="">Select Service Level</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Efficiency
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="efficiency"
                value={user.employee_info.efficiency}
                onChange={handleEmployeeInfoChange}
              />
            </Col>
          </Form.Group>

          {/*}  <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Calendar Assignment
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="calendar_assignment"
                value={user.employee_info.calendar_assignment}
                onChange={handleEmployeeInfoChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Run Assignment
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="run_assignment"
                value={user.employee_info.run_assignment}
                onChange={handleEmployeeInfoChange}
              />
            </Col>
          </Form.Group>*/}

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Phone
            </Form.Label>
            <Col sm={10}>
              <PhoneInput
                international
                defaultCountry="MX"
                value={user.employee_info.phone}
                onChange={(value) => handlePhoneChange(value, "phone")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Cell
            </Form.Label>
            <Col sm={10}>
              <PhoneInput
                international
                defaultCountry="MX"
                value={user.employee_info.cell}
                onChange={(value) => handlePhoneChange(value, "cell")}
              />
            </Col>
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={user.employee_info.address}
                onChange={handleEmployeeInfoChange}
                placeholder="Enter address"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={user.employee_info.city}
                onChange={handleEmployeeInfoChange}
                placeholder="Enter city"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formPostalCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="postal_code"
                value={user.employee_info.postal_code}
                onChange={handleEmployeeInfoChange}
                placeholder="Enter postal code"
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formShownOnStaffPage">
              <Form.Check
                type="checkbox"
                name="shown_on_staff_page"
                label="Shown on Staff Page"
                checked={user.employee_info.shown_on_staff_page}
                onChange={(e) =>
                  handleEmployeeInfoChange({
                    target: {
                      name: "shown_on_staff_page",
                      value: e.target.checked,
                    },
                  })
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBio">
              <Form.Label>Bio</Form.Label>
              <Form.Control
                type="text"
                name="bio"
                value={user.employee_info.bio}
                onChange={handleEmployeeInfoChange}
                placeholder="Enter bio"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                value={user.employee_info.notes}
                onChange={handleEmployeeInfoChange}
                placeholder="Enter notes"
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formTimeZone">
              <Form.Label>Time Zone</Form.Label>
              <Form.Control
                as="select"
                name="time_zone"
                value={user.employee_info.time_zone}
                onChange={handleEmployeeInfoChange}
                placeholder="Enter time zone"
              >
                {timezones.map((timezone) => (
                  <option key={timezone} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formDateOfHire">
              <Form.Label>Date of Hire</Form.Label>
              <Form.Control
                type="date"
                name="date_of_hire"
                value={user.employee_info.date_of_hire}
                onChange={handleEmployeeInfoChange}
              />
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default EmployeeEdit;
