import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import './NewClientsModal.css'; // Importing the CSS file for styling
import 'react-phone-number-input/style.css';

const NewClientsModal = ({ onClose }) => {
    const [productCategories, setProductCategories] = useState([]);
    const services = ["Veterinary", "Day Care", "Bath", "Grooming"];
    const preferred_contact = ["Email", "Telephone", "SMS", "WhatsApp"]

    const [clientData, setClientData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        address: '',
        postalCode: '',
        productsInterest: [],
        serviceInterest: [],
        acceptsMarketing: false,
        preferredContact: '',
        notes: ''
    });

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;
        if (checked) {
            setClientData({ ...clientData, [name]: [...clientData[name], value] });
        } else {
            setClientData({ ...clientData, [name]: clientData[name].filter((item) => item !== value) });
        }
    };

    useEffect(() => {
        // Fetch product categories
        axios.get('/expenses/categories/?client_form=true').then(response => {
            setProductCategories(response.data);
        });
    }, []);

    const handlePhoneChange = (phone) => {
        setClientData({ ...clientData, telephone: phone });
    };

    const handleSubmit = async () => {
        try {
            const formattedData = {
                first_name: clientData.firstName,
                last_name: clientData.lastName,
                email: clientData.email,
                phone: clientData.telephone,
                address: clientData.address,
                postal_code: clientData.postalCode,
                products_of_interest: clientData.productsInterest,
                services_preferred: clientData.serviceInterest,
                accepts_marketing: clientData.acceptsMarketing,
                preferred_contact: clientData.preferredContact,
                notes: clientData.notes,
                client_since: moment().format('YYYY-MM-DD'),
            };
            await axios.post('/clients/', formattedData);
            onClose(); // Close modal after submission
        } catch (error) {
            console.error('Error saving client', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({ ...clientData, [name]: value });
    };

    return (
        <div className="new-client-modal-overlay">
            <div className="new-client-modal">
                <h1 className="text-2xl font-semibold mb-8">New Client Information</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    {/* First Name */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                        First Name
                        </label>
                        <input
                            className="input-field"
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder="John"
                            value={clientData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Last Name */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                        Last Name
                        </label>
                        <input
                            className="input-field"
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder="Doe"
                            value={clientData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Email */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                        </label>
                        <input
                            className="input-field"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="john@email.com"
                            value={clientData.email}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Telephone */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="telephone">
                            Telephone   
                        </label>
                        <PhoneInput
                            international
                            defaultCountry="MX"
                            value={clientData.telephone}
                            onChange={handlePhoneChange}
                        />
                    </div>
                    {/* Address */}
                    <div className="md:col-span-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                            Address
                        </label>
                        <input
                            className="input-field"
                            id="address"
                            name="address"
                            type="text"
                            placeholder="Mailing address"
                            value={clientData.address}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Postal Code */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="postalCode">
                        Postal Code
                        </label>
                        <input
                            className="input-field"
                            id="postalCode"
                            name="postalCode"
                            type="text"
                            placeholder="10200"
                            value={clientData.postalCode}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Products of Interest */}
                    <div className="md:col-span-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productsInterest">
                            Products of Interest
                        </label>
                        <div className="checkbox-container">
                            {productCategories.map((category, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        id={`product-${category.id}`}
                                        name="productsInterest"
                                        value={category.id}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={`product-${category.id}`}>{category.name}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Service of Interest */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serviceInterest">
                        Service of Interest
                        </label>
                        <div className="checkbox-container">
                            {services.map((service, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        id={`service-${index}`}
                                        name="serviceInterest"
                                        value={service}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={`service-${index}`}>{service}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Accepts Marketing */}
                    <div className="md:col-span-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="acceptsMarketing">
                        Accepts Marketing
                        </label>
                        <input
                            type="checkbox"
                            id="acceptsMarketing"
                            name="acceptsMarketing"
                            checked={clientData.acceptsMarketing}
                            onChange={(e) => setClientData({ ...clientData, acceptsMarketing: e.target.checked })}
                        />
                    </div>
                    {/* Preferred method of contact */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preferredContact">
                        Preferred method of contact
                        </label>
                        <div className="checkbox-container">
                            {preferred_contact.map((preferred_contact, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        id={`preferred_contact-${index}`}
                                        name="preferredContact"
                                        value={preferred_contact}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={`service-${index}`}>{preferred_contact}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Notes */}
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="notes">
                        Notes (Private)
                        </label>
                        <textarea
                            id="notes"
                            name="notes"
                            value={clientData.notes}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                </div>
                <button onClick={handleSubmit}>Save Client</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default NewClientsModal;
