import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";

//import Sidebar from "../Design/ClientCenterSidebar.js";
//import Sidebar from "../Design/ShopDetailsSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import ClientSalesHistory from "../Sales/ClientSalesModal.js";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const ShopClients = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for search term
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(`${apiUrl}api/clients/${id}/`);
        const data = await response.json();
        setBookings(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Filter function based on search term
  const filteredBookings = bookings.filter((booking) => {
    const fullName = `${booking.FirstName} ${booking.LastName}`.toLowerCase();
    const petNames = booking.pets
      ? booking.pets.map((pet) => pet.PetName.toLowerCase()).join(", ")
      : "";
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      petNames.includes(searchTerm.toLowerCase())
    );
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Active Client List
        </h3>
        <Link to="/shop-add-clients">
          <button className="mb-4 mt-4">Add New Client</button>
        </Link>
        {/* Search input */}
        <input
          type="text"
          placeholder="Search by client or pet name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control mb-4"
        />
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Pet</th>
                <th>Preferred Contact</th>
                <th>Address</th>
                <th>Transaction</th>
              </tr>
            </thead>
            <tbody>
            {filteredBookings.map((booking, index) => (
                <tr
                  key={index}
                  style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}
                >
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/client-profile/${booking.id}`}>
                      {booking.FirstName ? `${booking.FirstName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/client-profile/${booking.id}`}>
                      {booking.LastName ? `${booking.LastName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.pets && booking.pets.length > 0
                      ? booking.pets.map((pet, index) => (
                          <span key={index}>
                            <Link to={`/pet-profile/${pet.id}`}>
                              {pet.PetName}
                            </Link>
                            {index < booking.pets.length - 1 && ", "}
                          </span>
                        ))
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.contacts && booking.contacts.length > 0
                      ? booking.contacts
                          .map((contact) => contact.contact_type)
                          .join(", ")
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.Address ? booking.Address : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="primary"
                      onClick={() => handleOpenModal(booking.id)}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientSalesHistory clientId={currentItemId} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShopClients;
