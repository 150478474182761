// ClientForm.js

import React, { useState } from 'react';
import axios from 'axios';

const ClientForm = () => {
  const [clientData, setClientData] = useState({
    Prefix: '',
    FirstName: '',
    LastName: '',
    ServicesCateGory: '',
    Address: '',
    AddressTwo: '',
    City: '',
    StateCountry: '',
    PostalCode: '',
    Area: '',
    LocalTaxRate: '',
    ClientReferralSource: '',
    ClientSince: '',
    SpecialNotes: '',
    contacts: [
      { ContactType: '', ContactNumber: '', ContactBy: '', Primary: '' },
    ],
  });

  const handleClientChange = (field, value) => {
    setClientData({ ...clientData, [field]: value });
  };
  

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index][field] = value;
    setClientData({ ...clientData, contacts: updatedContacts });
  };

  const addContact = () => {
    setClientData({
      ...clientData,
      contacts: [...clientData.contacts, { ContactType: '', ContactNumber: '', ContactBy: '', Primary: '' }],
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/clients1/', clientData);
      // Handle success
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Server responded with an error:', error.response.data);
        console.log('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('No response received from the server.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error during request setup:', error.message);
      }
    }
  };
  
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <input type="text" placeholder="Prefix" value={clientData.Prefix} onChange={(e) => handleClientChange('Prefix', e.target.value)} />
      <input type="text" placeholder="First Name" value={clientData.FirstName} onChange={(e) => handleClientChange('FirstName', e.target.value)} />
      <input type="text" placeholder="Last Name" value={clientData.LastName} onChange={(e) => handleClientChange('LastName', e.target.value)} />
      <input type="text" placeholder="Services Category" value={clientData.ServicesCateGory} onChange={(e) => handleClientChange('ServicesCateGory', e.target.value)} />
      <input type="text" placeholder="Address" value={clientData.Address} onChange={(e) => handleClientChange('Address', e.target.value)} />
      <input type="text" placeholder="Address Two" value={clientData.AddressTwo} onChange={(e) => handleClientChange('AddressTwo', e.target.value)} />
      <input type="text" placeholder="City" value={clientData.City} onChange={(e) => handleClientChange('City', e.target.value)} />
      <input type="text" placeholder="State/Country" value={clientData.StateCountry} onChange={(e) => handleClientChange('StateCountry', e.target.value)} />
      <input type="text" placeholder="Postal Code" value={clientData.PostalCode} onChange={(e) => handleClientChange('PostalCode', e.target.value)} />
      <input type="text" placeholder="Area" value={clientData.Area} onChange={(e) => handleClientChange('Area', e.target.value)} />
      <input type="text" placeholder="Local Tax Rate" value={clientData.LocalTaxRate} onChange={(e) => handleClientChange('LocalTaxRate', e.target.value)} />
      <input type="text" placeholder="Client Referral Source" value={clientData.ClientReffralSource} onChange={(e) => handleClientChange('ClientReffralSource', e.target.value)} />
      <input type="datetime-local" placeholder="Client Since" value={clientData.ClientSince} onChange={(e) => handleClientChange('ClientSince', e.target.value)} />
      <input type="text" placeholder="Special Notes" value={clientData.SpecialNotes} onChange={(e) => handleClientChange('SpecialNotes', e.target.value)} />

      {clientData.contacts.map((contact, index) => (
        <div key={index}>
          <input type="text" placeholder="Contact Type" value={contact.ContactType} onChange={(e) => handleContactChange(index, 'ContactType', e.target.value)} />
          <input type="text" placeholder="Contact Number" value={contact.ContactNumber} onChange={(e) => handleContactChange(index, 'ContactNumber', e.target.value)} />
          <input type="text" placeholder="Contact By" value={contact.ContactBy} onChange={(e) => handleContactChange(index, 'ContactBy', e.target.value)} />
          <input type="text" placeholder="Primary" value={contact.Primary} onChange={(e) => handleContactChange(index, 'Primary', e.target.value)} />
        </div>
      ))}
      <button onClick={addContact}>Add Contact</button>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default ClientForm;
