import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Form, Button } from "react-bootstrap";
import GaugeChart from "react-gauge-chart";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

function Cards() {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Calculate the first and last dates of the current month
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    .toISOString()
    .split("T")[0];
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    .toISOString()
    .split("T")[0];

  const [filters, setFilters] = useState({
    startDate: firstDayOfMonth,
    endDate: lastDayOfMonth,
    shop: "",
  });
  const [shops, setShops] = useState([]);
  const [data, setData] = useState({
    gross_profit_margin: null,
    net_profit_margin: null,
    opex_ratio: null,
    operating_profit_margin: null,
    break_even_data: { revenue: [], costs: [], labels: [] },
    burn_rate_trends: { labels: [], data: [] },
    runway_trends: { labels: [], data: [] },
  });
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  // Fetch shop data for dropdown
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiUrl, userInfo.token]);

  const fetchData = () => {
    axios
      .get(`${apiUrl}inventory/financial-cards/`, {
        params: {
          start_date: filters.startDate,
          end_date: filters.endDate,
          shop: selectedUser,
        },
      })
      .then((response) => {
        setData(response.data.sale_data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching financial data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // Chart Data
  const breakEvenChartData = {
    labels: data.break_even_data.labels,
    datasets: [
      {
        label: "Costs",
        data: data.break_even_data.costs,
        backgroundColor: "rgba(255, 0, 0, 0.6)", // Red
        borderColor: "rgba(255, 0, 0, 1)",
        borderWidth: 1,
      },
      {
        label: "Revenue",
        data: data.break_even_data.revenue.map((revenue, index) =>
          revenue > data.break_even_data.costs[index] ? revenue - data.break_even_data.costs[index] : 0
        ),
        backgroundColor: "rgba(0, 128, 0, 0.6)", // Green
        borderColor: "rgba(0, 128, 0, 1)",
        borderWidth: 1,
      },
    ],
  };

  const breakEvenChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Break-even Analysis",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `$${value.toFixed(2)}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true, // Stack bars horizontally
        title: {
          display: true,
          text: "Date Range",
        },
      },
      y: {
        stacked: true, // Stack bars horizontally
        title: {
          display: true,
          text: "Amount ($)",
        },
        beginAtZero: true,
      },
    },
  };

  // Avoid rendering if data is not yet available
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Filters */}
      <div className="d-flex mb-4">
        <Form.Group className="me-3">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            name="startDate"
            value={filters.startDate}
            onChange={handleFilterChange}
          />
        </Form.Group>
        <Form.Group className="me-3">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            name="endDate"
            value={filters.endDate}
            onChange={handleFilterChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Shop</Form.Label>
          <Form.Select name="shop" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value || null)}>
            <option value="">All Shops</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Button variant="primary" onClick={fetchData} className="ms-3 align-self-end">
          Apply Filters
        </Button>
      </div>
      
      {/* Speedometers for KPIs */}
      <div className="d-flex flex-wrap mb-4">
        {[
          { label: "Gross Profit Margin", value: data.gross_profit_margin },
          { label: "Opex Ratio", value: data.opex_ratio },
          { label: "Net Profit Margin", value: data.net_profit_margin },
          { label: "Operating Profit Margin", value: data.operating_profit_margin },
        ].map((metric, index) => (
          <Card key={index} style={{ width: "18rem", margin: "1rem" }}>
            <Card.Body>
              <GaugeChart
                id={`metric-${index}`}
                nrOfLevels={20}
                percent={metric.value != null ? metric.value / 100 : 0}
                colors={["#FF0000", "#FFFF00", "#00FF00"]}
                textColor="#000000"
              />
              <Card.Title>{metric.label}</Card.Title>
            </Card.Body>
          </Card>
        ))}
      </div>
      {/* Break-even Chart */}
      <div className="mb-4">
        <h4>Break-even Analysis</h4>
        <Bar data={breakEvenChartData} options={breakEvenChartOptions} />
      </div>
    </div>
  );
}

export default Cards;
