import React, { useEffect, useState } from "react";
import axios from "axios";

const ClientSalesHistory = ({ clientId }) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [salesData, setSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSales = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}inventory/client/${clientId}/sales/`
        );
        setSalesData(response.data);
        setIsLoading(false);
      } catch (err) {
        setError("Failed to fetch sales data");
        setIsLoading(false);
      }
    };

    fetchSales();
  }, [clientId]);

  const getTotalSales = () => {
    return salesData.reduce((total, sale) => {
      const saleTotal = sale.details.reduce((saleSum, detail) => {
        // Ensure the detail.total_price is a number before adding it.
        return saleSum + (parseFloat(detail.total_price) || 0);
      }, 0);
      return total + saleTotal;
    }, 0);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()}`;
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Receipt Number</th>
            <th>Date</th>
            <th>Product/Service</th>
            <th>Type</th>
            <th>Quantity</th>
            {/*} <th>Discount Amount</th>
            <th>Price Before Discount</th> */}
            <th>Unit Price</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((sale) =>
            sale.details.map((detail, index) => (
              <tr key={index}>
                {index === 0 && (
                  <>
                    <td rowSpan={sale.details.length}>{sale.receipt_number}</td>
                    <td rowSpan={sale.details.length}>
                      {formatDate(sale.date)}
                    </td>
                  </>
                )}
                <td>
                  {detail.product
                    ? detail.product.productName
                    : detail.service.name}
                </td>
                <td>{detail.product ? "Product" : "Service"}</td>
                <td>{detail.quantity_sold}</td>
                {/*}   <td>{detail.discount_amount}</td>
                <td>{detail.price_before_discount}</td> */}
                <td>${detail.unit_price}</td>
                <td>${detail.total_price}</td>
              </tr>
            ))
          )}
          <tr>
            <td colSpan="6" style={{ textAlign: "right" }}>
              <h4>Total:</h4>
            </td>
            <td>
              <h4>${getTotalSales().toFixed(2)}</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClientSalesHistory;
