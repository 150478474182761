import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import Sidebar from "../Design/Sidebar.js";
import Header from "../Header.js";
import { useSelector } from "react-redux";

const ShopEditPet = () => {
  const shop = useSelector((state) => state.shop);
  const shopid = shop.shopId;
  const shopName = shop.shopName;

  const { id } = useParams();

  const [shopDetails, setShopDetails] = useState(null);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [petData, setPetData] = useState({
    PetName: "",
    PetType: "",
    Gender: "",
    Breed: "",
    Spayed: false,
    Weight: "",
    CoatColor: "",
    BirthDate: "",
    Allergies: "",
    AdditionalNote: "",
    VaccinationRecord: "",
    DateAdministered: "",
    NextDue: "",
    HealthFlags: [],
    ServicesFlags: [],
    GuestFlags: [],
    //current_status: "",
    PetImage: null,
  });

  const [healthFlags, setHealthFlags] = useState([]);
  const [serviceFlags, setServiceFlags] = useState([]);
  const [guestFlags, setGuestFlags] = useState([]);

  useEffect(() => {
    const fetchFlags = async (type, setFlags) => {
      try {
        const response = await fetch(`${apiUrl}api/flags/${type}/`);
        const data = await response.json();
        setFlags(data);
      } catch (error) {
        console.error(`Failed to fetch ${type} flags:`, error);
      }
    };

    fetchFlags("health", setHealthFlags);
    fetchFlags("service", setServiceFlags);
    fetchFlags("guest", setGuestFlags);
  }, [apiUrl]);

  const fetchPetDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}api/edit-pet/${id}/`);
      console.log("pet data : ", response.data);

      setPetData({
        ...response.data,
        HealthFlags: response.data.HealthFlags || [],
        ServicesFlags: response.data.ServicesFlags || [],
        GuestFlags: response.data.GuestFlags || [],
      });
    } catch (error) {
      console.error("Error fetching pet details:", error);
    }
  };

  useEffect(() => {
    fetchPetDetails();
  }, []);

  const [breeds, setBreeds] = useState([]);
  const [breedSuggestions, setBreedSuggestions] = useState([]);

  // Fetch breeds from the API
  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${apiUrl}api/breeds/`);
        const data = await response.json();
        setBreeds(data);
      } catch (error) {
        console.error("Failed to fetch breeds:", error);
      }
    };

    fetchBreeds();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPetData({ ...petData, [name]: value });

    if (name === "Breed" && value.length >= 3) {
      const filteredBreeds = breeds
        .filter((breed) =>
          breed.name.toLowerCase().includes(value.toLowerCase())
        )
        .map((breed) => breed.name);
      setBreedSuggestions(filteredBreeds);
    }
  };

  // Handler for selecting a breed from suggestions
  const handleSelectBreed = (breed) => {
    setPetData({ ...petData, Breed: breed });
    setBreedSuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append non-file fields
    Object.entries(petData).forEach(([key, value]) => {
      if (["BirthDate", "DateAdministered", "NextDue"].includes(key)) {
        if (value) {
          formData.append(key, value);
        }
      } else if (key !== "PetImage" && key !== "flags") {
        formData.append(key, value);
      }
    });

    // Append each flag ID individually (as multiple values for the same 'flags' key)
    if (petData.flags && petData.flags.length > 0) {
      formData.append("flags", petData.flags.join(","));
    }

    // Conditionally append PetImage if a file is selected
    if (petData.PetImage) {
      formData.append("PetImage", petData.PetImage);
    }

    try {
      const response = await axios.put(
        `${apiUrl}api/edit-pet/${id}/`,
        formData
      );
      alert("Pet updated successfully!");
      window.location.href = `/shop`;
    } catch (error) {
      console.error("Error updating pet details:", error.response.data);
      alert("There is some error in updating pet!");
    }
  };

  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}api/pet-detail/${id}/`);
        const data = await response.json();
        setShopDetails(data);
      } catch (error) {
        console.error("Error fetching shop details:", error);
      }
    };

    fetchShopDetails();
  }, [id]);

  if (!shopDetails) {
    return <div>Loading...</div>;
  }

  // Function to format the birth date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const flagId = parseInt(value); // Convert value to integer (since it's a flag ID)

    setPetData((prevState) => ({
      ...prevState,
      flags: checked
        ? [...prevState.flags, flagId] // Add flag ID if checked
        : prevState.flags.filter((flag) => flag !== flagId), // Remove flag ID if unchecked
    }));
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar userId={shopDetails.Owner} />
      </div>
      <div className="col-md-9">
        <Header />
        <div className="row">
          <h3
            style={{
              paddingBottom: "10px",
              fontWeight: "700",
              color: "#282c34",
            }}
          >
            Edit {shopDetails.PetName}'s Details
          </h3>
          <form onSubmit={handleSubmit} className="container mt-5">
            {/* Pet fields */}
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Pet Name (required)</label>
                <input
                  type="text"
                  name="PetName"
                  value={petData.PetName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Pet Type (required)</label>
                <select
                  name="PetType"
                  value={petData.PetType}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Dog">Dog</option>
                  <option value="Cat">Cat</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Gender (required)</label>
                <select
                  name="Gender"
                  value={petData.Gender}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="col-md-6">
                <label>Breed (required)</label>
                <input
                  type="text"
                  name="Breed"
                  value={petData.Breed}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                />
                {breedSuggestions.length > 0 && (
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      position: "absolute",
                      backgroundColor: "white",
                      zIndex: 1000,
                    }}
                  >
                    {breedSuggestions.map((breed, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectBreed(breed)}
                        style={{ cursor: "pointer", padding: "5px" }}
                      >
                        {breed}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="row mb-4 mt-4">
              <div className="col-md-6">
                <input
                  type="checkbox"
                  name="Spayed"
                  checked={petData.Spayed}
                  onChange={(e) =>
                    setPetData({ ...petData, Spayed: e.target.checked })
                  }
                  className="form-check-input"
                />
                <label className="form-check-label ml-2">
                  Spayed / Neutered
                </label>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Weight (optional)</label>
                <input
                  type="number"
                  name="Weight"
                  value={petData.Weight}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Coat Color (optional)</label>
                <input
                  type="text"
                  name="CoatColor"
                  value={petData.CoatColor}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Birth Date (optional)</label>
                <input
                  type="date"
                  name="BirthDate"
                  value={petData.BirthDate}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Allergies (optional)</label>
                <textarea
                  as="textarea"
                  rows={5}
                  maxLength={200}
                  name="Allergies"
                  value={petData.Allergies}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Additional Note (optional)</label>
                <textarea
                  name="AdditionalNote"
                  value={petData.AdditionalNote}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Vaccination Record (optional)</label>
                <select
                  name="VaccinationRecord"
                  value={petData.VaccinationRecord}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select Type</option>
                  <option value="Rabies">Rabies</option>
                  <option value="Bordetella">Bordetella</option>
                  <option value="Canine Distemper / Parvo Combo">
                    Canine Distemper / Parvo Combo
                  </option>
                  <option value="Canine Influenza">Canine Influenza</option>
                  <option value="Leptospirosis (Lepto4)">
                    Leptospirosis (Lepto4)
                  </option>
                  <option value="Giardia">Giardia</option>
                  <option value="Canine C3 (dist, hep, parvo)">
                    Canine C3 (dist, hep, parvo)
                  </option>
                  <option value="Canine C4 (dist, parainfluenza)">
                    Canine C4 (dist, parainfluenza)
                  </option>
                  <option value="Canine C5 (dist, hep, parvo, borda, parainfluenza)">
                    Canine C5 (dist, hep, parvo, borda, parainfluenza)
                  </option>
                  <option value="Canine C7 (dist, hep, parvo, borda, para, lept, tet)">
                    Canine C7 (dist, hep, parvo, borda, para, lept, tet)
                  </option>
                  <option value="Feline FVRCP group (rhino, calic, distemper)">
                    Feline FVRCP group (rhino, calic, distemper)
                  </option>
                  <option value="Feline Herpesvirus (FHV)">
                    Feline Herpesvirus (FHV)
                  </option>
                  <option value="Feline Calicivirus (FCV)">
                    Feline Calicivirus (FCV)
                  </option>
                  <option value="Feline Leukemia (FeLV)">
                    Feline Leukemia (FeLV)
                  </option>
                  <option value="Feline Panleukopenia (FPK)">
                    Feline Panleukopenia (FPK)
                  </option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Date Administered (optional)</label>
                <input
                  type="date"
                  name="DateAdministered"
                  value={petData.DateAdministered}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Next Due (optional)</label>
                <input
                  type="date"
                  name="NextDue"
                  value={petData.NextDue}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Pet Image (required)</label>
                <input
                  type="file"
                  name="PetImage"
                  accept="image/*"
                  onChange={(e) =>
                    setPetData({ ...petData, PetImage: e.target.files[0] })
                  }
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label>Health Flags (optional)</label>
                {healthFlags.map((flag) => (
                  <div key={flag.id} className="form-check">
                    <input
                      type="checkbox"
                      name="HealthFlags"
                      value={flag.id}
                      checked={petData.flags.includes(flag.id)}
                      onChange={handleCheckboxChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label">{flag.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Services Flags (optional)</label>
                {serviceFlags.map((flag) => (
                  <div key={flag.id} className="form-check">
                    <input
                      type="checkbox"
                      name="ServiceFlags"
                      value={flag.id}
                      checked={petData.flags.includes(flag.id)}
                      onChange={handleCheckboxChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label">{flag.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Guest Flags (optional)</label>
                {guestFlags.map((flag) => (
                  <div key={flag.id} className="form-check">
                    <input
                      type="checkbox"
                      name="GuestFlags"
                      value={flag.id}
                      checked={petData.flags.includes(flag.id)}
                      onChange={handleCheckboxChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label">{flag.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopEditPet;
