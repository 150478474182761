import React, { useState, useEffect, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { CSVLink } from "react-csv";

function Timecards() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const reportRef = useRef();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/employee/`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (startDate && endDate && selectedUser) {
      try {
        const response = await axios.post(`${apiUrl}api/timecards-report/`, {
          start_date: startDate,
          end_date: endDate,
          user: selectedUser !== "all" ? selectedUser : undefined,
        });
        setResult(response.data.attendance_records);
      } catch (error) {
        console.error("Error fetching attendance records:", error);
      }
    } else {
      alert("Please select start date, end date, and user.");
    }
  };

  const handleLocationClick = (lat, lng) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(url, "_blank");
  };

  const formatTime = (time) => {
    return format(new Date(time), "hh:mm a");
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const headers = [
    { label: "Date", key: "date" },
    { label: "Check-in Time", key: "check_in_time" },
    { label: "Check-out Time", key: "check_out_time" },
    { label: "Ip Address", key: "check_in_ip_address" },
    { label: "Location", key: "check_in_location" },
  ];

  const csvData = result
    ? result.map((record) => ({
      date: new Date(record.date).toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
      }),
        check_in_time: formatTime(record.check_in_time),
        check_out_time: formatTime(record.check_out_time),
        check_in_ip_address: record.check_in_ip_address,
        check_in_location: record.check_in_location,
      }))
    : [];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>Employee</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>

      {result && result.length > 0 && (
        <>
          <div className="d-flex justify-content-end mb-3">
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"employee-timecard-report.csv"}
              className="btn btn-primary me-2"
            >
              Export to CSV
            </CSVLink>
            <Button className="btn btn-secondary" onClick={handlePrint}>
              Print
            </Button>
          </div>
          <div ref={reportRef}>
            <h4 className="mt-5 mb-5">Employee Timecard</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Check-in Time</th>
                  <th>Check-out Time</th>
                  <th>Ip Address</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {result.map((record) => (
                  <tr key={record.id}>
                    <td>{record.date}</td>
                    <td>{formatTime(record.check_in_time)}</td>
                    <td>{formatTime(record.check_out_time)}</td>
                    <td>{record.check_in_ip_address}</td>
                    <td>
                      <Button
                        className="text-decoration-none"
                        variant="link"
                        onClick={() =>
                          handleLocationClick(
                            ...record.check_in_location.split(",")
                          )
                        }
                      >
                        View Location
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
}

export default Timecards;
