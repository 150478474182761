import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector, useDispatch } from "react-redux";

const ServicesForm = () => {
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const dispatch = useDispatch();
    const shop = useSelector((state) => state.shop);
    const id = shop.shopId
    const shopName = shop.shopName

    const [serviceName, setServiceName] = useState('');
    const [serviceCategory, setServiceCategory] = useState('');
    const [servicePrice, setServicePrice] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [services, setServices] = useState([]);

    useEffect(() => {
        axios.get(`${apiUrl}inventory/inventory-services/`)
            .then(response => setServices(response.data))
            .catch(error => console.error('Error fetching services', error));
    }, []);

    const handleSearch = async () => {
        try {
            const response = await axios.get(`${apiUrl}inventory/inventory-services/search/?query=${searchTerm}`);
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching services', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${apiUrl}inventory/inventory-services/`, {
            name: serviceName,
            category: serviceCategory,
            price: servicePrice,
        })
        .then(response => {
            setServices([...services, response.data]);
            setServiceName('');
            setServiceCategory('');
            setServicePrice('');
            alert("Service saved successfully.")
            window.location.href = `/services`;
        })
        .catch(error => console.error('Error adding service', error));
    };

    return (
        <div className="row">

      <div className="col-md-12" style={{ paddingLeft: "0px" }}>

            <form onSubmit={handleSubmit}>
             <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                <div className="row mb-4 mt-4">
                <input
                    type="text"
                    placeholder="Service Name"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                />
                </div>
                <div className="row mb-4 mt-4">
                <select
                    value={serviceCategory}
                    onChange={(e) => setServiceCategory(e.target.value)}
                >
                    <option value="N/A">Select an Option</option>
                    <option value="Grooming">Grooming</option>
                    <option value="Bath">Bath</option>
                    <option value="Veterinary">Veterinary</option>
                    <option value="Day Care">Day Care</option>
                </select>
                </div>
                <div className="row mt-4">
                <input
                    type="text"
                    placeholder="Service Price"
                    value={servicePrice}
                    onChange={(e) => setServicePrice(e.target.value)}
                />
                </div>
                <div className="row mt-4">
                <button type="submit">Add Service</button>
                </div>
                </div>
                    <div className="col-3"></div>
                    </div>
            </form>

            </div>
        </div>
    );
};

export default ServicesForm;
