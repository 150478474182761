import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
//import Sidebar from "../Design/ClientCenterSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Modal, Button } from "react-bootstrap";
import EditPromoGroup from "./EditPromoGroup.js";

function PromoGroups() {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [categories, setCategories] = useState([]);
  const [activeBookingId, setActiveBookingId] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (bookingId) => {
    setActiveBookingId(bookingId);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/promotion-groups/`)
      .then((response) => {
        setCategories(response.data);
        console.log("promo groups : ", response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "20px", fontWeight: "700", color: "#282c34" }}
        >
          Promo Groups
        </h3>

        {/********** */}

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Group Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => handleOpenModal(item.id)}
                  style={{
                    height: "50px",
                    borderBottom: "1px solid #dee2e6",
                    cursor: "pointer",
                  }}
                >
                  <td style={{ textAlign: "center" }}>
                    {item.group_name ? `${item.group_name}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.description ? `${item.description}` : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Promo Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!activeBookingId ? (
              <div></div>
            ) : (
              <EditPromoGroup id={activeBookingId} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      {/*
          {/*}    <div>
            <h1>Categories</h1>
            {categories.map(category => (
                <div key={category.id}>
                    <h2>{category.group_name}</h2>
                    <p>Items: {Array.isArray(category.display_items) ? category.display_items.join(', ') : category.display_items}</p>
                </div>
            ))}
            </div>
      */}
    </div>
  );
}

export default PromoGroups;
