import React, { useState, useEffect, useRef} from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const EmployeeCommission = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const reportRef = useRef();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
        console.log(">>>>>>>", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {};
    if (startDate) data.start_date = startDate;
    if (endDate) data.end_date = endDate;
    if (selectedUser && selectedUser !== "all") data.shop = selectedUser;

    if (Object.keys(data).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}payroll/commissions-report/`,
        data
      );
      setResult(response.data);
      console.log("sales response : ", response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const headers = [
    { label: "Employee", key: "employee" },
    { label: "Total Earnings", key: "total_earnings" },
    { label: "Commission", key: "commission" },
    { label: "Date", key: "date" },
  ];

  const csvData = result
    ? result.map((commission) => ({
        employee: commission.employee.first_name,
        total_earnings: commission.total_earnings,
        commission: commission.commission,
        date: new Date(commission.date).toISOString().split('T')[0],
      }))
    : [];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {result && (
        <div>
        <div className="d-flex justify-content-end mb-3">
          <CSVLink
            data={csvData}
            headers={headers}
            filename={"employee-commission-report.csv"}
            className="btn btn-primary me-2"
          >
            Export to CSV
          </CSVLink>
          <button className="btn btn-secondary" onClick={handlePrint}>
            Print
          </button>
        </div>
        <div ref={reportRef}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Total Service Earnings</th>
              <th>Commission</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {result.map((commission) => (
              <tr key={commission.id}>
                <td>{commission.employee.first_name}</td>
                <td>${commission.total_earnings}</td>
                <td>${commission.commission}</td>
                <td>{new Date(commission.date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeCommission;
