import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Table, Button } from "react-bootstrap";

import { useSelector } from "react-redux";

const EmployeeWorkTime = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/work-time/${userId}/`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user details!", error);
      });
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const formatTime = time => {
    if (!time) return 'Off';
    const [hour, minute] = time.split(':');
    let hourNum = parseInt(hour, 10);
    const period = hourNum >= 12 ? 'PM' : 'AM';
    if (hourNum > 12) {
      hourNum -= 12;
    } else if (hourNum === 0) {
      hourNum = 12;
    }
    return `${hourNum}:${minute} ${period}`;
  };

  const formatTimeRange = (from, to) => {
    if (!from || !to) return 'Off';
    return `${formatTime(from)} to ${formatTime(to)}`;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Employee Work Time
        </h3>
        
        <Link to={`/edit-work-time/${userId}/`}>
          <button className="mb-4 mt-4">Edit</button>
        </Link>
        <Table className="mt-4">
          <tbody>
            {/* Work Time Schedule */}
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Monday</td>
          <td>{formatTimeRange(user.monday_from, user.monday_to)}</td>
        </tr>
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Tuesday</td>
          <td>{formatTimeRange(user.tuesday_from, user.tuesday_to)}</td>
        </tr>
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Wednesday</td>
          <td>{formatTimeRange(user.wednesday_from, user.wednesday_to)}</td>
        </tr>
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Thursday</td>
          <td>{formatTimeRange(user.thursday_from, user.thursday_to)}</td>
        </tr>
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Friday</td>
          <td>{formatTimeRange(user.friday_from, user.friday_to)}</td>
        </tr>
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Saturday</td>
          <td>{formatTimeRange(user.saturday_from, user.saturday_to)}</td>
        </tr>
        <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
          <td style={{ textAlign: "center", fontWeight: "700" }}>Sunday</td>
          <td>{formatTimeRange(user.sunday_from, user.sunday_to)}</td>
        </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EmployeeWorkTime;
