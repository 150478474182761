import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MessageScreen.css';

const ContactList = ({ onSelectContact }) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = () => {
      axios.get(`${apiUrl}messaging/contacts/`)
      .then((response) => setContacts(response.data))
      .catch((error) => console.error('Error fetching contacts:', error));
    }

    fetchContacts();
    const interval = setInterval(fetchContacts, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="contact-list-container">
      <div className="contact-list">
        {contacts.map((contact) => (
          <div
            key={contact.id}
            className="contact-item"
            onClick={() => onSelectContact(contact)}
          >
            <div className="contact-info">
              <h4>{contact.name}</h4>
              <p>{contact.lastMessage}</p>
            </div>
            {contact.unreadCount > 0 && <div className="unread-badge">{contact.unreadCount}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactList;
