import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import {
  Form,
  Button,
  Col,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import moment from "moment-timezone";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const EmployeeForm = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    employee_info: {
      user_role: "",
      status: "",
      service_level: "",
      efficiency: "",
      calendar_assignment: "",
      run_assignment: "",
      phone: "",
      cell: "",
      address: "",
      city: "",
      postal_code: "",
      shown_on_staff_page: false,
      bio: "",
      notes: "",
      time_zone: "",
      date_of_hire: "",
    },
  });

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setFormData((prevState) => ({
      ...prevState,
      employee_info: {
        ...prevState.employee_info,
        time_zone: userTimeZone,
      },
    }));
  }, []);

  const timezones = moment.tz.names();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePhoneChange = (value, name) => {
    setFormData((prevState) => ({
      ...prevState,
      employee_info: {
        ...prevState.employee_info,
        [name]: value,
      },
    }));
  };

  const handleEmployeeInfoChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState) => ({
      ...prevState,
      employee_info: {
        ...prevState.employee_info,
        [name]: newValue,
      },
    }));

    if (name === "postal_code" && value.trim().length > 0) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: value,
              components: "country:MX",
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );


          setFormData((prevState) => ({
            ...prevState,
            employee_info: {
              ...prevState.employee_info,
              city: cityComponent ? cityComponent.long_name : "",
              postal_code: newValue,
            },
          }));
        }
      } catch (error) {
        console.error(
          "Error fetching data from Google Maps Geocoding API",
          error
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${apiUrl}api/create-user/`, formData)
      .then((response) => {
        alert("New employee is created.");
        window.location.href = `/employee-center`;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="service-level-tooltip" {...props}>
      <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
        <li>
          <strong>Level 0:</strong> Person in training
        </li>
        <li>
          <strong>Level 1:</strong> Bather
        </li>
        <li>
          <strong>Level 2:</strong> Groomer with experience
        </li>
        <li>
          <strong>Level 3:</strong> Senior groomer (knows Asian grooming and
          stripping)
        </li>
      </ul>
    </Tooltip>
  );

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Add New Employee
        </h3>
        <Form onSubmit={handleSubmit} className="mt-4">
          <Form.Group as={Row} className="mb-3 mt-5">
            <Form.Label column sm={2}>
              Username
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Username"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Password
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Email
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              First Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First Name"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Last Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                required
              />
            </Col>
          </Form.Group>

          <h4 className="mt-5">Employee Info</h4>

          <Form.Group as={Row} className="mb-3 mt-5">
            <Form.Label column sm={2}>
              Role
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="user_role"
                value={formData.employee_info.user_role}
                onChange={handleEmployeeInfoChange}
              >
                <option value="">Select Role</option>
                <option value="Manager">Manager</option>
                <option value="Assistant Manager">Assistant Manager</option>
                <option value="Groomer">Groomer</option>
                <option value="Daycare">Daycare</option>
                <option value="Reception">Reception</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 mt-5">
            <Form.Label column sm={2}>
              Status
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="status"
                value={formData.employee_info.status}
                onChange={handleEmployeeInfoChange}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Suspended">Suspended</option>
                <option value="Terminated">Terminated</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Service Level{" "}
              <OverlayTrigger placement="right" overlay={renderTooltip}>
                <span style={{ cursor: "pointer", color: "blue" }}>?</span>
              </OverlayTrigger>
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="service_level"
                value={formData.employee_info.service_level}
                onChange={handleEmployeeInfoChange}
                required
              >
                <option value="">Select Service Level</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Efficiency
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="efficiency"
                value={formData.employee_info.efficiency}
                onChange={handleEmployeeInfoChange}
                placeholder="Efficiency"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Phone
            </Form.Label>
            <Col sm={10}>
              <PhoneInput
                international
                defaultCountry="MX"
                value={formData.employee_info.phone}
                onChange={(value) => handlePhoneChange(value, "phone")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Cell
            </Form.Label>
            <Col sm={10}>
              <PhoneInput
                international
                defaultCountry="MX"
                value={formData.employee_info.cell}
                onChange={(value) => handlePhoneChange(value, "cell")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Address
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="address"
                value={formData.employee_info.address}
                onChange={handleEmployeeInfoChange}
                placeholder="Address"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              City
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="city"
                value={formData.employee_info.city}
                onChange={handleEmployeeInfoChange}
                placeholder="City"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Postal Code
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="postal_code"
                value={formData.employee_info.postal_code}
                onChange={handleEmployeeInfoChange}
                placeholder="Postal Code"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Shown on Staff Page
            </Form.Label>
            <Col sm={2}>
              <Form.Check
                type="checkbox"
                name="shown_on_staff_page"
                checked={formData.employee_info.shown_on_staff_page}
                onChange={handleEmployeeInfoChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Bio
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="bio"
                value={formData.employee_info.bio}
                onChange={handleEmployeeInfoChange}
                placeholder="Bio"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Notes
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="notes"
                value={formData.employee_info.notes}
                onChange={handleEmployeeInfoChange}
                placeholder="Notes"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Time Zone
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="time_zone"
                value={formData.employee_info.time_zone}
                onChange={handleEmployeeInfoChange}
              >
                {timezones.map((timezone) => (
                  <option key={timezone} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Date of Hire
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="date"
                name="date_of_hire"
                value={formData.employee_info.date_of_hire}
                onChange={handleEmployeeInfoChange}
                placeholder="Date of Hire"
                required
              />
            </Col>
          </Form.Group>

          <Button type="submit">Add</Button>
        </Form>
      </div>
    </div>
  );
};

export default EmployeeForm;
