import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const PromotionForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    months_period: "",
    no_of_service: "",
    discount_percentage: "",
    date: "",
  });

  useEffect(() => {
    if (id) {
      axios
        .get(`${apiUrl}/payroll/promotions/${id}/`)
        .then((response) => setFormData(response.data))
        .catch((error) =>
          console.error("There was an error fetching the promotion!", error)
        );
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      axios
        .put(`${apiUrl}/payroll/promotions/${id}/`, formData)
        .then(() => navigate("/promotions"))
        .catch((error) =>
          console.error("There was an error updating the promotion!", error)
        );
    } else {
      axios
        .post(`${apiUrl}/payroll/promotions/`, formData)
        .then(() => navigate("/promotions"))
        .catch((error) =>
          console.error("There was an error creating the promotion!", error)
        );
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          {id ? "Edit Promotion" : "Add Promotion"}
        </h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Months Period:</label>
            <input
              type="number"
              name="months_period"
              value={formData.months_period}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>No of Service:</label>
            <input
              type="number"
              name="no_of_service"
              value={formData.no_of_service}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Discount Percentage:</label>
            <input
              type="number"
              name="discount_percentage"
              value={formData.discount_percentage}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Date:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">{id ? "Update" : "Create"}</button>
        </form>
      </div>
    </div>
  );
};

export default PromotionForm;
