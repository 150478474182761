import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, Button, Table } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const BankReconciliation = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [reconciliations, setReconciliations] = useState([]);
  const [stores, setStores] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [storeData, setStoreData] = useState([]);
  const shop = useSelector((state) => state.shop);
  const [shopTimezone, setShopTimezone] = useState("UTC"); // Default to UTC or fetch actual timezone
  const [hasPreviousReconciliation, setHasPreviousReconciliation] = useState(false); // Track if last reconciliation exists

  const [form, setForm] = useState({
    store: "",
    date: "",
    opening_balance: "",
    closing_balance: "",
    sales_in_card: "",
    expenses: "",
    bank_account: "",
    ending_balance: "",
    opening_time: "",
    closing_time: "",
    is_reconciled: false,  // Auto-set on submit
  });

  useEffect(() => {
    fetchBankAccounts();
    fetchStores();
  }, []);

  useEffect(() => {
    if (selectedBankAccount && selectedDate) {
      fetchLastClosingBalance();
      fetchReconciliations();
      fetchSalesAndExpenses();
    }
  }, [selectedBankAccount, selectedDate]);

  const fetchBankAccounts = async () => {
    try {
      const response = await axios.get(`${apiUrl}inventory/accounts/bank/`);
      setBankAccounts(response.data);
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
    }
  };

  const fetchStores = async () => {
    try {
      const response = await axios.get(`${apiUrl}inventory/stores/`);
      setStores(response.data);
      if (response.data && response.data.Timezone) {
        setShopTimezone(response.data.Timezone);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  const fetchLastClosingBalance = async () => {
    try {
        const response = await axios.get(
          `${apiUrl}inventory/reconciliations/bank/last-closing-balance/${selectedBankAccount}/`,
          {
            params: { selectedDate }
          }
        );

        // Log response to check returned balances
        console.log("Response from last closing balance API:", response.data);

        const { lastClosingBalance, lastOpeningBalance, date } = response.data;
        
        setForm((prevForm) => ({
            ...prevForm,  
            opening_balance: date ? (selectedDate === date ? lastOpeningBalance : lastClosingBalance || 0) : prevForm.opening_balance
        }));
        setHasPreviousReconciliation(!!date); // true if a previous reconciliation date exists
    } catch (error) {
        console.error("Error fetching last closing balance:", error);
        setHasPreviousReconciliation(false); // No previous reconciliation found
    }
  };

  const fetchReconciliations = async () => {
    try {
      const response = await axios.get(`${apiUrl}inventory/reconciliations/bank/`, {
        params: {
          bank_account: selectedBankAccount,
        },
      });

      console.log("Fetched reconciliations:", response.data);

      if (response.data.length > 0) {
        setReconciliations(response.data);
      } else {
        setReconciliations([]);
      }
    } catch(error) {
      console.error("Error fetching reconciliations:", error);
    }
  };

  const initializeReconciliation = async (isClosing = false) => {
    const reconciliationData = {
      ...form,
      bank_account: selectedBankAccount,
      opening_time: form.opening_time || getCurrentTimeInTimezone(),
      closing_time: isClosing ? getCurrentTimeInTimezone() : null,
      is_reconciled: isClosing,  
      sales_amount: isClosing ? storeData.reduce((acc, store) => acc + parseFloat(store.salesInCard || 0), 0) : null, // total sales
      closing_balance: isClosing ? form.ending_balance : null,
      shop_timezone: shopTimezone, // Include the shop's timezone
      expenses_amount: isClosing ? storeData.reduce((acc, store) => acc + parseFloat(store.expenses || 0), 0) : null, // total expenses
    };

    console.log("Data to be sent:", reconciliationData); // Log data to check structure

    try {
        const response = await axios.post(`${apiUrl}inventory/reconciliations/bank/create-or-update/`, reconciliationData);
        console.log("Initialized reconciliation:", response.data);
        alert("Data saved successfully.");  // Show success message
        fetchReconciliations();  // Refresh data after initializing
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Validation errors:", error.response.data.errors);
        alert(`Error: ${JSON.stringify(error.response.data.errors)}`); // Display detailed error messages
      } else {
          console.error("Error initializing reconciliation:", error);
      }
    }
  };

  // Automatically get local time in shop's timezone
  const getCurrentTimeInTimezone = () => {
    const currentTime = new Date();
    return currentTime.toLocaleTimeString("en-US", { timeZone: shopTimezone, hour12: false });
  };

  const fetchSalesAndExpenses = async (storeId, date) => {
    console.log(`Fetching sales and expenses for date: ${date}`);
    try {
      const storeSalesExpenses = await Promise.all (
        stores.map(async (store) => {
          const salesResponse = await axios.get(`${apiUrl}inventory/get-card-sales/`, {
            params: { store: store.id, date: selectedDate },
          });
          const expensesResponse = await axios.get(`${apiUrl}inventory/get-card-expenses/`, {
            params: { store: store.id, date: selectedDate, bank_account: selectedBankAccount }
          });
          return {
            storeId: store.id,
            storeName: store.ShopName,
            salesInCard: salesResponse.data.total_sales || 0,
            expenses: expensesResponse.data.total_card_expenses || 0,
          };
        })
      );

      const totalExpenses = storeSalesExpenses.reduce((acc, store) => acc + parseFloat(store.expenses || 0), 0);
      console.log(`Total expenses for date ${date}: ${totalExpenses}`);
      setStoreData(storeSalesExpenses);
    } catch (error) {
      console.error("Error fetching sales and expenses:", error);
    }
  };

  const handleBankAccountChange = (e) => setSelectedBankAccount(e.target.value);
  
  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setSelectedDate(dateValue);
    setForm((prevForm) => ({
      ...prevForm,
      date: dateValue,
    }));
  };

  const handleStoreDataChange = (e, storeId, fieldName) => {
    const { value } = e.target;
    setStoreData((prevStoreData) =>
      prevStoreData.map((store) =>
        store.storeId === storeId ? { ...store, [fieldName]: value } : store
      )
    );
  };

  const calculateEndingBalance = () => {
    const openingBalance = parseFloat(form.opening_balance || 0);
    const sales = storeData.reduce((acc, store) => acc + parseFloat(store.salesInCard || 0), 0);
    const expenses = storeData.reduce((acc, store) => acc + parseFloat(store.expenses || 0), 0);
    const endingBalance = openingBalance + sales - expenses;
    setForm((prevForm) => ({
      ...prevForm,
      ending_balance: endingBalance.toFixed(2),
    }));
  };

  useEffect(() => {
    calculateEndingBalance();
  }, [form.opening_balance, storeData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    initializeReconciliation(form.is_reconciled);  // Call the function with all data fields
  };

  const handleClosingSubmit = async () => {
    setForm(prevForm => ({
      ...prevForm,
      is_reconciled: true
    }));
    initializeReconciliation(true);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    if (name === "opening_balance" || name === "sales_in_card" || name === "expenses") {
      calculateEndingBalance();  // Recalculate the ending balance if any of these fields change
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Bank Reconciliation
        </h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBankAccount">
            <Form.Label>Bank Account</Form.Label>
            <Form.Control name="bank_account" as="select" value={selectedBankAccount} onChange={handleBankAccountChange} required>
              <option value="">Select a Bank Account</option>
              {bankAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.bank_name} - {account.account_number}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control name="date" type="date" value={form.date} onChange={handleDateChange} required />
          </Form.Group>

          <Form.Group controlId="formOpeningBalance" style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "10px" }}>
            <Form.Label>Opening Balance</Form.Label>
            <Form.Control
              type="number"
              name="opening_balance"
              value={form.opening_balance}
              onChange={handleChange}
              readOnly={hasPreviousReconciliation} // Editable only if no previous reconciliation
              style={{ fontWeight: "bold" }}
              required
            />
          </Form.Group>

          <div style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "10px" }}>
            <strong>Sales in Card</strong>
          </div>

          {storeData.map((store, index) => (
            <Form.Group key={store.storeId} style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
              <Form.Label>{store.storeName}</Form.Label>
              <Form.Control
                type="number"
                name="sales_in_card"
                value={store.salesInCard}
                onChange={(e) => handleStoreDataChange(e, store.storeId, 'salesInCard')}
                style={{ width: "100px" }}
              />
            </Form.Group>
          ))}
          <div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", padding: "5px 0", borderTop: "1px solid black", marginTop: "10px" }}>
            <span>Total</span>
            <span>${storeData.reduce((acc, store) => acc + parseFloat(store.salesInCard || 0), 0)}</span>
          </div>

          <div style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "10px", marginTop: "20px" }}>
            <strong>Expenses in Card</strong>
          </div>

          {storeData.map((store, index) => (
            <Form.Group key={store.storeId} style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
              <Form.Label>{store.storeName}</Form.Label>
              <Form.Control
                type="number"
                name="expenses"
                value={store.expenses}
                readOnly
                style={{ width: "100px" }}
              />
            </Form.Group>
          ))}
          <div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", padding: "5px 0", borderTop: "1px solid black", marginTop: "10px" }}>
            <span>Total</span>
            <span>${storeData.reduce((acc, store) => acc + parseFloat(store.expenses || 0), 0)}</span>
          </div>

          <Form.Group controlId="formEndingBalance" style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", padding: "10px 0", borderTop: "2px solid black", marginTop: "20px" }}>
            <Form.Label>Ending Balance</Form.Label>
            <Form.Control
              type="number"
              name="ending_balance"
              value={form.ending_balance}
              onChange={handleChange}
              style={{ fontWeight: "bold", width: "100px" }}
              readOnly={form.is_reconciled} // Editable only when not reconciled
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" style={{ marginTop: "20px" }}>
              Submit Opening
          </Button>
          <Button variant="secondary" onClick={handleClosingSubmit} style={{ marginLeft: "10px", marginTop: "20px" }}>
              Submit Closing
          </Button>
        </Form>
        <h3 style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}>
          Reconciliation of the Account {bankAccounts.find((account) => account.id === selectedBankAccount)?.account_number}
        </h3>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Opening Balance</th>
              <th>Sales</th>
              <th>Expenses</th>
              <th>Ending Balance</th>
            </tr>
          </thead>
          <tbody>
            {reconciliations
              .filter(rec => rec.bank_account === parseInt(selectedBankAccount, 10)) // Ensure only selected account’s data
              .map((rec) => (
                <tr key={rec.id}>
                  <td className="text-center">{rec.date}</td>
                  <td className="text-center">${rec.opening_balance}</td>
                  <td className="text-center">${rec.sales_amount}</td>
                  <td className="text-center">${rec.expenses || '0.00'}</td>
                  <td className="text-center">${rec.closing_balance}</td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BankReconciliation;
