import { useState, useEffect } from "react";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faAngleDown, faAngleUp, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from ".././actions/userActions";

import { clearCart } from "../actions/cartActions"; 

function DashboardHeader() {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  /* HANDLER */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(clearCart());
    navigate('/shop');
  };


  return (
    <header>
      <div className="app-topbar navbar-light bg-white topbar mb-4 static-top shadow">

        <div className="user-profile">
        <Link to={`/shop`} onClick={handleClick} data-toggle="tooltip" data-placement="bottom" title="Exit Dashboard">
          <FontAwesomeIcon icon={faArrowRight} className="mr-4"/>
          </Link>
        </div>
      </div>
    </header>
  );
}
export default DashboardHeader;
