import moment from 'moment';
import 'moment-timezone';

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function getPreviousBusinessDays(currentDate, timezone = 'America/Mexico_City') {
    const result = [];
    const date = moment.tz(currentDate, timezone);
    const dayOfWeek = date.day(); // 0 (Sunday) to 6 (Saturday)

    // Add the current day if it's not Sunday
    //if (dayOfWeek !== 0) {
    //    result.push(formatDate(date));
    //}

    // Check previous days
    if (dayOfWeek === 1) {
        for (let i = 3; i >= 1; i--) {
            const previousDay = moment(date).subtract(i, 'days');
            result.push(formatDate(previousDay));
        }
    } else {
        // For other days, add the previous business day(s)
        for (let i = 1; i <= 2; i++) {
            const previousDay = moment(date).subtract(i, 'days');
            if (previousDay.day() !== 0) { // Exclude Sundays
                result.push(formatDate(previousDay));
            }
        }
    }

    return result;
}

function formatDate(date) {
    return date.format('YYYY-MM-DD');
}


