import React, { useState } from "react";

function CreateExpenseCategoryForm() {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState("");
  const [type, setType] = useState("product"); // Default to 'Product'

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newCategory = {
      name,
      type,
    };

    try {
      const response = await fetch(`${apiUrl}inventory/expenses/categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newCategory),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Success:", result);
      alert("New category is created!");
      window.location.href = `/item-categories`;
    } catch (error) {
      console.error("Error:", error);
      alert("Error in creating new category.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
          <div className="row mb-4 mt-4">
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="row mb-4">
            <label>Type:</label>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="product">Product</option>
              <option value="operation">Operation</option>
            </select>
          </div>
          <div className="row mb-4">
            <button type="submit">Create Category</button>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </form>
  );
}

export default CreateExpenseCategoryForm;
