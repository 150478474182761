import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import ServicesForm from "./ServicesForm";
import { Table, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

const AllServices = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [serviceName, setServiceName] = useState("");
  const [serviceCategory, setServiceCategory] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState([]);

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/inventory-services/`)
      .then((response) => setServices(response.data))
      .catch((error) => console.error("Error fetching services", error));
  }, []);

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/inventory-services/search/?query=${searchTerm}`
      );
      setServices(response.data);
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };

  const handleDeleteService = async (serviceId) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      try {
        await axios.delete(
          `${apiUrl}inventory/inventory-services/${serviceId}/`
        );
        setServices(services.filter((service) => service.id !== serviceId));
      } catch (error) {
        console.error("Error deleting service", error);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Services
        </h3>

        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
            Add New Service
          </Button>
        </div>

        <div className="search-filter">
          <input
            type="text"
            placeholder="Search for services"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch} className="ml-2">
            Search
          </button>
        </div>

        <Table striped bordered hover className="m-2">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service.id}>
                <td>{service.name}</td>
                <td>{service.category}</td>
                <td>${service.price}</td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteService(service.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ServicesForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllServices;
