import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const CashBalanceChart = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [data, setData] = useState({});
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiUrl, userInfo.token]);

  const fetchCashBalance = async () => {
    try {
      const response = await axios.get(`${apiUrl}inventory/cash-balance/`, {
        params: { month, year, store: selectedUser },
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching cash balance data", error);
    }
  };

  useEffect(() => {
    fetchCashBalance();
  }, [month, year, selectedUser]);

  const chartData = {
    labels: ["Opening Balance", "Cash Income", "Cash Expense", "Projected Balance"],
    datasets: [
      {
        label: "Cash Flow",
        data: [
          data.opening_balance || 0,
          data.total_cash_income || 0,
          data.total_cash_expense || 0,
          data.projected_balance || 0,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Monthly Cash Flow (Waterfall Chart)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <h4>Monthly Cash Balance</h4>
          <div className="d-flex mb-4">
            <div>
              <label>Month</label>
              <input
                type="number"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                min="1"
                max="12"
              />
            </div>
            <div className="ms-4">
              <label>Year</label>
              <input
                type="number"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                min="2000"
                max={new Date().getFullYear()}
              />
            </div>
            <div className="ms-4">
              <label>Shop</label>
              <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                <option value="">All Shops</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.ShopName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <Bar data={chartData} options={chartOptions} />
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Cash Flow Details</th>
                <th>Amount ($)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Cash Income</td>
                <td>${data.total_cash_income?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Total Cash Expense</td>
                <td>${data.total_cash_expense?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Accounts Payable</td>
                <td>${data.accounts_payable?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Accounts Receivable</td>
                <td>${data.accounts_receivable?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Working Capital</td>
                <td>${data.working_capital?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Projected Balance</td>
                <td>${data.projected_balance?.toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CashBalanceChart;
