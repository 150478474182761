import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { Button, Modal, Table } from "react-bootstrap";
import BreedForm from "./BreedForm";

import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { useSelector, useDispatch } from "react-redux";

const API_URL = process.env.REACT_APP_BASE_URL;

const BreedList = () => {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [breeds, setBreeds] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}api/add-breeds/`)
      .then((response) => setBreeds(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${API_URL}api/add-breeds/${id}/`)
      .then((response) => setBreeds(breeds.filter((breed) => breed.id !== id)))
      .catch((error) => console.error(error));
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Breeds
        </h3>
        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4">
            Add Breed
          </Button>
        </div>
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Breed</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {breeds.map((breed) => (
                <tr key={breed.id}>
                  <td>{breed.name}</td>
                  <td>{breed.active ? "Active" : "Inactive"}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(breed.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Breed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BreedForm />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default BreedList;
