import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

const apiUrl = process.env.REACT_APP_BASE_URL;

const BankAccountForm = ({ selectedBankAccount, onSave }) => {
  const [stores, setStores] = useState([]);
  const [banks, setBanks] = useState([]);
  const [bankAccount, setBankAccount] = useState({
    store: "",
    account_number: "",
    bank: "",
  });

  useEffect(() => {
    axios.get(`${apiUrl}inventory/stores/`).then((response) => {
      setStores(response.data);
    });

    axios.get(`${apiUrl}inventory/banks/`).then((response) => {
      setBanks(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedBankAccount) {
      setBankAccount(selectedBankAccount);
    }
  }, [selectedBankAccount]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankAccount({
      ...bankAccount,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (bankAccount.id) {
      axios
        .put(`${apiUrl}inventory/bankaccounts/${bankAccount.id}/`, bankAccount)
        .then(() => onSave());
    } else {
      axios
        .post(`${apiUrl}inventory/bankaccounts/`, bankAccount)
        .then(() => onSave());
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Store</Form.Label>
        <Form.Control
          as="select"
          name="store"
          value={bankAccount.store}
          onChange={handleChange}
        >
          <option value="">Select Store</option>
          {stores.map((store) => (
            <option key={store.id} value={store.id}>
              {store.ShopName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>Account Number</Form.Label>
        <Form.Control
          type="text"
          name="account_number"
          value={bankAccount.account_number}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Bank</Form.Label>
        <Form.Control
          as="select"
          name="bank"
          value={bankAccount.bank}
          onChange={handleChange}
        >
          <option value="">Select Bank</option>
          {banks.map((bank) => (
            <option key={bank.id} value={bank.id}>
              {bank.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
  );
};

export default BankAccountForm;
