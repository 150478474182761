import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//import Sidebar from "../Design/AddPetSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector } from "react-redux";

const ShopAddPet = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const { id } = useParams();
  const initialPetData = {
    PetName: "",
    PetType: "",
    Gender: "",
    Breed: "",
    Spayed: false,
    Weight: "",
    CoatColor: "",
    BirthDate: "",
    Allergies: "",
    AdditionalNote: "",
    VaccinationRecord: "",
    DateAdministered: "",
    NextDue: "",
    social_url: "",
    //HealthFlags: [],
    //ServicesFlags: [],
    //GuestFlags: [],
    //current_status: "",
    flags: [],
    PetImage: null,
  };

  const [availableFlags, setAvailableFlags] = useState({
    health: [],
    service: [],
    guest: [],
  });
  const [petData, setPetData] = useState(initialPetData);
  const [breeds, setBreeds] = useState([]);
  const [breedSuggestions, setBreedSuggestions] = useState([]);

  // Fetch breeds from the API
  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${apiUrl}api/breeds/`);
        const data = await response.json();
        setBreeds(data);
      } catch (error) {
        console.error("Failed to fetch breeds:", error);
      }
    };

    fetchBreeds();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPetData({ ...petData, [name]: value });

    if (name === "Breed" && value.length >= 3) {
      const filteredBreeds = breeds
        .filter((breed) =>
          breed.name.toLowerCase().includes(value.toLowerCase())
        )
        .map((breed) => breed.name);
      setBreedSuggestions(filteredBreeds);
    }
  };

  // Handler for selecting a breed from suggestions
  const handleSelectBreed = (breed) => {
    setPetData({ ...petData, Breed: breed });
    setBreedSuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      Object.entries(petData).forEach(([key, value]) => {
        if (key === "PetImage" && value !== null) {
          formData.append(key, value, value.name);
        } else if (key === "Spayed") {
          // Convert boolean to string for backend
          formData.append(key, value.toString());
        } else if (key === "flags") {
          value.forEach((flagId) => formData.append("flags", flagId));
        } else {
          formData.append(key, value);
        }
      });

      const response = await axios.post(
        `${apiUrl}api/add-pet/${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Pet added successfully!");
      window.location.href = `/client-profile/${id}/`;
    } catch (error) {
      console.error("Error adding pet:", error);
      alert("Pet not added, fill all required fields.");
    }
  };

  const handleCancel = () => {
    // Reset the form to its initial state
    setPetData(initialPetData);
  };

  // Fetch available flags from the backend
  useEffect(() => {
    const fetchFlags = async (flagType) => {
      const response = await axios.get(`${apiUrl}api/flags/${flagType}/`);
      return response.data;
    };

    const flagTypes = ["health", "service", "guest"];
    flagTypes.forEach((type) => {
      fetchFlags(type).then((flags) => {
        setAvailableFlags((prev) => ({ ...prev, [type]: flags }));
      });
    });
  }, [apiUrl]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setPetData((prev) => ({
      ...prev,
      flags: checked
        ? [...prev.flags, value]
        : prev.flags.filter((flag) => flag !== value),
    }));
  };

  const renderFlagCheckboxes = (flagType) =>
    availableFlags[flagType].map((flag) => (
      <div key={flag.id} className="form-check">
        <input
          type="checkbox"
          name="flags"
          value={flag.id}
          checked={petData.flags.includes(flag.id.toString())}
          onChange={handleCheckboxChange}
          className="form-check-input"
        />
        <label className="form-check-label">{flag.name}</label>
      </div>
    ));

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Add New Pet
        </h3>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <form onSubmit={handleSubmit} className="container mt-5">
            {/* Pet fields */}
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Pet Name (required)</label>
                <input
                  type="text"
                  name="PetName"
                  value={petData.PetName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Pet Type (required)</label>
                <select
                  name="PetType"
                  value={petData.PetType}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Dog">Dog</option>
                  <option value="Cat">Cat</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Gender (required)</label>
                <select
                  name="Gender"
                  value={petData.Gender}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="col-md-6">
                <label>Breed (required)</label>
                <input
                  type="text"
                  name="Breed"
                  value={petData.Breed}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                />
                {breedSuggestions.length > 0 && (
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      position: "absolute",
                      backgroundColor: "white",
                      zIndex: 1000,
                    }}
                  >
                    {breedSuggestions.map((breed, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectBreed(breed)}
                        style={{ cursor: "pointer", padding: "5px" }}
                      >
                        {breed}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="row mb-4 mt-4">
              <div className="col-md-6">
                <input
                  type="checkbox"
                  name="Spayed"
                  checked={petData.Spayed}
                  onChange={(e) =>
                    setPetData({ ...petData, Spayed: e.target.checked })
                  }
                  className="form-check-input"
                />
                <label className="form-check-label ml-2">
                  Spayed / Neutered
                </label>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Weight (optional)</label>
                <input
                  type="number"
                  name="Weight"
                  value={petData.Weight}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Coat Color (optional)</label>
                <input
                  type="text"
                  name="CoatColor"
                  value={petData.CoatColor}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Birth Date (optional)</label>
                <input
                  type="date"
                  name="BirthDate"
                  value={petData.BirthDate}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Allergies (optional)</label>
                <textarea
                  as="textarea"
                  rows={5}
                  maxLength={200}
                  name="Allergies"
                  value={petData.Allergies}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Additional Note (optional)</label>
                <textarea
                  name="AdditionalNote"
                  value={petData.AdditionalNote}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Vaccination Record (optional)</label>
                <select
                  name="VaccinationRecord"
                  value={petData.VaccinationRecord}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select Type</option>
                  <option value="Rabies">Rabies</option>
                  <option value="Bordetella">Bordetella</option>
                  <option value="Canine Distemper / Parvo Combo">
                    Canine Distemper / Parvo Combo
                  </option>
                  <option value="Canine Influenza">Canine Influenza</option>
                  <option value="Leptospirosis (Lepto4)">
                    Leptospirosis (Lepto4)
                  </option>
                  <option value="Giardia">Giardia</option>
                  <option value="Canine C3 (dist, hep, parvo)">
                    Canine C3 (dist, hep, parvo)
                  </option>
                  <option value="Canine C4 (dist, parainfluenza)">
                    Canine C4 (dist, parainfluenza)
                  </option>
                  <option value="Canine C5 (dist, hep, parvo, borda, parainfluenza)">
                    Canine C5 (dist, hep, parvo, borda, parainfluenza)
                  </option>
                  <option value="Canine C7 (dist, hep, parvo, borda, para, lept, tet)">
                    Canine C7 (dist, hep, parvo, borda, para, lept, tet)
                  </option>
                  <option value="Feline FVRCP group (rhino, calic, distemper)">
                    Feline FVRCP group (rhino, calic, distemper)
                  </option>
                  <option value="Feline Herpesvirus (FHV)">
                    Feline Herpesvirus (FHV)
                  </option>
                  <option value="Feline Calicivirus (FCV)">
                    Feline Calicivirus (FCV)
                  </option>
                  <option value="Feline Leukemia (FeLV)">
                    Feline Leukemia (FeLV)
                  </option>
                  <option value="Feline Panleukopenia (FPK)">
                    Feline Panleukopenia (FPK)
                  </option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Date Administered (optional)</label>
                <input
                  type="date"
                  name="DateAdministered"
                  value={petData.DateAdministered}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Next Due (optional)</label>
                <input
                  type="date"
                  name="NextDue"
                  value={petData.NextDue}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Pet Image (required)</label>
                <input
                  type="file"
                  name="PetImage"
                  accept="image/*"
                  onChange={(e) =>
                    setPetData({ ...petData, PetImage: e.target.files[0] })
                  }
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Instagram/Tiktok URL (optional)</label>
                <input
                  type="url"
                  name="social_url"
                  value={petData.social_url}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label>Health Flags (optional)</label>
                {renderFlagCheckboxes("health")}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Services Flags (optional)</label>
                {renderFlagCheckboxes("service")}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Guest Flags (optional)</label>
                {renderFlagCheckboxes("guest")}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mt-2 ml-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopAddPet;
