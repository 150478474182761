import { useState, useEffect } from "react";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faAngleDown, faAngleUp, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from ".././actions/userActions";

function PofHeader() {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);
  const shop = useSelector((state) => state.shop);
  const { shopName } = shop;
  const { userInfo } = userLogin;

  /* HANDLER */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);


  return (
    <header>
      <div className="app-topbar navbar-light bg-white topbar mb-4 static-top shadow">
        {shopName && <span className="shop-name">{shopName}</span>}
        <div className="user-profile">
        <Link to={`/inventory-main`} data-toggle="tooltip" data-placement="bottom" title="Exit Purchase Orders">
          <FontAwesomeIcon icon={faArrowRight} className="mr-4"/>
          </Link>
        </div>
      </div>
    </header>
  );
}
export default PofHeader;
