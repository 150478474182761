import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ServiceVolume.css";
import { RadialGauge } from "react-canvas-gauges";

const VolumeMeter = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}inventory/service-volume/`);
        setData(response.data.sale_data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="mt-4 mb-4">Services Volume by Store (Grooming)</h4>
        <div className="d-flex flex-wrap justify-content-around">
          {Object.entries(data).map(([shopName, volume]) => (
            <div key={shopName} className="text-center m-3">
              <h3>{shopName}</h3>
              <RadialGauge
                units="%"
                value={volume}
                minValue={0}
                maxValue={100}
                majorTicks={["0", "20", "40", "60", "80", "100"]}
                minorTicks={2}
                strokeTicks={true}
                highlights={[
                  { from: 0, to: 50, color: "#8ef5f5" },
                  { from: 50, to: 100, color: "#51c5c5" },
                ]}
                colorPlate="#fff"
                needleType="line"
                needleWidth={3}
                borderShadowWidth={0}
                borders={false}
                borderRadius={0}
                animationDuration={1500}
                animationRule="bounce"
                valueBox={false}
                width={200}
                height={200}
              />
              <p>{volume}%</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VolumeMeter;
