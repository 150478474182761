import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Image } from "react-bootstrap";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";

const API_URL = process.env.REACT_APP_BASE_URL;
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ShopForm = ({ shop, onSave }) => {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [formData, setFormData] = useState({
    user: "",
    Prefixs: "",
    OwnerFirstName: "",
    OwnerLastName: "",
    ShopName: "",
    Address: "",
    AddressTwo: "",
    City: "",
    StateCountry: "",
    PostalCode: "",
    Area: "",
    ShopImage: null,
    ContactDetails: {
      ContactType: "",
      ContactNumber: "",
      ContactBy: "",
      Primary: false,
    },
  });

  useEffect(() => {
    if (shop) {
      setFormData(shop);
    }
  }, [shop]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "PostalCode" && value.trim().length > 0) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: value,
              components: "country:MX",
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const areaComponent = addressComponents.find(
            (component) =>
              component.types.includes("sublocality") ||
              component.types.includes("political")
          );
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country")
          );

          setFormData((prevState) => ({
            ...prevState,
            City: cityComponent ? cityComponent.long_name : "",
            Area: areaComponent ? areaComponent.long_name : "",
            StateCountry: countryComponent ? countryComponent.long_name : "",
          }));
        }
      } catch (error) {
        console.error(
          "Error fetching data from Google Maps Geocoding API",
          error
        );
      }
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      ShopImage: e.target.files[0],
    });
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      ContactDetails: { ...formData.ContactDetails, [name]: value },
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      ContactDetails: { ...formData.ContactDetails, ContactNumber: value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("user", userInfo.id);
    data.append("Prefixs", formData.Prefixs);
    data.append("OwnerFirstName", formData.OwnerFirstName);
    data.append("OwnerLastName", formData.OwnerLastName);
    data.append("ShopName", formData.ShopName);
    data.append("Address", formData.Address);
    data.append("AddressTwo", formData.AddressTwo);
    data.append("City", formData.City);
    data.append("StateCountry", formData.StateCountry);
    data.append("PostalCode", formData.PostalCode);
    data.append("Area", formData.Area);
    if (formData.ShopImage instanceof File) {
      data.append("ShopImage", formData.ShopImage);
    }
    data.append(
      "ContactDetails.ContactType",
      formData.ContactDetails.ContactType
    );
    data.append(
      "ContactDetails.ContactNumber",
      formData.ContactDetails.ContactNumber
    );
    data.append("ContactDetails.ContactBy", formData.ContactDetails.ContactBy);
    data.append("ContactDetails.Primary", formData.ContactDetails.Primary);

    if (formData.id) {
      axios
        .put(`${API_URL}api/shopdetails/${formData.id}/`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => onSave());
    } else {
      axios
        .post(`${API_URL}api/shopdetails/`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => onSave());
      alert("Shop created.");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Shop Name</Form.Label>
        <Form.Control
          type="text"
          name="ShopName"
          value={formData.ShopName}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Prefix</Form.Label>
        <Form.Control
          type="text"
          name="Prefixs"
          value={formData.Prefixs}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Owner First Name</Form.Label>
        <Form.Control
          type="text"
          name="OwnerFirstName"
          value={formData.OwnerFirstName}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Owner Last Name</Form.Label>
        <Form.Control
          type="text"
          name="OwnerLastName"
          value={formData.OwnerLastName}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Address</Form.Label>
        <Form.Control
          type="text"
          name="Address"
          value={formData.Address}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Address Two</Form.Label>
        <Form.Control
          type="text"
          name="AddressTwo"
          value={formData.AddressTwo}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="text"
          name="PostalCode"
          value={formData.PostalCode}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          name="City"
          value={formData.City}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>State/Country</Form.Label>

        <Form.Select
          name="StateCountry"
          value={formData.StateCountry}
          onChange={handleChange}
        >
          <option value="">Select</option>
          <option value="Aguascalientes">Aguascalientes</option>
          <option value="Baja California">Baja California</option>
          <option value="Baja California Sur">Baja California Sur</option>
          <option value="Campeche">Campeche</option>
          <option value="Chiapas">Chiapas</option>
          <option value="Chihuahua">Chihuahua</option>
          <option value="Ciudad de México">Ciudad de México</option>
          <option value="Coahuila">Coahuila</option>
          <option value="Colima">Colima</option>
          <option value="Durango">Durango</option>
          <option value="Guanajuato">Guanajuato</option>
          <option value="Guerrero">Guerrero</option>
          <option value="Hidalgo">Hidalgo</option>
          <option value="Jalisco">Jalisco</option>
          <option value="México">México</option>
          <option value="Michoacán">Michoacán</option>
          <option value="Morelos">Morelos</option>
          <option value="Nayarit">Nayarit</option>
          <option value="Nuevo León">Nuevo León</option>
          <option value="Oaxaca">Oaxaca</option>
          <option value="Puebla">Puebla</option>
          <option value="Querétaro">Querétaro</option>
          <option value="Quintana Roo">Quintana Roo</option>
          <option value="San Luis Potosí">San Luis Potosí</option>
          <option value="Sinaloa">Sinaloa</option>
          <option value="Sonora">Sonora</option>
          <option value="Tabasco">Tabasco</option>
          <option value="Tamaulipas">Tamaulipas</option>
          <option value="Tlaxcala">Tlaxcala</option>
          <option value="Veracruz">Veracruz</option>
          <option value="Yucatán">Yucatán</option>
          <option value="Zacatecas">Zacatecas</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Area</Form.Label>
        <Form.Control
          type="text"
          name="Area"
          value={formData.Area}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Shop Image</Form.Label>
        <Form.Control
          type="file"
          name="ShopImage"
          onChange={handleFileChange}
        />
        {formData.ShopImage && !(formData.ShopImage instanceof File) && (
          <div>
            <Image src={formData.ShopImage} thumbnail />
          </div>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Number</Form.Label>
        <PhoneInput
          international
          defaultCountry="MX"
          value={formData.ContactDetails.ContactNumber}
          onChange={handlePhoneChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
  );
};

export default ShopForm;
