import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const PromotionList = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/payroll/promotions/`)
      .then((response) => setPromotions(response.data))
      .catch((error) =>
        console.error("There was an error fetching the promotions!", error)
      );
  }, []);

  const deletePromotion = (id) => {
    axios
      .delete(`${apiUrl}/payroll/promotions/${id}/`)
      .then(() =>
        setPromotions(promotions.filter((promotion) => promotion.id !== id))
      )
      .catch((error) =>
        console.error("There was an error deleting the promotion!", error)
      );
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Promotions
        </h3>
        <Link to={`/promotions/new`}>
          <button className="mb-4 mt-4">Add Promotion</button>
        </Link>
        <table>
          <thead>
            <tr>
              <th>Months Period</th>
              <th>Number of services</th>
              <th>Discount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {promotions.map((promotion) => (
              <tr key={promotion.id}>
                <td className="text-center">{promotion.months_period}</td>
                <td className="text-center">{promotion.no_of_service}</td>
                <td className="text-center">{promotion.discount_percentage}%</td>
                <td className="text-center">
                  <Link to={`/promotions/${promotion.id}/edit`}>
                    <button className="mr-2">Edit</button>
                  </Link>
                  <button onClick={() => deletePromotion(promotion.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PromotionList;
