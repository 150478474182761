import { useState, useEffect } from "react";
import "../App.css";

import { Link, useNavigate } from "react-router-dom";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from ".././actions/userActions";

function Header({ toggleSidebar }) {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);
  const shop = useSelector((state) => state.shop);

  const { userInfo } = userLogin;
  const { shopName } = shop;

  /* HANDLER */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());

  };

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);


  return (
    <header>
      <div className="app-topbar navbar-light bg-white topbar mb-4 static-top shadow">{/*}
      {!userInfo ? (
        <button className="hamburger-button">
          &#9776;
        </button>
        ) : (
          <button className="hamburger-button" onClick={toggleSidebar}>
          &#9776;
        </button>
        )}*/}
        <div className="user-profile">
          {shopName && <span className="shop-name">{shopName}</span>}
          {userInfo && userInfo.user_role === "Manager" && (
            <span>{userInfo.username}</span>
          )}
          {userInfo && userInfo.user_role === "Assistant Manager" && (
            <span>{userInfo.username}</span>
          )}
          {userInfo && userInfo.user_role === "Groomer" && (
            <span>{userInfo.username}</span>
          )}
          {userInfo && userInfo.user_role === "Daycare" && (
            <span>{userInfo.username}</span>
          )}
          {userInfo && userInfo.user_role === "Reception" && (
            <span>{userInfo.username}</span>
          )}
          {userInfo && userInfo.user_role === "Manager" && <span>Manager</span>}
          {userInfo && userInfo.user_role === "Assistant Manager" && <span>Assistant Manager</span>}
          {userInfo && userInfo.user_role === "Groomer" && <span>Groomer</span>}
          {userInfo && userInfo.user_role === "Daycare" && <span>Daycare</span>}
          {userInfo && userInfo.user_role === "Reception" && <span>Reception</span>}
          {!userInfo ? (
            <span><Link to="/login" style={{color:"white", textDecoration:"none"}}>Login</Link></span>
          ) : (
            <span onClick={logoutHandler}>Logout</span>
          )}
        </div>
      </div>
    </header>
  );
}
export default Header;
