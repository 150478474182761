import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Header from "../DashboardHeader.js";
import StoreSalesPieChart from "./SalesComponents/StoreSalesPieChart";
import RevenueChart from "./SalesComponents/RevenueService";
import VolumeMeter from "./SalesComponents/ServiceVolume";
import Cards from "./FinancialComponents/Cards";
import RevenueCogs from "./FinancialComponents/RevenueCogs";
import CashBalanceChart from "./FinancialComponents/CashBalance";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const Financial = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
        console.log(">>>>>>>", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {};
    if (startDate) data.start_date = startDate;
    if (endDate) data.end_date = endDate;
    if (selectedUser && selectedUser !== "all") data.shop = selectedUser;

    if (Object.keys(data).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}inventory/sales-report/`,
        data
      );
      setResult(response.data.sale_records);
      console.log("sales response : ", response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  return (
    <div className="row">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <Cards />
          </div>
        </div>
        <div className="row mx-4 mb-4">
          <div className="col-md-6">
            <CashBalanceChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financial;
