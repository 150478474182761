import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import Sidebar from "../Design/Sidebar.js";
import Header from "../Header.js";

import { useSelector } from 'react-redux';

const ClientProfile = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId
  const shopName = shop.shopName
  //const { shopName } = useParams();
  const { id } = useParams();

  const [shopDetails, setShopDetails] = useState(null);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}api/client-detail/${id}/`
        );
        const data = await response.json();
        setShopDetails(data);
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    fetchShopDetails();
  }, [id]);

  if (!shopDetails) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar
          userId={shopDetails.id}
        />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
          className="mt-4"
        >
          About {shopDetails.FirstName}
        </h3>
        <div className="col-12">
          {shopDetails.unsigned_policy_titles &&
            shopDetails.unsigned_policy_titles.length > 0 && (
              <p className="mt-4">
                This client has not yet signed{" "}
                {shopDetails.unsigned_policy_titles.join(", ")}{" "}
                <strong>which are required</strong>
              </p>
            )}
          {shopDetails.unsigned_policy_titles &&
            shopDetails.unsigned_policy_titles.length > 0 && (
              <Link
                to={`/signature-policy/${shopDetails.id}`}
                className="text-decoration-none text-dark px-4 mb-4"
              >
                <button className="mb-4">Sign Now</button>
              </Link>
            )}
          <p>
            Next scheduled appointment on{" "}
            {formatDate(shopDetails.next_booking_date)}
          </p>

          <div className="row">
            <div className="col-12">
          <Link to={`/edit-client/${id}/`}>
          <button className="mr-2">Edit Client</button></Link>

          <Link to={`/shop-add-pet/${id}/`}>
          <button className="">Add Pet</button></Link>
          </div>
          </div>
          <Table className="mt-4">
            <tbody>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Prefix
                </td>
                <td>{shopDetails.Prefix}</td>
              </tr>

              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  First Name
                </td>
                <td>{shopDetails.FirstName}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Last Name
                </td>
                <td>{shopDetails.LastName}</td>
              </tr>
              {shopDetails.contacts &&
                shopDetails.contacts.map((contact, index) => (
                  <React.Fragment key={index}>
                    <tr
                      style={{
                        height: "50px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        Contact
                      </td>
                      <td>
                        {contact.contact_type} {contact.contact_value}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}

              {shopDetails.pets &&
                shopDetails.pets.map((pets, index) => (
                  <React.Fragment key={index}>
                    <tr
                      style={{
                        height: "50px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        Pet
                      </td>
                      <td>
                        {pets.PetName}
                        <Link key={index} to={`/pet-profile/${pets.id}`}>
                        <button className="ml-4">View Pet Details</button></Link>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}

              {/*}    <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Service Categories
                </td>
                <td>
                  {Array.isArray(shopDetails.ServiceCategory)
                    ? shopDetails.ServiceCategory.join(", ")
                    : shopDetails.ServiceCategory}
                </td>
                  </tr>*/}
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Notes
                </td>
                <td>{shopDetails.SpecialNotes}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Referral Source
                </td>
                <td>{shopDetails.ClientRefferalSource}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Client Since
                </td>
                <td>{formatDate(shopDetails.ClientSince)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ClientProfile;
