import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Table } from "react-bootstrap";
import Header from "../Header.js";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faTimes } from "@fortawesome/free-solid-svg-icons";
import PetPlaceholder from "../../Images/dog_placeholder.avif";

const ShopBookingsDetail = ({ shopName, bookingIds }) => {
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [bookingDetails, setBookingDetails] = useState([]);
  const [selectedItem, setSelectedItem] = useState("Pets");
  const [petStatus, setPetStatus] = useState("");
  const [recentServices, setRecentServices] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showServices, setShowServices] = useState(false);
  const [getServices, setGetServices] = useState([]);
  const [clientStatus, setClientStatus] = useState("");
  const [existingServices, setExistingServices] = useState({});
  const [lastServices, setLastServices] = useState([]);
  const [calendars, setCalendars] = useState([]);

  const fetchBookingDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}api/multiple-booking-details/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ booking_ids: bookingIds }),
      });
      const data = await response.json();
      setBookingDetails(data);

      data.forEach((booking) => {
        fetchLastServices(booking.petname.id);
      });
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const fetchLastServices = async (petId) => {
    try {
      const response = await axios.get(`${apiUrl}api/last-service/${petId}/`);
      const data = response.data;
      setLastServices((prevState) => ({ ...prevState, [petId]: data[0] }));
    } catch (error) {
      console.error("Error fetching last services:", error);
    }
  };

  const fetchCalendars = async () => {
    try {
      const response = await axios.get(`${apiUrl}api/calendars/`);
      setCalendars(response.data);
    } catch (error) {
      console.error("Error fetching calendars:", error);
    }
  };

  useEffect(() => {
    if (bookingIds.length > 0) {
      fetchBookingDetails();
      fetchCalendars();
    }
  }, [bookingIds, apiUrl]);

  // Function to handle date and time conversion without timezone issues
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";

    return dateString.replace(" ", "T").substring(0, 16);
  };

  const handleFieldChange = async (bookingId, field, value) => {
    try {
      let formattedValue = value;

      if (field === "start" || field === "end") {
        formattedValue = value.replace("T", " ") + ":00";
      }

      // update booking details
      await axios.put(`${apiUrl}api/booking/${bookingId}/update/`, {
        [field]: formattedValue,
      });

      // update state
      setBookingDetails((prevDetails) =>
        prevDetails.map((booking) =>
          booking.id === bookingId
            ? {
                ...booking,
                [field]: formattedValue,
              }
            : booking
        )
      );

      fetchBookingDetails();

      console.log(`Booking ${field} updated successfully!`);
    } catch (error) {
      console.error(`Error updating booking ${field}:`, error);
    }
  };

  // fetch client package
  const [clientPackages, setClientPackages] = useState([]);

  useEffect(() => {
    const fetchClientPackages = async (clientId) => {
      try {
        const response = await axios.get(
          `${apiUrl}inventory/booked-package-detail/${clientId}/`
        );
        setClientPackages(response.data);
      } catch (error) {
        console.error("Error fetching client packages:", error);
      }
    };

    if (bookingDetails.length > 0 && bookingDetails[0].owner) {
      fetchClientPackages(bookingDetails[0].owner.id);
    }
  }, [bookingDetails, apiUrl]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  // fetch, add, delete selected services for each booking
  const fetchSelectedServices = async () => {
    try {
      const response = await fetch(`${apiUrl}api/selected-services/`);
      const data = await response.json();
      const servicesByBooking = data.reduce((acc, service) => {
        if (!acc[service.booking]) {
          acc[service.booking] = [];
        }
        acc[service.booking].push(service);
        return acc;
      }, {});
      setExistingServices(servicesByBooking);
    } catch (error) {
      console.error("Error fetching selected services:", error);
    }
  };

  useEffect(() => {
    fetchSelectedServices();
  }, [bookingIds]);

  const handleDelete = async (serviceId, bookingId) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      try {
        console.log(
          "Deleting serviceId:",
          serviceId,
          "from bookingId:",
          bookingId
        );

        await axios.delete(
          `${apiUrl}api/selected-services/delete/${serviceId}/`
        );

        setExistingServices((prevExistingServices) => {
          const updatedServices = (
            prevExistingServices[bookingId] || []
          ).filter((service) => service.id !== serviceId);

          // Debug log
          console.log(
            "Updated existingServices after deletion:",
            updatedServices
          );

          return {
            ...prevExistingServices,
            [bookingId]: updatedServices,
          };
        });

        setSelectedServices((prevSelectedServices) => {
          const updatedSelectedServices = (
            prevSelectedServices[bookingId] || []
          ).filter((service) => service.id !== serviceId);

          // Debug log
          console.log(
            "Updated selectedServices after deletion:",
            updatedSelectedServices
          );

          return {
            ...prevSelectedServices,
            [bookingId]: updatedSelectedServices,
          };
        });
      } catch (error) {
        console.error("Error deleting service", error);
      }
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim() === "") {
      setSearchResults([]);
    } else {
      searchServices(event.target.value);
    }
  };

  const searchServices = async (query) => {
    try {
      const response = await fetch(
        `${apiUrl}inventory/inventory-services/search/?query=${query}`
      );
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error searching for services:", error);
    }
  };

  const handleAddService = (bookingId, service) => {
    setSelectedServices((prevSelectedServices) => {
      const existingServices = prevSelectedServices[bookingId] || [];
      const serviceExists = existingServices.find((s) => s.id === service.id);

      if (serviceExists) {
        return {
          ...prevSelectedServices,
          [bookingId]: existingServices.map((s) =>
            s.id === service.id ? { ...s, quantity: s.quantity + 1 } : s
          ),
        };
      } else {
        return {
          ...prevSelectedServices,
          [bookingId]: [...existingServices, { ...service, quantity: 1 }],
        };
      }
    });
  };

  const handleQuantityChange = (bookingId, serviceId, quantity) => {
    setSelectedServices((prevSelectedServices) => {
      return {
        ...prevSelectedServices,
        [bookingId]: prevSelectedServices[bookingId].map((s) =>
          s.id === serviceId ? { ...s, quantity: quantity } : s
        ),
      };
    });

    setExistingServices((prevExistingServices) => {
      return {
        ...prevExistingServices,
        [bookingId]: (prevExistingServices[bookingId] || []).map((s) =>
          s.id === serviceId ? { ...s, quantity: quantity } : s
        ),
      };
    });
  };

  const handleRemoveService = (bookingId, serviceId) => {
    setSelectedServices((prevSelectedServices) => {
      const updatedSelectedServices = (
        prevSelectedServices[bookingId] || []
      ).filter((service) => service.id !== serviceId);

      // Debug log
      console.log(
        "Updated selectedServices after removal:",
        updatedSelectedServices
      );

      return {
        ...prevSelectedServices,
        [bookingId]: updatedSelectedServices,
      };
    });

    setExistingServices((prevExistingServices) => {
      const updatedExistingServices = (
        prevExistingServices[bookingId] || []
      ).filter((service) => service.id !== serviceId);

      // Debug log
      console.log(
        "Updated existingServices after removal:",
        updatedExistingServices
      );

      return {
        ...prevExistingServices,
        [bookingId]: updatedExistingServices,
      };
    });
  };

  const submitSelectedServices = async (bookingId) => {
    const servicesToSubmit = selectedServices[bookingId] || [];
    if (servicesToSubmit.length > 0) {
      try {
        await Promise.all(
          servicesToSubmit.map((service) =>
            fetch(`${apiUrl}api/selected-services/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                booking: bookingId,
                service: service.id,
                quantity: service.quantity,
              }),
            })
          )
        );

        // Refetch services after submission to ensure state synchronization
        fetchSelectedServices();

        setSelectedServices((prevSelectedServices) => ({
          ...prevSelectedServices,
          [bookingId]: [],
        }));
        setSearchResults([]);
        setSearchTerm("");
        alert("Services submitted successfully!");
      } catch (error) {
        console.error("Error submitting selected services:", error);
      }
    } else {
      console.error("No services to submit for booking:", bookingId);
    }
  };

  const toggleShowServices = (bookingId) => {
    setShowServices((prevShowServices) => ({
      ...prevShowServices,
      [bookingId]: !prevShowServices[bookingId],
    }));
  };

  // update pet guest status
  const toggleGuestStatus = async (bookingId, currentStatus) => {
    const newStatus =
      currentStatus === "Checked In" ? "Checked Out" : "Checked In";

    try {
      const response = await fetch(
        `${apiUrl}api/update-guest-status/${bookingId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ guest_status: newStatus }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setBookingDetails((prevState) =>
          prevState.map((booking) =>
            booking.id === bookingId
              ? {
                  ...booking,
                  guest_status: data.guest_status,
                  checkin_time: data.checkin_time,
                  checkout_time: data.checkout_time,
                  duration: data.duration,
                }
              : booking
          )
        );
      } else {
        console.error("Failed to update guest status:", data.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // update pet current status
  const updatePetStatus = async (petId, newStatus) => {
    try {
      const response = await fetch(`${apiUrl}api/pet/${petId}/update-status/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ booking_status: newStatus }),
      });
      if (!response.ok) {
        throw new Error("Failed to update pet status");
      }

      const updatedPet = await response.json();
      setBookingDetails((prevState) =>
        prevState.map((booking) =>
          booking.id === petId
            ? { ...booking, booking_status: updatedPet.booking_status }
            : booking
        )
      );
    } catch (error) {
      console.error("Error updating pet status:", error);
    }
  };

  const handleStatusChange = (event, petId) => {
    const newStatus = event.target.value;
    updatePetStatus(petId, newStatus);
  };

  // fetch and update client status
  const fetchClientStatus = async (ownerId) => {
    try {
      const response = await fetch(`${apiUrl}api/client/${ownerId}/`);
      if (!response.ok) throw new Error("Failed to fetch client status");
      const data = await response.json();
      setClientStatus(data.Status);
    } catch (error) {
      console.error("Error fetching client status:", error);
    }
  };

  const updateClientStatus = async (ownerId, status) => {
    try {
      const response = await fetch(
        `${apiUrl}api/client/${ownerId}/update-status/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Status: status }),
        }
      );
      if (!response.ok) throw new Error("Failed to update client status");
      setClientStatus(status);
    } catch (error) {
      console.error("Error updating client status:", error);
    }
  };

  useEffect(() => {
    if (bookingDetails && bookingDetails[0] && bookingDetails[0].owner) {
      fetchClientStatus(bookingDetails[0].owner.id);
    }
  }, [bookingDetails]);

  if (bookingDetails.length === 0) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const PET_STATUS = [
    ["Inbound", "Inbound"],
    ["Awaiting Service", "Awaiting Service"],
    ["Active", "Active"],
    ["Cancel", "Cancel"],
    ["Complete", "Complete"],
  ];

  const addToCart = async () => {
    try {
      let allSelectedServices = [];

      for (const bookingId of bookingIds) {
        const response = await fetch(
          `${apiUrl}api/selected-services/${bookingId}/`
        );
        const selectedServices = await response.json();
        const itemsToAdd = selectedServices.map(
          ({ id: selectedServiceId, service, quantity, owner_id }) => ({
            uniqueId: `service-${service.id}-booking-${bookingId}`,
            id: service.id,
            name: service.name,
            price: service.price,
            category: service.category,
            SelectedService: selectedServiceId,
            quantity: quantity,
            isService: true,
            isDirect: true,
            booking_id: bookingId,
            clientId: owner_id,
            isAppointment:
              bookingDetails.find((booking) => booking.id === bookingId)
                .booking_type !== "reservation",
          })
        );

        allSelectedServices = [...allSelectedServices, ...itemsToAdd];
      }

      dispatch({ type: "ADD_MULTIPLE_TO_CART", payload: allSelectedServices });
      window.location.href = `/point-of-sale`;
    } catch (error) {
      console.error("Error fetching selected services:", error);
    }
  };

  const showPOS = bookingDetails.some(
    (detail) =>
      (detail.booking_type === "appointment" &&
        detail.current_status !== "Complete") ||
      detail.booking_type === "reservation"
  );

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <div id="">
          <ul
            className="navbar-nav bg-gradient-primary sidebar-dark accordion px-4 py-4"
            id="accordionSidebar"
            style={{ width: "100%", height: "90vh" }}
          >
            <a className="sidebar-brand d-flex align-items-center justify-content-center">
              <div className="sidebar-brand-icon rotate-n-15">
                <i className="fas fa-laugh-wink"></i>
              </div>
            </a>
            <hr className="sidebar-divider my-0" />

            <li className="nav-item active">
              <a className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <FontAwesomeIcon icon={faChartBar} className="mr-4" />
                <Link
                  onClick={() => handleItemClick("Pets")}
                  style={{ cursor: "pointer" }}
                  className="text-decoration-none text-white"
                >
                  Pets
                </Link>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <FontAwesomeIcon icon={faChartBar} className="mr-4" />
                <Link
                  onClick={() => handleItemClick("Clients")}
                  style={{ cursor: "pointer" }}
                  className="text-decoration-none text-white"
                >
                  Client
                </Link>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <FontAwesomeIcon icon={faChartBar} className="mr-4" />
                <Link
                  onClick={() => handleItemClick("Details")}
                  style={{ cursor: "pointer" }}
                  className="text-decoration-none text-white"
                >
                  Details
                </Link>
              </a>
            </li>
            {showPOS && (
              <li className="nav-item active">
                <a className="nav-link">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <FontAwesomeIcon icon={faChartBar} className="mr-4" />
                  <Link
                    onClick={addToCart}
                    style={{ cursor: "pointer" }}
                    className="text-decoration-none text-white"
                  >
                    Point of Sale
                  </Link>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          {selectedItem === "Pets" && (
            <div>
              <h5
                className="mb-4"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "600",
                  color: "#282c34",
                  textAlign: "left",
                }}
              >
                Scheduled Pets
              </h5>

              {bookingDetails.map((booking, index) => (
                <div key={index}>
                  <img
                    src={
                      booking.petname.PetImage
                        ? `${apiUrl}${booking.petname.PetImage}`
                        : PetPlaceholder
                    }
                    alt="Pet"
                    style={{ height: "150px", width: "200px" }}
                  />

                  {booking.booking_type === "reservation" &&
                    booking.guest_status !== "Checked Out" && (
                      <button
                        onClick={() =>
                          toggleGuestStatus(booking.id, booking.guest_status)
                        }
                        className="mt-2"
                      >
                        {booking.guest_status === "Checked In"
                          ? "Check Out"
                          : "Check In"}
                      </button>
                    )}

                  <Table hover className="mt-4">
                    <tbody>
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Name
                        </td>
                        <td>
                          <Link to={`/pet-profile/${booking.petname.id}`}>
                            {booking.petname.PetName}
                          </Link>
                        </td>
                      </tr>
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Breed
                        </td>
                        <td>{booking.petname.Breed}</td>
                      </tr>
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Flags
                        </td>
                        <td>
                          {booking.petname.flag_names
                            ? booking.petname.flag_names
                            : "No Flags"}
                        </td>
                      </tr>
                      {booking.booking_type === "appointment" ? (
                        <tr
                          style={{
                            height: "50px",
                            borderBottom: "1px solid #dee2e6",
                          }}
                        >
                          <td
                            style={{ textAlign: "center", fontWeight: "700" }}
                          >
                            Current Status
                          </td>
                          <td>
                            <select
                              value={booking.booking_status}
                              onChange={(e) =>
                                handleStatusChange(e, booking.id)
                              }
                            >
                              {PET_STATUS.map(([value, label]) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ) : (
                        <tr
                          style={{
                            height: "50px",
                            borderBottom: "1px solid #dee2e6",
                          }}
                        >
                          <td
                            style={{ textAlign: "center", fontWeight: "700" }}
                          >
                            Guest Status
                          </td>
                          <td>{booking.guest_status}</td>
                        </tr>
                      )}
                      {booking.checkin_time && (
                        <tr
                          style={{
                            height: "50px",
                            borderBottom: "1px solid #dee2e6",
                          }}
                        >
                          <td
                            style={{ textAlign: "center", fontWeight: "700" }}
                          >
                            Check-in Time
                          </td>
                          <td>{formatDate(booking.checkin_time)}</td>
                        </tr>
                      )}
                      {booking.checkin_time && booking.checkout_time && (
                        <>
                          <tr
                            style={{
                              height: "50px",
                              borderBottom: "1px solid #dee2e6",
                            }}
                          >
                            <td
                              style={{ textAlign: "center", fontWeight: "700" }}
                            >
                              Check-out Time
                            </td>
                            <td>{formatDate(booking.checkout_time)}</td>
                          </tr>
                          <tr
                            style={{
                              height: "50px",
                              borderBottom: "1px solid #dee2e6",
                            }}
                          >
                            <td
                              style={{ textAlign: "center", fontWeight: "700" }}
                            >
                              Duration (Hours)
                            </td>
                            <td>{booking.duration.toFixed(2)}</td>
                          </tr>
                        </>
                      )}
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Last Service
                        </td>
                        <td>
                          {lastServices[booking.petname.id]
                            ? `${
                                lastServices[booking.petname.id].services[0]
                                  .name
                              } - ${formatDate(
                                lastServices[booking.petname.id]
                                  .last_service_date
                              )} - ${
                                lastServices[booking.petname.id].provided_by
                              }`
                            : "N/A"}
                        </td>
                      </tr>
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Services
                        </td>
                        <td>
                          {existingServices[booking.id] &&
                            existingServices[booking.id].map((service, idx) => (
                              <div key={idx}>
                                {`${
                                  service.service.name || service.service_name
                                } - ${service.quantity} qty`}
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  onClick={() =>
                                    handleDelete(service.id, booking.id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "15px",
                                  }}
                                />
                              </div>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div>
                    <button
                      onClick={() => toggleShowServices(booking.id)}
                      className="toggle-services-btn mt-2 mb-2"
                    >
                      {showServices[booking.id]
                        ? "Hide Services"
                        : "Add Services"}
                    </button>

                    {showServices[booking.id] && (
                      <div>
                        <input
                          type="text"
                          placeholder="Search Items"
                          value={searchTerm}
                          onChange={handleSearchTermChange}
                        />
                        <div>
                          {searchResults.map((service) => (
                            <div
                              key={service.id}
                              onClick={() =>
                                handleAddService(booking.id, service)
                              }
                              className="mt-2"
                              style={{ cursor: "pointer" }}
                            >
                              {service.name} - ${service.price}
                            </div>
                          ))}
                        </div>
                        {selectedServices[booking.id] &&
                          selectedServices[booking.id].map((service) => (
                            <div key={service.id} className="mt-2">
                              <span>{service.name}</span>
                              <input
                                type="number"
                                value={service.quantity}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    booking.id,
                                    service.id,
                                    parseInt(e.target.value) || 0
                                  )
                                }
                              />
                              <span>${service.price}</span>
                              <FontAwesomeIcon
                                icon={faTimes}
                                onClick={() =>
                                  handleRemoveService(booking.id, service.id)
                                }
                                className="remove-service-icon"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          ))}
                        <button
                          onClick={() => submitSelectedServices(booking.id)}
                          className="mt-4"
                        >
                          Submit Services
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {selectedItem === "Clients" && (
            <div>
              <h5
                className="mb-4"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "600",
                  color: "#282c34",
                  textAlign: "left",
                }}
              >
                Client Info
              </h5>
              <Table className="mt-4">
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center", fontWeight: "700" }}>
                      Client
                    </td>
                    <td>
                      <Link
                        to={`/client-profile/${bookingDetails[0].owner.id}`}
                      >
                        {bookingDetails[0].owner.FirstName}
                      </Link>
                    </td>
                  </tr>
                  {bookingDetails[0].owner.contacts &&
                    bookingDetails[0].owner.contacts.map((contact, index) => (
                      <React.Fragment key={index}>
                        <tr
                          style={{
                            height: "50px",
                            borderBottom: "1px solid #dee2e6",
                          }}
                        >
                          <td
                            style={{ textAlign: "center", fontWeight: "700" }}
                          >
                            Contact
                          </td>
                          <td>{contact.contact_value}</td>
                        </tr>
                        <tr
                          style={{
                            height: "50px",
                            borderBottom: "1px solid #dee2e6",
                          }}
                        >
                          <td
                            style={{ textAlign: "center", fontWeight: "700" }}
                          >
                            Preferred Contact
                          </td>
                          <td>{contact.preferred_contact}</td>
                        </tr>
                      </React.Fragment>
                    ))}

                  <tr
                    style={{
                      height: "50px",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    <td style={{ textAlign: "center", fontWeight: "700" }}>
                      Status
                    </td>
                    <td>
                      <select
                        value={clientStatus}
                        onChange={(e) => {
                          const newStatus = e.target.value;
                          setClientStatus(newStatus);
                          updateClientStatus(
                            bookingDetails[0].owner.id,
                            newStatus
                          );
                        }}
                      >
                        <option value="vip">VIP</option>
                        <option value="good">Good</option>
                        <option value="caution">Caution</option>
                        <option value="warning">Warning</option>
                        <option value="banned">Banned</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                  </tr>
                  {/* Display client packages */}
                  <tr
                    style={{
                      height: "50px",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    <td style={{ textAlign: "center", fontWeight: "700" }}>
                      Prepaid Package
                    </td>
                    <td>
                      {clientPackages.length > 0 ? (
                        <ul>
                          {clientPackages.map((pkg) => (
                            <li key={pkg.id}>
                              {pkg.service_name}: {pkg.remaining_services}{" "}
                              remaining
                            </li>
                          ))}
                        </ul>
                      ) : (
                        "No packages available"
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {selectedItem === "Details" && (
            <div>
              <h5
                className="mb-4"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "600",
                  color: "#282c34",
                  textAlign: "left",
                }}
              >
                Booking Details
              </h5>
              {bookingDetails.map((booking, index) => (
                <Table key={index} className="mt-4">
                  <tbody>
                    <tr
                      style={{
                        height: "50px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        Title
                      </td>
                      <td>{booking.title}</td>
                    </tr>
                    <tr
                      style={{
                        height: "50px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        Start Date
                      </td>
                      <td>
                        <input
                          type="datetime-local"
                          value={formatDateForInput(booking.start)}
                          onChange={(e) =>
                            handleFieldChange(
                              booking.id,
                              "start",
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        height: "50px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        End Date
                      </td>
                      <td>
                        <input
                          type="datetime-local"
                          value={formatDateForInput(booking.end)}
                          onChange={(e) =>
                            handleFieldChange(booking.id, "end", e.target.value)
                          }
                        />
                      </td>
                    </tr>

                    {booking.calendar && (
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Calendar Type
                        </td>
                        <td>
                          <select
                            value={booking.calendar.id || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                booking.id,
                                "calendar",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select Calendar</option>
                            {calendars.map((calendar) => (
                              <option key={calendar.id} value={calendar.id}>
                                {calendar.title} ({calendar.user_first_name})
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        Client
                      </td>
                      <td>
                        <Link to={`/client-profile/${booking.owner.id}`}>
                          {booking.owner.FirstName}
                        </Link>
                      </td>
                    </tr>

                    <tr
                      style={{
                        height: "50px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      <td style={{ textAlign: "center", fontWeight: "700" }}>
                        Pet
                      </td>
                      <td>
                        <Link to={`/pet-profile/${booking.petname.id}`}>
                          {booking.petname.PetName}
                        </Link>
                      </td>
                    </tr>

                    {booking.additional_details && (
                      <tr
                        style={{
                          height: "50px",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        <td style={{ textAlign: "center", fontWeight: "700" }}>
                          Additional Notes
                        </td>
                        <td>{booking.additional_details}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopBookingsDetail;
