import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MessageScreen.css';

const ChatConversation = ({ contact, onBack }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const fetchMessages = async () => {
    try {
        const response = await axios.get(`/messaging/messages/${contact.id}`);
        setMessages(response.data);
    } catch (error) {
        console.error("Error fetching messages:", error);
    }
  };

  const markMessagesAsRead = async () => {
    try {
      await axios.post(`/messaging/mark-as-read/${contact.id}/`);
    } catch (error) {
      console.error("Error marking messages as read:", error)
    }
  }

  useEffect(() => {
    fetchMessages();
    markMessagesAsRead(); // Mark messages as read when the conversation is opened

    const interval = setInterval(() => {
      fetchMessages();
    }, 10000);

    return () => clearInterval(interval);
  }, [contact.id]);

  const formatPhoneNumber = (number) => {
    // Remove all non-numeric characters and leading '+'
    const cleaned = number.replace(/\D/g, '');
    // Check if the number starts with '521', then reformat it
    if (cleaned.startsWith('521')) {
      return `+52${cleaned.slice(3)}`;
    } else if (cleaned.startsWith('52')) {
      return `+52${cleaned.slice(2)}`;
    } else {
      return `+52${cleaned}`;  // Add '+52' prefix if missing
    }
  };

  const sendMessage = () => {
    const formattedNumber = formatPhoneNumber(contact.id); // Format the number here

    console.log("Sending message to:", formattedNumber);  // Log the contact ID
    console.log("Message content:", newMessage);     // Log the message content

    axios.post('/messaging/send-message/', 
      new URLSearchParams({
        to: formattedNumber, 
        body: newMessage
      }).toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
    .then((response) => {
      const outboundMessage = {
        sender: 'Me',
        message: newMessage,
        profile_name: 'Me',
        timestamp: new Date().toISOString(),
        direction: 'outbound'
      };
        setMessages([...messages, outboundMessage]);
        setNewMessage('');
    })
    .catch((error) => {
      console.error("Error sending message:", error);
    });
  };

  return (
    <div className="chat-conversation">
      <button onClick={onBack} className="back-button">Back</button>
      <h2>{contact.name}</h2>
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.direction}`}>
            <span>{msg.message}</span>
          </div>
        ))}
      </div>
      <div className="input-bar">
        <button className="attach-btn">📎</button>
        <input
          type="text"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={sendMessage} className="send-btn">Send</button>
      </div>
    </div>
  );
};

export default ChatConversation;
