import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector, useDispatch } from "react-redux";

const BanksForm = ({
  currentItemId,
  setCurrentItemId,
  services,
  setServices,
  setShowModal,
}) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [serviceName, setServiceName] = useState("");

  // Find the current service details if editing
  useEffect(() => {
    if (currentItemId) {
      const currentService = services.find(
        (service) => service.id === currentItemId
      );
      if (currentService) {
        setServiceName(currentService.name);
      }
    }
  }, [currentItemId, services]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = { name: serviceName };

    if (currentItemId) {
      axios
        .put(`${apiUrl}inventory/banks/${currentItemId}/`, payload)
        .then((response) => {
          setServices(
            services.map((service) =>
              service.id === currentItemId ? response.data : service
            )
          );
          alert("Bank updated successfully.");
        })
        .catch((error) => console.error("Error updating bank", error));
    } else {
      axios
        .post(`${apiUrl}inventory/banks/`, payload)
        .then((response) => {
          setServices([...services, response.data]);
          alert("Bank added successfully.");
        })
        .catch((error) => console.error("Error adding bank", error));
    }
    // Reset form and close modal
    setServiceName("");
    setCurrentItemId(null);
    setShowModal(false);
  };

  return (
    <div className="row">
      <div className="col-md-12" style={{ paddingLeft: "0px" }}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
              <div className="row mb-4 mt-4">
                <input
                  type="text"
                  placeholder="Bank Name"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
              </div>
              <div className="row mt-4">
                <button type="submit">
                  {currentItemId ? "Update Bank" : "Add Bank"}
                </button>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BanksForm;