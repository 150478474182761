import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import "./StoreSalesPieChart.css";

const StoreSalesPieChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Sales",
        data: [],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  });

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/store-sales/`)
      .then((response) => {
        const storeNames = response.data.map((store) => store.ShopName);
        const storeSales = response.data.map((store) =>
          parseFloat(store.total_sales)
        );

        setChartData({
          labels: storeNames,
          datasets: [
            {
              label: "Total Sales",
              data: storeSales,
              backgroundColor: [
                "#8ef5f5",
                "#51c5c5",
                "#00a6a6",
                "#4BC0C0",
                "#9966FF",
                "#FF9F40",
              ],
            },
          ],
        });

        setSalesData(response.data);
      })
      .catch((error) => console.error("Error fetching sales data:", error));
  }, []);

  return (
    <>
      <div>
        <div className="card">
          <div className="card-body">
            <h4 className="mt-4 mb-4">Sales (Service + Products)</h4>
            <div className="chart-container">
              <Pie data={chartData} className="chart-data" />
            </div>
            <div className="chart-legend mt-4">
              {salesData.map((store, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <span
                    className="legend-color me-2"
                    style={{
                      backgroundColor:
                        chartData.datasets[0].backgroundColor[index],
                    }}
                  ></span>
                  <span className="me-2">{store.ShopName}</span>
                  <span>${store.total_sales != null ? store.total_sales.toLocaleString() : "0.00"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreSalesPieChart;
