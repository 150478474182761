import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import Sidebar from "../Design/ShopDetailsSidebar.js";
import Header from "../Header.js";
import { useSelector } from "react-redux";

const ShopDetail = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const shop = useSelector((state) => state.shop);

  const [shopDetails, setShopDetails] = useState(null);

  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}api/shop-detail/${shop.shopId}/`
        );
        const data = await response.json();
        setShopDetails(data);
      } catch (error) {
        console.error("Error fetching shop details:", error);
      }
    };

    fetchShopDetails();
  }, [shop.shopId]);

  if (!shopDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
          className="mb-4"
        >
          Shop Details
        </h3>

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <h5
            className="mb-4"
            style={{
              paddingBottom: "10px",
              fontWeight: "600",
              color: "#282c34",
              textAlign: "left",
            }}
          >
            Shop: {shop.shopName}
          </h5>
          {shopDetails.ShopImage && (
            <img
              src={`${apiUrl}${shopDetails.ShopImage}`}
              alt="Shop"
              style={{ height: "150px", width: "200px" }}
            />
          )}
          <Table className="mt-4">
            <tbody>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Prefixs
                </td>
                <td>{shopDetails.Prefixs}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Owner
                </td>
                <td>{shopDetails.OwnerFirstName}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Address
                </td>
                <td>{shopDetails.Address}</td>
              </tr>

              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Address Two
                </td>
                <td>{shopDetails.AddressTwo}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Postal Code
                </td>
                <td>{shopDetails.PostalCode}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>City</td>
                <td>{shopDetails.City}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  State/Country
                </td>
                <td>{shopDetails.StateCountry}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>Area</td>
                <td>{shopDetails.Area}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ShopDetail;
