import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import CashRegisterWithdrawModal from './CashRegisterWithdrawModal'; // Assuming this is the file path
import './CashRegisterForm.css';

const getCsrfToken = () => {
  return document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
};

const CashRegisterForm = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedStoreName, setSelectedStoreName] = useState(''); // Store name
  const [cashRegisterStatus, setCashRegisterStatus] = useState({ isOpen: false, openingCash: 0 });
  const [initializing, setInitializing] = useState(false);
  const [openingCash, setOpeningCash] = useState('');
  const [denominationsCount, setDenominationsCount] = useState({
    // Initial state based on the country's currency denominations
    '0.05': 0, '0.10': 0, '0.20': 0, '0.50': 0,
    '1': 0, '2': 0, '5': 0, '10': 0, '20': 0,
    '50': 0, '100': 0, '200': 0, '500': 0, '1000': 0,
  });
  const datetimeNow = moment().tz('America/Mexico_City');  // Replace 'Your_Timezone' with your desired timezone
  const formattedDate = datetimeNow.format('YYYY-MM-DD');
  const [showAdjustButtons, setShowAdjustButtons] = useState(false);
  const [expectedClosingCash, setExpectedClosingCash] = useState(0); // New state variable for expected closing cash
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const handleOpenWithdrawModal = () => {
    setShowWithdrawModal(true);
  };

  const handleCloseWithdrawModal = () => {
    setShowWithdrawModal(false);
  };

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Fetch stores
    axios.get(`${apiUrl}inventory/stores/`)
        .then(response => setStores(response.data))
        .catch(error => console.error('Error fetching stores:', error));
  }, []);

  useEffect(() => {
    calculateTotalCash();
  }, [denominationsCount]);

  useEffect(() => {
    if (selectedStore && cashRegisterStatus.isOpen) {
      fetchSalesAndExpenses(selectedStore);
    }
  }, [selectedStore, cashRegisterStatus]);

  useEffect(() => {
    const selected = stores.find(store => store.id === selectedStore);
    setSelectedStoreName(selected ? selected.name : '');
  }, [selectedStore, stores]);

  const resetForm = () => {
    setSelectedStore('');
    setSelectedStoreName('');
    setOpeningCash('');
    setDenominationsCount({
      '0.05': 0, '0.10': 0, '0.20': 0, '0.50': 0,
      '1': 0, '2': 0, '5': 0, '10': 0, '20': 0,
      '50': 0, '100': 0, '200': 0, '500': 0, '1000': 0,
    });
  };

  const handleDenominationChange = (denomination, value) => {
    setDenominationsCount({
      ...denominationsCount,
        [denomination]: value});
  };

  const calculateTotalCash = () => {
    const total = Object.entries(denominationsCount).reduce(
        (acc, [denomination, count]) => acc + (parseFloat(denomination) * count), 0
    );
    setOpeningCash(total.toFixed(2));
  };

  const handleStoreChange = (e) => {
    setSelectedStore(e.target.value);
    checkCashRegisterStatus(e.target.value);
  };

  const checkCashRegisterStatus = (storeId) => {
    axios.get(`${apiUrl}inventory/store_cashregister/status/${storeId}/`)
        .then(response => {
            setCashRegisterStatus(response.data);
            setInitializing(!response.data.isOpen);
            if (response.data.isOpen) {
              fetchSalesAndExpenses(storeId, response.data.date);
            }
        })
        .catch(error => {
            console.error('Error fetching cash register status:', error);
            setInitializing(true);
        });
  };

  const handleInitializeRegister = () => {
    const csrfToken = getCsrfToken();  // Fetch CSRF token
    const currentTime = moment().format('HH:mm:ss'); // Format current time as HH:MM:SS
    axios.post(`${apiUrl}inventory/store_cashregister/create/${selectedStore}/`, { 
      opening_cash: openingCash,
      store: selectedStore, // Include store ID in the request body
      date: formattedDate,  
      opening_time: currentTime,
      }, {
      headers: {
        'X-CSRFToken': csrfToken  // Include CSRF token in request header
      }
    })
    .then(response => {
      setCashRegisterStatus({ isOpen: true, openingCash: response.data.opening_cash });
      setInitializing(false);
      resetForm();
    })
      .catch(error => console.error('Error initializing cash register:', error));
  };

  const handleCloseRegister = () => {
    const csrfToken = getCsrfToken();
    const currentTime = moment().format('HH:mm:ss'); // Format current time as HH:MM:SS
    axios.post(`${apiUrl}inventory/store_cashregister/close/${selectedStore}/`, {
      closing_cash: openingCash,
      closing_time: currentTime
    }, {
        headers: {
            'X-CSRFToken': csrfToken
        }
    })
    .then(() => {
      setCashRegisterStatus({ ...cashRegisterStatus, isOpen: false });
      resetForm();
    })
    .catch(error => console.error('Error closing cash register:', error));
  };

  const handleToggleRegister = () => {
    if (cashRegisterStatus.isOpen) {
      handleCloseRegister();
    } else {
      handleInitializeRegister();
    }
  };

  const fetchSalesAndExpenses = (storeId, registerDate) => {
    axios.get(`${apiUrl}inventory/cash-movements/${storeId}/`)
    .then(response => {
      const movements = response.data;
      const deposits = movements.filter(m => m.movement_type === 'deposit')
                              .reduce((sum, m) => sum + parseFloat(m.amount), 0);
      const withdrawals = movements.filter(m => m.movement_type === 'withdraw')
                                 .reduce((sum, m) => sum + parseFloat(m.amount), 0);
      console.log(`Deposits: ${deposits}, Withdrawals: ${withdrawals}`);                           
      const expectedClosing = parseFloat(cashRegisterStatus.openingCash) + deposits - withdrawals;
      setExpectedClosingCash(expectedClosing);
      setShowAdjustButtons(openingCash !== expectedClosing.toFixed(2));
    })
    .catch(error => console.error('Error fetching cash movements:', error));
  };

  const submitPayment = (poNumber, paymentDetails) => {
    const csrfToken = getCsrfToken();
    return new Promise((resolve, reject) => {
        axios.put(`${apiUrl}inventory/purchase-orders/update-payment/${poNumber}/`, paymentDetails, {
          headers: {
            'X-CSRFToken': csrfToken  // Include CSRF token in request header
          }
        })
            .then(response => {
                resolve(); // Resolve the promise on successful update
            })
            .catch(error => {
                console.error('Error updating payment:', error);
                reject(); // Reject the promise on error
            });
    });
  };

  return (
      <div className="cash-register-form">
          <h2>{cashRegisterStatus.isOpen ? `Closing Cash Register - ${selectedStoreName}` : `Opening Cash Register - ${selectedStoreName}`}</h2>
          <select onChange={handleStoreChange} value={selectedStore}>
              <option value="">Select a Store</option>
              {stores.map(store => (
                  <option key={store.id} value={store.id}>{store.name}</option>
              ))}
          </select>
          <div className="denominations-grid">
            {Object.keys(denominationsCount).map((denomination) => (
              <div key={denomination} className="denomination-input">
                <label>{`${denomination} x `}</label>
                <input
                  type="number"
                  value={denominationsCount[denomination]}
                  onChange={(e) => handleDenominationChange(denomination, e.target.value)}
                  min="0"
                />
              </div>
            ))}
            <p className="total-amount"><strong>Total Cash Amount: ${openingCash}</strong></p>
            <p className="total-amount"><strong>Expected Closing Cash: ${expectedClosingCash.toFixed(2)}</strong></p>
          </div>
          
          <div>
            <button onClick={handleToggleRegister} disabled={openingCash !== expectedClosingCash.toFixed(2)}>
              {cashRegisterStatus.isOpen ? 'Close Register' : 'Open Register'}
            </button>
            <button onClick={handleInitializeRegister}>On click</button>
            {showAdjustButtons && (
              <div>
                <button onClick={handleOpenWithdrawModal}>Withdraw Money</button>
                <button >Deposit Money</button>
              </div>
            )}
          </div>
          {showWithdrawModal && 
            <CashRegisterWithdrawModal
              onClose={handleCloseWithdrawModal} 
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              stores={stores}
              submitPayment={submitPayment}
            />}
      </div>
  );
};

export default CashRegisterForm;
