import React from "react";
import { Table } from "react-bootstrap";

const ExpenseDetail = ({ expenseDetail }) => {
  if (!expenseDetail || !expenseDetail.details || expenseDetail.details.length === 0) return <div>No data available</div>;

  // Function to format currency in Mexican Pesos
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
  };

  return (
    <div>
      <h4>Expense Detail</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Category</th>
            <th>Subcategory</th>
            <th>Is COGS</th>
            <th>Is Fixed</th>
            <th>Payment Method</th>
          </tr>
        </thead>
        <tbody>
          {expenseDetail.details.map((expense, index) => (
            <tr key={index}>
              <td>{expense.due_date || expense.due_date}</td>
              <td>{formatCurrency(expense.amount)}</td>
              <td>{expense.subcategory__category__name || "N/A"}</td>
              <td>{expense.subcategory__name || "N/A"}</td>
              <td>{expense.is_cogs ? "Yes" : "No"}</td>
              <td>{expense.is_fixed ? "Yes" : "No"}</td>
              <td>{expense.payment_method}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ExpenseDetail;