import React, { useEffect, useState } from "react";

class CustomToolbar extends React.Component {
    navigate = (action) => {
      this.props.onNavigate(action);
    };
  
    viewChange = (view) => {
      this.props.onView(view);
    };
  
    render() {
      return (
        <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            
            <button type="button" onClick={() => this.navigate('PREV')}>Back</button>
            <button type="button" onClick={() => this.navigate('TODAY')}>Today</button>
            <button type="button" onClick={() => this.navigate('NEXT')}>Next</button>
          </span>
  
          <span className="rbc-toolbar-label">{this.props.label}</span>
  
          <span className="rbc-btn-group">
            {this.props.views.map(view =>
              <button type="button" key={view} onClick={() => this.viewChange(view)}>
                {view}
              </button>
            )}
          </span>
        </div>
      );
    }
  }

  // For a default export
export default CustomToolbar;
