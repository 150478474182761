import React, { useState, useEffect, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";

const InactiveClients = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);
  const reportRef = useRef();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/inactive-clients/`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleUserClick = (clientId) => {
    navigate(`/client-profile/${clientId}`);
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Last Booking", key: "last_booking_date" },
  ];

  const csvData = users.map((user) => ({
    first_name: user.first_name,
    last_name: user.last_name,
    last_booking_date: new Date(user.last_booking_date)
      .toISOString()
      .split("T")[0],
  }));

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <CSVLink
          data={csvData}
          headers={headers}
          filename={"users-report.csv"}
          className="btn btn-primary me-2"
        >
          Export to CSV
        </CSVLink>
        <Button className="btn btn-secondary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={reportRef}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Last Booking</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{formatDate(user.last_booking_date)}</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => handleUserClick(user.id)}
                  >
                    View profile
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default InactiveClients;
