import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../actions/cartActions";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import PrepaidPackageForm from "./PrepaidPackageForm";

const PrepaidPackages = () => {
  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (pkg) => {
    setSelectedPackage(pkg || null);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const refreshPackages = () => {
    axios
      .get(`${apiUrl}inventory/prepaid-packages/`)
      .then((response) => {
        setPackages(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the prepaid packages!",
          error
        );
      });
  };

  useEffect(() => {
    refreshPackages();
  }, []);

  const handleAddToCart = (pkg) => {
    const cartItem = {
      uniqueId: `service-${pkg.id}`,
      id: pkg.service.id,
      pkgId: pkg.id,
      name: pkg.service.name,
      price: pkg.service.price,
      quantity: pkg.no_of_services,
      discount: pkg.discount || 0,
      isService: true,
      isPrepaid: true,
    };

    dispatch(addToCart(cartItem));
    window.location.href = `/point-of-sale`;
  };

  const handleDeletePackage = (pkgId) => {
    if (window.confirm("Are you sure you want to delete this package?")) {
      axios
        .delete(`${apiUrl}inventory/prepaid-packages/${pkgId}/`)
        .then(() => {
          setPackages(packages.filter((pkg) => pkg.id !== pkgId));
        })
        .catch((error) => {
          console.error("There was an error deleting the package!", error);
        });
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Prepaid Packages
        </h3>

        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
            Add New Package
          </Button>
        </div>
        <div>
          <div className="d-flex flex-wrap">
            {packages.map((pkg) => (
              <Card key={pkg.id} style={{ width: "18rem", margin: "1rem" }}>
                <Card.Body>
                  <Card.Title>{pkg.service.name}</Card.Title>
                  <Card.Text>
                    Number of Services: {pkg.no_of_services}
                    <br />
                    Discount: {pkg.discount}%<br />
                    Price: ${pkg.service.price}
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => handleAddToCart(pkg)}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    variant="info"
                    onClick={() => handleOpenModal(pkg)}
                    style={{ marginLeft: "10px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeletePackage(pkg.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedPackage ? "Edit" : "Add"} Prepaid Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrepaidPackageForm
            packageData={selectedPackage}
            handleClose={handleCloseModal}
            refreshPackages={refreshPackages}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PrepaidPackages;
