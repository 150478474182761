import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "../Design/Sidebar.js";
import Header from "../Header.js";
import { useSelector } from 'react-redux';
import PetPlaceholder from '../../Images/dog_placeholder.avif'

const PetProfile = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId
  const shopName = shop.shopName

  //const { shopName } = useParams();
  const { id } = useParams();

  const [shopDetails, setShopDetails] = useState(null);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}api/pet-detail/${id}/`
        );
        const data = await response.json();
        setShopDetails(data);
      } catch (error) {
        console.error("Error fetching shop details:", error);
      }
    };

    fetchShopDetails();
  }, [id]);

  if (!shopDetails) {
    return <div>Loading...</div>;
  }

  // Function to format the birth date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="row">
      
      <div className="col-md-3" style={{paddingRight:'0px'}}>
        <Sidebar userId={shopDetails.Owner}/>
      </div>
      <div className="col-md-9" style={{paddingLeft:'0px'}}>
      <Header />
      <div className="row px-4">
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          {shopDetails.PetName}'s Pet Profile
        </h3>
        <Link to={`/edit-pet/${id}/`}>
                  <button className="ml-4">Edit Pet</button></Link>
        <div className="col-4 mt-4">


          {shopDetails && (
            <img
              src={shopDetails.PetImage ? `${apiUrl}${shopDetails.PetImage}` : PetPlaceholder}
              alt="Pet"
              style={{ height: "150px", width: "200px" }}
            />
          )}

        </div>


        <div className="col-8">
          <Table className="mt-4">
            <tbody>
            <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Pet Owner
                </td>
                <td>{shopDetails.OwnerDetails.FirstName}
                <Link to={`/client-profile/${shopDetails.OwnerDetails.id}`}>
                <button className="ml-4">View Owner Details</button></Link>
                </td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Pet Name
                </td>
                <td>{shopDetails.PetName}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>Type</td>
                <td>{shopDetails.PetType}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Breed
                </td>
                <td>{shopDetails.Breed}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Gender
                </td>
                <td>{shopDetails.Gender}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Weight
                </td>
                <td>{shopDetails.Weight}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Coat Color
                </td>
                <td>{shopDetails.CoatColor}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Birthday
                </td>
                <td>{formatDate(shopDetails.BirthDate)}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Vaccinations
                </td>
                <td>{shopDetails.VaccinationRecord}</td>
              </tr>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <td style={{ textAlign: "center", fontWeight: "700" }}>
                  Additional Notes
                </td>
                <td>{shopDetails.AdditionalNote}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PetProfile;
