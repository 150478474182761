import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';


const BankReconciliationForm = () => {
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));    
    const [selectedBankAccount, setSelectedBankAccount] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);
    const [estimatedOpeningBalance, setEstimatedOpeningBalance] = useState(0);
    const [actualOpeningBalance, setActualOpeningBalance] = useState(0);
    const [estimatedClosingBalance, setEstimatedClosingBalance] = useState(0);
    const [actualClosingBalance, setActualClosingBalance] = useState(0);

    // Additional state variables as needed

    useEffect(() => {
        const fetchBankAccounts = async () => {
            try {
                const response = await axios.get(`${apiUrl}inventory/accounts/bank/`);
                setBankAccounts(response.data);
            } catch (error) {
                console.error('Error fetching bank accounts:', error);
            }
        };
        fetchBankAccounts();
    }, []);

    const calculateEstimates = async (date, bankAccountId) => {
        try {
            // Fetch transactions for the selected date and bank account
            const response = await axios.get(`${apiUrl}inventory/transactions/bank/date/${bankAccountId}/${date}/`);
            const transactions = response.data.transactions;
   
            // Initialize variables
            let yesterdaysSales = 0; // You need to fetch this from somewhere or calculate based on previous day's data
            let todaysWithdrawals = 0;
            let todaysSales = 0;
    
            // Iterate through transactions to sum sales and withdrawals
            transactions.forEach(transaction => {
                if (transaction.transaction_type === 'Withdrawal') {
                    todaysWithdrawals += parseFloat(transaction.amount);
                } else if (transaction.transaction_type === 'Deposit') {
                    todaysSales += parseFloat(transaction.amount);
                }
            });
    
            // Calculate today's estimated opening balance
            const todaysEstimatedOpeningBalance = yesterdaysSales; // Assuming yesterday's closing balance is the same as yesterday's sales
    
            // Calculate today's closing balance
            const todaysClosingBalance = todaysEstimatedOpeningBalance - todaysWithdrawals;
    
            // Calculate tomorrow's estimated opening balance
            const tomorrowsEstimatedOpeningBalance = todaysClosingBalance + todaysSales;
    
            return {
                estimatedOpening: todaysEstimatedOpeningBalance,
                estimatedClosing: todaysClosingBalance,
                tomorrowEstimatedOpening: tomorrowsEstimatedOpeningBalance
            };
        } catch (error) {
            console.error('Error calculating estimates:', error);
            throw error;
        }
    };

    const submitReconciliation = async (reconciliationData) => {
        try {
            const response = await axios.post(`${apiUrl}inventory/reconciliations/bank/`, reconciliationData);
            return response.data;
        } catch (error) {
            console.error('Error submitting reconciliation:', error);
            throw error;
        }
    };

    useEffect(() => {
        // Calculate estimates whenever the date or bank account changes
        const fetchEstimates = async () => {
            const estimates = await calculateEstimates(selectedDate, selectedBankAccount);
            setEstimatedOpeningBalance(estimates.estimatedOpening);
            setEstimatedClosingBalance(estimates.estimatedClosing);
        };
        if (selectedBankAccount) {
            fetchEstimates();
        }
    }, [selectedDate, selectedBankAccount]);

    const handleDateChange = (event) => {
        const formattedDate = moment(event.target.value).format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
    };

    const handleBankAccountChange = (event) => {
        setSelectedBankAccount(event.target.value);
    };

    const handleActualOpeningBalanceChange = (event) => {
        setActualOpeningBalance(event.target.value);
    };

    const handleActualClosingBalanceChange = (event) => {
        setActualClosingBalance(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await submitReconciliation({
            date: selectedDate,
            bankAccountId: selectedBankAccount,
            actualOpeningBalance,
            actualClosingBalance
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Date:
                <input type="date" value={selectedDate} onChange={handleDateChange} />
            </label>
            <label>
                Bank Account:
                <select value={selectedBankAccount} onChange={handleBankAccountChange}>
                    <option>Select a Bank Account</option>
                    {bankAccounts.map(account => (
                        <option key={account.id} value={account.id}>{account.bank_name} - {account.account_number}</option>
                    ))}
                </select>
            </label>
            <label>
                Estimated Opening Balance:
                <input type="number" value={estimatedOpeningBalance} readOnly />
            </label>
            <label>
                Actual Opening Balance:
                <input type="number" value={actualOpeningBalance} onChange={handleActualOpeningBalanceChange} />
            </label>
            <label>
                Estimated Closing Balance:
                <input type="number" value={estimatedClosingBalance} readOnly />
            </label>
            <label>
                Actual Closing Balance:
                <input type="number" value={actualClosingBalance} onChange={handleActualClosingBalanceChange} />
            </label>
            <button type="submit">Submit</button>
        </form>
    );
};

export default BankReconciliationForm;