import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
//import Sidebar from "../Design/ClientCenterSidebar.js";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import ExpenseCategoriesForm from "./ExpenseCategoriesForm.js";
import ExpenseSubCategoryForm from "./ExpenseSubCategoryForm.js";
import { Modal, Button } from "react-bootstrap";

const ExpenseCategories = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const { shopName } = useParams();
  const { id } = useParams();
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subcategories, setSubcategories] = useState([]);

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSubModal, setShowSubModal] = useState(false);

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleOpenSubModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowSubModal(true);
  };

  const handleCloseSubModal = () => setShowSubModal(false);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const categoriesResponse = await fetch(
          `${apiUrl}inventory/expenses/categories-filter/`
        );
        const categoriesData = await categoriesResponse.json();
        setInventory(categoriesData);

        const subcategoriesResponse = await fetch(
          `${apiUrl}inventory/expenses/subcategories-all/`
        );
        const subcategoriesData = await subcategoriesResponse.json();
        setSubcategories(subcategoriesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, [apiUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await axios.delete(
          `${apiUrl}inventory/expenses/categories-filter/${categoryId}/`
        );
        setInventory(inventory.filter((item) => item.id !== categoryId));
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  const handleDeleteSubCategory = async (subcategoryId) => {
    if (window.confirm("Are you sure you want to delete this subcategory?")) {
      try {
        await axios.delete(
          `${apiUrl}inventory/expenses/subcategories-all/${subcategoryId}/`
        );
        setSubcategories(
          subcategories.filter((sub) => sub.id !== subcategoryId)
        );
      } catch (error) {
        console.error("Error deleting subcategory:", error);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Expense Categories
        </h3>

        <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
          Add New Category
        </Button>
        <Button onClick={() => handleOpenSubModal()} className="mt-2 mb-4">
          Add New Sub Category
        </Button>

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Type</th>
                <th>Category Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    height: "50px",
                    borderBottom: "1px solid #dee2e6",
                    cursor: "pointer",
                  }}
                >
                  <td style={{ textAlign: "center" }}>
                    {item.type ? `${item.type}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item.name ? `${item.name}` : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteCategory(item.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <h4 style={{ paddingTop: "20px", fontWeight: "700", color: "#282c34" }}>
          Sub Categories
        </h4>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Category</th>
                <th>Subcategory Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subcategories.map((sub, index) => (
                <tr
                  key={index}
                  style={{
                    height: "50px",
                    borderBottom: "1px solid #dee2e6",
                    cursor: "pointer",
                  }}
                >
                  <td style={{ textAlign: "center" }}>{sub.category_name}</td>
                  <td style={{ textAlign: "center" }}>{sub.name}</td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteSubCategory(sub.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExpenseCategoriesForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubModal}
        onHide={handleCloseSubModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExpenseSubCategoryForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSubModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExpenseCategories;
