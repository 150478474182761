import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button } from "react-bootstrap";

function Cards() {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState("");

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/sales-cards/`)
      .then((response) => {
        setData(response.data.sale_data);
      })
      .catch((error) => {
        console.error("There was an error fetching the psales data!", error);
      });
  }, []);

  return (
    <>
      <div className="mb-3">
        {data.first_sale_date && data.last_sale_date ? (
          <>
            <span>
              {" "}
              From: {new Date(data.first_sale_date).toLocaleDateString()}{" "}
            </span>
            <span>
              {" "}
              To: {new Date(data.last_sale_date).toLocaleDateString()}{" "}
            </span>
          </>
        ) : (
          "No data available"
        )}
      </div>
      <div className="d-flex justify-content-center">
        <Card style={{ width: "18rem", marginRight: "1rem" }}>
          <Card.Body>
            <Card.Title>Total Revenue</Card.Title>
            <Card.Text>
              ${data.revenue != null ? data.revenue.toFixed(2) : "0.00"}
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", marginRight: "1rem" }}>
          <Card.Body>
            <Card.Title>Total Profit</Card.Title>
            <Card.Text>
              ${data.profit != null ? data.profit.toFixed(2) : "0.00"}
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", marginRight: "1rem" }}>
          <Card.Body>
            <Card.Title>Profit Margin</Card.Title>
            <Card.Text>
              {data.profit_margin != null ? data.profit_margin.toFixed(2) : "0"}
              %
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>Cost</Card.Title>
            <Card.Text>
              ${data.cost != null ? data.cost.toFixed(2) : "0.00"}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default Cards;
