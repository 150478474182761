import React, { useEffect, useState } from "react";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Table } from "react-bootstrap";


function ReorderInventory() {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [inventoryItems, setInventoryItems] = useState([]);

  const fetchInventoryData = async () => {
    try {
      const response = await fetch(`${apiUrl}inventory/reorder-items/`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setInventoryItems(data);
    } catch (error) {
      console.error("Could not fetch inventory data:", error);
    }
  };

  useEffect(() => {
    fetchInventoryData();
  }, []); 

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Reorder Inventory List
        </h3>
    <Table striped bordered hover className="mt-4" style={{marginLeft:"5px", marginRight:"10px"}}>
      <thead>
        <tr>
          <th>Brand Name</th>
          <th>Product Name</th>
          <th>Description</th>
          <th>Quantity Stock</th>
          <th>Reorder Point</th>
          <th>SKU</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {inventoryItems.map((item) => (
          <tr key={item.id}>
            <td>{item.brandName}</td>
            <td>{item.productName}</td>
            <td>{item.description}</td>
            <td>{item.quantityStock}</td>
            <td>{item.reorder_point}</td>
            <td>{item.sku}</td>
            <td>${item.price}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
    </div>
  );
}

export default ReorderInventory;
