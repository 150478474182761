import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const PayrollReport = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
        console.log(">>>>>>>", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {};
    if (startDate) data.start_date = startDate;
    if (endDate) data.end_date = endDate;
    if (selectedUser && selectedUser !== "all") data.shop = selectedUser;

    if (Object.keys(data).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}payroll/commissions-report/`, data);
      setResult(response.data);
      console.log("sales response : ", response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
    {result && (
        <Table striped bordered hover>
          <thead>
            <tr>
            <th>Employee</th>
            <th>Service</th>
            <th>Sale</th>
            <th>Commission</th>
            <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {result.map((commission) => (
              <tr key={commission.id}>
                <td>{commission.employee}</td>
                <td>{commission.service}</td>
                <td>{commission.sale}</td>
                <td>${commission.commission}</td>
                <td>{new Date(commission.date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default PayrollReport;
