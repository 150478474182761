import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

const EmployeePayrollForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [payroll, setPayroll] = useState({
    employee_id: "",
    salary: "",
    commission: false,
    commission_percentage: "",
    date: "",
  });
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    loadUsers();
    if (id) {
      loadEmployeePayroll();
    }
  }, [id]);

  const loadUsers = async () => {
    const result = await axios.get(`${apiUrl}payroll/users/`);
    setUsers(result.data);
  };

  const loadEmployeePayroll = async () => {
    const result = await axios.get(`${apiUrl}payroll/employee-payroll/${id}/`);
    setPayroll({
        ...result.data,
        employee_id: result.data.employee.id, // Set employee_id for editing
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPayroll({
      ...payroll,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await axios.put(`${apiUrl}payroll/employee-payroll/${id}/`, payroll);
    } else {
      await axios.post(`${apiUrl}payroll/employee-payroll/`, payroll);
    }
    navigate("/employee-payroll");
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          {id ? "Edit" : "Add"} Employee Payroll
        </h3>

        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmployee">
          <Form.Label>Employee</Form.Label>
          <Form.Control
            as="select"
            name="employee_id"
            value={payroll.employee_id}
            onChange={handleChange}
          >
            <option value="">Select Employee</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formSalary">
          <Form.Label>Salary</Form.Label>
          <Form.Control
            type="number"
            name="salary"
            value={payroll.salary}
            onChange={handleChange}
            placeholder="Enter salary"
          />
        </Form.Group>

        <Form.Group controlId="formCommission" className="d-flex align-items-center">
          <Form.Check
            type="checkbox"
            name="commission"
            checked={payroll.commission}
            onChange={handleChange}
            label="Commission"
          />
        </Form.Group>

        <Form.Group controlId="formCommissionPercentage">
          <Form.Label>Commission Percentage</Form.Label>
          <Form.Control
            type="number"
            name="commission_percentage"
            value={payroll.commission_percentage}
            onChange={handleChange}
            placeholder="Enter commission percentage"
          />
        </Form.Group>

        <Form.Group controlId="formDate">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            name="date"
            value={payroll.date}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      </div>
    </div>
  );
};

export default EmployeePayrollForm;
