/* REDUX */
import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
} from "./reducers/userReducers";

import cartReducer from './reducers/cartReducer';
import shopReducer from './reducers/shopSlice';


/* COMBINED REDUCER */
const reducer = combineReducers({

  userLogin: userLoginReducer,
  cart: cartReducer,
  shop: shopReducer,
});

/* PULLING DATA OUT OF LOCAL STORAGE AND LOAD IT INTO INITIAL STATE */

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

  const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

/* INITIAL STATE */
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  cart: {
    selectedItems: cartItemsFromStorage,
  },
};

// Middleware to persist cart state changes to localStorage
const cartPersistMiddleware = store => next => action => {
  const result = next(action);
  if (action.type === 'ADD_TO_CART' || action.type === 'REMOVE_FROM_CART' || action.type === 'ADD_MULTIPLE_TO_CART') {
    const newState = store.getState();
    localStorage.setItem('cartItems', JSON.stringify(newState.cart.selectedItems));
  }

  return result;
};


const middleware = [thunk, cartPersistMiddleware];

/* REDUX STORE */
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
