import React from 'react';
import { useState } from "react";
import "./App.css";
import "./components/Design/sb-admin-2.min.css";
import "./components/Design/dataTables.bootstrap4.min.css"
import "./components/Design/bootstrap.bundle.min.js";
import "./components/Design/jquery.easing.min.js";
import "./components/Design/jquery.min.js";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ExpenseDashboard from "./components/ExpenseDashboard/ExpenseDashboard";
import ExpenseForm from "./components/Expenses/ExpenseForm";
import ExpenseCategories from "./components/Expenses/ExpenseCategories";
import CashRegisterForm from "./components/CashRegister/CashRegisterForm";
import SalesForm from "./components/Sales/SalesForm";
import VendorsForm from "./components/Vendors/VendorsForm";
import InventoryForm from "./components/Vendors/InventoryForm";
import InventoryMain from "./components/Vendors/inventoryMain"; 
import ReorderInventory from "./components/Vendors/ReorderInventory"; 
import PromotionsMain from "./components/Inventory/Promotions";
import PromoGroups from "./components/Inventory/PromoGroups";
import EditPromoGroup from "./components/Inventory/EditPromoGroup";
import PurchaseOrderForm from "./components/Vendors/PurchaseOrderForm";
import AllServices from "./components/Services/AllServices";
import POS from "./components/POS/pos.js";
import ClientForm from "./components/Clients/ClientsForm.js";
import EmployeeForm from "./components/Employee/EmployeeForm.js";
import BankReconciliationForm from "./components/Banks/BankReconciliationForm.js";
import BankReconciliation from "./components/Banks/BankReconciliation.js";
import Banks from "./components/Banks/Banks.js";
import BankAccounts from "./components/Banks/BankAccounts.js";
import CalendarList from "./components/Calendar/CalendarList.js";
import ShopList from "./components/Calendar/ShopList.js";
import AddBreedList from "./components/Calendar/BreedList.js";
import AddUser from "./components/BookingCalender/add_user.js";
import BookingList from "./components/BookingCalender/showbooking.js";
import BookingsShop from "./components/BookingCalender/shopBookings.js";
import ShopDetailList from "./components/BookingCalender/AddBooking.js";
import ShopDetail from "./components/BookingCalender/shopDetail.js";
import ShopClients from "./components/BookingCalender/shopClients.js";
import ShopAddClient from "./components/BookingCalender/shopAddClient.js";
import ShopEditClient from "./components/BookingCalender/shopEditClient.js";
import ShopEditPet from "./components/BookingCalender/shopEditPet.js";
import ShopAddPet from "./components/BookingCalender/shopAddPet.js";
import Booking from "./components/BookingCalender/Booking.js";
import ShopBookingsDetail from "./components/BookingCalender/shopBookingsDetail.js";
import Reservation from "./components/BookingCalender/Reservation.js";
import ReservationCalender from "./components/BookingCalender/ReservationCalender.js";
import AppointmentCalender from "./components/BookingCalender/AppointmentCalender.js";

import ClientLeads from "./components/BookingCalender/ClientLeads.js";
import FirstTimeClients from "./components/BookingCalender/FirstTimeClients.js";
import PolicySignature from "./components/BookingCalender/PolicySignature.js";

import ClientProfile from "./components/BookingCalender/clientProfile.js";
import PetProfile from "./components/BookingCalender/petProfile.js";
import BreedList from "./components/BookingCalender/BreedList.js";
import FlagsTable from "./components/BookingCalender/FlagsList.js";

import EmployeeCenter from "./components/Employee/EmployeeCenter.js";
import EmployeeDetail from "./components/Employee/EmployeeDetail.js";
import EmployeeEdit from "./components/Employee/EmployeeEdit.js";
import MyProfile from "./components/Employee/MyProfile.js";
import EmployeeWorkTime from "./components/Employee/EmployeeWorkTime.js";
import EditEmployeeWorkTime from "./components/Employee/EditEmployeeWorkTime.js";
import AddEmployeeWorkTime from "./components/Employee/AddEmployeeWorkTime.js";
import InactiveClients from "./components/Clients/InactiveClients.js";

import Reports from "./components/Reports/Reports.js";
import PrepaidPackages from "./components/Inventory/PrepaidPackages.js";
import Sales from "./components/Dashboards/Sales.js";
import Financial from "./components/Dashboards/Financial.js";
import Retail from "./components/Dashboards/Retail.js"

import EmployeePayrollList from './components/EmployeePayroll/EmployeePayrollList';
import EmployeePayrollForm from './components/EmployeePayroll/EmployeePayrollForm';
import PromotionList from './components/Inventory/PromotionList';
import PromotionForm from './components/Inventory/PromotionForm';

import CashReconciliation from './components/CashRegister/CashReconciliation';

import Login from "./components/Login/Login.js";
import Header from "./components/Header.js";

import { components } from "react-select";

import { Provider } from "react-redux";
import store from "./store";

import Test from "./components/Design/test.js";

import MessageScreen from './components/Messaging/MessageScreen';
import './components/Messaging/MessageScreen.css';

// import logo from './logo.svg';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleMenuClick = (menu) => {
    setActiveMenu((prevActiveMenu) => (prevActiveMenu === menu ? "" : menu));
  };

  return (
    <Provider store={store}>
      <Router>
        <div className="app">
          <main>
            <Routes>
              {/*<Route path="/" element={<ExpenseDashboard />} />*/}
              <Route path="/login" element={<Login />} />
              <Route path="/booking" element={<Booking />} />
              <Route
                path="/reservation"
                element={<Reservation />}
              />
              <Route
                path="/reservation-bookings"
                element={<ReservationCalender />}
              />
              <Route
                path="/appointment-bookings"
                element={<AppointmentCalender />}
              />
              <Route path="/test" element={<Test />} />
              <Route path="/" element={<ShopDetailList />} />
              <Route path="/shop" element={<ShopDetail />} />
              <Route path="/shop-clients" element={<ShopClients />} />
              <Route path="/shop-add-clients" element={<ShopAddClient />} />
              <Route path="/client-leads" element={<ClientLeads />} />
              <Route path="/shop-add-pet/:id" element={<ShopAddPet />} />
              <Route path="/client-profile/:id" element={<ClientProfile />} />
              <Route path="/edit-client/:id" element={<ShopEditClient />} />
              <Route path="/edit-pet/:id" element={<ShopEditPet />} />
              <Route path="/pet-profile/:id" element={<PetProfile />} />
              <Route path="/first-time-clients/:shopName/:id" element={<FirstTimeClients />} />
              
              <Route path="/BookingList" element={<BookingList />} />
              <Route
                path="/shop-bookings"
                element={<BookingsShop />}
              />
              <Route path="/booking-detail/:shopName/:id" element={<ShopBookingsDetail />} />
              <Route path="/signature-policy/:id" element={<PolicySignature />} />
              <Route path="/breed-list" element={<BreedList />} />
              <Route path="/flags-list" element={<FlagsTable />} />

              <Route path="/employee-center" element={<EmployeeCenter />} />
              <Route path="/employee-detail/:userId" element={<EmployeeDetail />} />
              <Route path="/employee-edit/:userId" element={<EmployeeEdit />} />
              <Route path="/my-profile" element={<MyProfile />} />
              <Route path="/work-time/:userId" element={<EmployeeWorkTime />} />
              <Route path="/edit-work-time/:userId" element={<EditEmployeeWorkTime />} />
              <Route path="/add-work-time/:userId" element={<AddEmployeeWorkTime />} />
              <Route path="/inactive-clients" element={<InactiveClients />} />

              <Route path="/reports" element={<Reports />} />
              <Route path="/prepaid-packages" element={<PrepaidPackages />} />

              {/* Dashboards */}
              <Route path="/sales-dashboard" element={<Sales />} />
              <Route path="/financial-dashboard" element={<Financial />} />
              <Route path="/retail-dashboard" element={<Retail />} />

              {/* Payroll crud */}
              <Route path="/employee-payroll" element={<EmployeePayrollList />} />
              <Route path="/employee-payroll/add" element={<EmployeePayrollForm />} />
              <Route path="/employee-payroll/edit/:id" element={<EmployeePayrollForm />} />

              {/* Promotions crud */}
              <Route path="/promotions" element={<PromotionList />} />
              <Route path="/promotions/new" element={<PromotionForm />} />
              <Route path="/promotions/:id/edit" element={<PromotionForm />} />

              <Route path="/AddUser" element={<AddUser />} />
              <Route path="/dashboard" element={<ExpenseDashboard />} />
              <Route path="/expense-form" element={<ExpenseForm />} />
              <Route path="/item-categories" element={<ExpenseCategories />} />
              <Route path="/cash-register" element={<CashRegisterForm />} />
              <Route path="/sales" element={<SalesForm />} />
              <Route path="/vendors" element={<VendorsForm />} />
              <Route path="/inventory" element={<InventoryForm />} />
              <Route path="/inventory-main" element={<InventoryMain />} />
              <Route path="/reorder-items" element={<ReorderInventory />} />
              <Route path="/promotions-main" element={<PromotionsMain />} />
              <Route path="/promo-groups" element={<PromoGroups />} />
              <Route path="/edit-promo-group" element={<EditPromoGroup />} />
              <Route path="/purchase-order" element={<PurchaseOrderForm />} />
              <Route path="/point-of-sale" element={<POS />} />
              <Route path="/services" element={<AllServices />} />
              <Route path="/clients" element={<ClientForm />} />
              <Route path="/employees" element={<EmployeeForm />} />
              <Route path="/banks" element={<Banks />} />
              <Route path="/banks-accounts" element={<BankAccounts />} />
              <Route path="/calendars-list" element={<CalendarList />} />
              <Route path="/shops-list" element={<ShopList />} />
              <Route path="/add-breed-list" element={<AddBreedList />} />
              <Route
                path="/bank-reconciliation1"
                element={<BankReconciliationForm />}
              />
              <Route
                path="/bank-reconciliation"
                element={<BankReconciliation />}
              />
              <Route path="/cash-reconciliation" element={<CashReconciliation />} />
              <Route path="/message-screen" element={<MessageScreen />} />
            </Routes>
          </main>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
