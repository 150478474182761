import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shopName: localStorage.getItem('shopName') || '',
  shopId: localStorage.getItem('shopId') || '',
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShopDetail: (state, action) => {
      const { shopName, shopId } = action.payload;
      state.shopName = shopName;
      state.shopId = shopId;
      localStorage.setItem('shopName', shopName);
      localStorage.setItem('shopId', shopId);
    },
    clearShopDetails: (state) => {
      state.shopName = '';
      state.shopId = '';
      localStorage.removeItem('shopName');
      localStorage.removeItem('shopId');
    },
  },
});

export const { setShopDetail, clearShopDetails } = shopSlice.actions;

export default shopSlice.reducer;
